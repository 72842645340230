import React from 'react'

import Grid from '@material-ui/core/Grid'

import QList from '../../../../components/QList/index'
import QIconRow from '../components/QIconRow/index'
import QBodyText from '../../../../components/QBodyText'
import QHeader from '../../../../components/QHeader'
import Slide from '../../../../components/Slide'
import ImageComponent from '../../../../components/ImageComponent'

import ABBLogo from '../../../../components/SvgIcons/ABBLogo'
import image1 from '../../../../assets/branchenkonfiguratorIndustrie/backgrounds/industrie-usv-und-spannungsaufbereitung.png'
import image2 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_14_operation_0522.svg'
import image3 from '../../../../assets/branchenkonfiguratorIndustrie/icons/stroke.svg'
import image4 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB__Continous_operation.svg'

export default function IndustrieUSVSpannungsaufbereitung() {
    return (
        <Slide
            logo={<ABBLogo style={{ fontSize: '4rem' }} />}
            background={{
                enabled: true,
                alignment: 'right',
                width: '50%',
                height: 3.575 / 4,
                backgroundSize: 'cover',
                backgroundPosition: 'right',
            }}
            actions={[
                {
                    type: 'icon',
                    text: 'Web',
                    img: 'laptop',
                    target: '_blank',
                    link: 'https://new.abb.com/ups/de/strom-und-spannungsaufbereiter-von-abb',
                },
                {
                    type: 'icon',
                    text: 'PDF',
                    img: 'pdf',
                    target: '_blank',
                    link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/industrie-usv.pdf',
                },
                {
                    type: 'icon',
                    text: 'Training',
                    img: 'seminar',
                    target: '_blank',
                    link: 'https://new.abb.com/service/de/training/abb-university/germany/seminare/termine/großhandelspartnerprogramm/energiemanagement-großhandel',
                },
            ]}
        >
            <Grid container wrap="nowrap" direction="column" style={{ height: '95vh' }}>
                <Grid item>
                    <QHeader title="Industrie-USV und Spannungsaufbereitung" />
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <QBodyText>
                                ABB bietet individuelle Lösungen von der Absicherung vereinzelter Leit- und Automatisierungssysteme bis hin zum Schutz gesamter Produktionsbereiche oder Werke.
                            </QBodyText>
                            <QList
                                items={[
                                    'Absicherung von IT- oder kleinerer Produktions- lasten in rauen, industriellen Umgebungen',
                                    'Flexibel konfigurierbar mit erhöhtem Schutzgrad bis IP42',
                                    'Elektrisch robuste Absicherung von Produktions- lasten oder gesamter Werke',
                                    'Höchster Wirkungsgrad > 99%, Batterien oder Superkondensatoren als Energiespeicher',
                                ]}
                                dense
                                variant="dot"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '100%',
                                    }}
                                    spacing={2}
                                >
                                    <ImageComponent previewImgHeight="40vh" previewImgWidth="90%" alt="Industrie-USV und Spannungsaufbereitung" src={image1} />
                                </Grid>
                                <Grid item>
                                    <QIconRow
                                        colWidth={4}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                        items={[
                                            {
                                                title: 'Für industrielle Lasten',
                                                img: image2,
                                            },
                                            {
                                                title: 'Robustes Anlagendesign',
                                                img: image3,
                                            },
                                            {
                                                title: 'Erhöhte Produktivität',
                                                img: image4,
                                            },
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Slide>
    )
}
