import React from 'react'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import QHeader from '../../../../../components/QHeader'
import QBox from '../../components/QBox'
import Slide from '../../../../../components/Slide'
import { silentMoveTo } from '../../../../../static/fullpageHelpers'
import Edcs from '../../Overlays/EDCS'
import SmartSensorMotoren from '../../Overlays/SmartSensorMotoren'
import EnergieeffizienteAntriebstechnik from '../../Overlays/EnergieeffizienteAntriebstechnik'
import Netzanalysegeraete from '../../Overlays/Netzanalysegeraete'
import Deltec from '../../Overlays/Deltec'
import Dbt from '../../Overlays/DBT'
import SelbstnachspannendeKlemmen from '../../Overlays/SelbstnachspannendeKlemmen'
import UniversalMotorController from '../../Overlays/UniversalMotorController'
import EQMatic from '../../Overlays/EQMatic'
import Softstarter from '../../Overlays/Softstarter'
import QSlide from '../../components/QSlide'
import TruONE from '../../Overlays/TruONE'
import Leistungsschalter from '../../Overlays/Leistungsschalter'

import background from '../../../../../assets/branchenkonfiguratorIndustrie/backgrounds/zement-steine-erde.jpg'
import image1 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_12_cloud_driven_mobile_devices_0768.svg'
import image2 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_14_repair_0525.svg'
import image3 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_03_for_switchgear_M2M_0584.svg'
import image4 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_03_drive_for_switchgear_ACS550_0582.svg'
import image5 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_03_calciner_0578.svg'
import image6 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_11_energy_care_0459.svg'
import image7 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_05_no_drilling_0643.svg'
import image8 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_03_for_switchgear_PSTB_0589.svg'
import image9 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_10_manual_monitoring_0444.svg'
import image10 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_11_energy_mark_0760.svg'
import image11 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_03_device_0050.svg'

export default function ZementSteineErde() {
    const items = [
        {
            id: 'edcs',
            title: 'Digitales Energiemanagement',
            img: image1,
            dialog: true,
            dialogContent: <Edcs />,
        },
        {
            id: 'smartsensormotoren',
            title: 'Vorbeugende Instandhaltung',
            img: image2,
            dialog: true,
            dialogContent: <SmartSensorMotoren />,
        },
        {
            id: 'netzanalysegeraete',
            title: 'Leistungsüberwachung',
            img: image3,
            dialog: true,
            dialogContent: <Netzanalysegeraete />,
        },
        {
            id: 'energieeffizienteantriebstechnik',
            title: 'Energieeffiziente Antriebstechnik',
            img: image4,
            dialog: true,
            dialogContent: <EnergieeffizienteAntriebstechnik />,
        },
        {
            id: 'deltec',
            title: 'Kabelmanagement',
            img: image5,
            dialog: true,
            dialogContent: <Deltec />,
        },
        {
            id: 'dbt',
            title: 'Energieeffiziente Beleuchtungssteuerung',
            img: image6,
            dialog: true,
            dialogContent: <Dbt />,
        },
        {
            id: 'selbstnachspannendeklemmen',
            title: 'Schraubenlose Schaltgeräte',
            img: image7,
            dialog: true,
            dialogContent: <SelbstnachspannendeKlemmen />,
        },
        {
            id: 'motormanagementsystem',
            title: 'Motormanagementsystem',
            img: image8,
            dialog: true,
            dialogContent: (
                <QSlide>
                    <UniversalMotorController />
                    <Softstarter />
                </QSlide>
            ),
        },
        {
            id: 'eqmatic',
            title: 'Energiemonitoring',
            img: image9,
            dialog: true,
            dialogContent: <EQMatic />,
        },
        {
            id: 'truone',
            title: 'Automatische Umschalter',
            img: image10,
            dialog: true,
            dialogContent: <TruONE />,
        },
        {
            id: 'leistungsschalter',
            title: 'Leistungsschalter',
            img: image11,
            dialog: true,
            dialogContent: <Leistungsschalter />,
        },
    ]

    return (
        <Slide
            background={{
                src: background,
                enabled: true,
                alignment: 'right',
                width: '66%',
                height: 3.575 / 4,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
            <Grid container direction="column" wrap="nowrap" justifyContent="space-between">
                <Grid item>
                    <QHeader
                        title="Smarte Lösungen"
                        subTitle="Zement, Steine und Erde"
                        arrow
                        arrowOverride={() => silentMoveTo('dashboard', null)}
                    />
                </Grid>
                <Grid item>
                    <Box p={2}>
                        <QBox items={items} />
                    </Box>
                </Grid>
            </Grid>
        </Slide>
    )
}
