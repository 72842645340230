import React from 'react'

import Grid from '@material-ui/core/Grid'

import QList from '../../../../components/QList/index'
import QIconRow from '../components/QIconRow/index'
import QBodyText from '../../../../components/QBodyText'
import QHeader from '../../../../components/QHeader'
import Slide from '../../../../components/Slide'
import ImageComponent from '../../../../components/ImageComponent'

import ABBLogo from '../../../../components/SvgIcons/ABBLogo'
import image1 from '../../../../assets/branchenkonfiguratorIndustrie/backgrounds/EnergieeffizienteAntriebstechnik.png'
import image2 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABBicon_list_first_aid.png'
import image3 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_04_lower_maintenance_cost_0611.svg'
import image4 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_07_environment_care_0258.svg'

export default function EnergieeffizienteAntriebstechnik() {
    return (
        <Slide
            logo={<ABBLogo style={{ fontSize: '4rem' }} />}
            background={{
                enabled: true,
                alignment: 'right',
                width: '50%',
                height: 3.575 / 4,
                backgroundSize: 'cover',
                backgroundPosition: 'right',
            }}
            actions={[
                {
                    type: 'icon',
                    text: 'Web',
                    img: 'laptop',
                    target: '_blank',
                    link: 'https://new.abb.com/drives/de',
                },
                {
                    type: 'icon',
                    text: 'YouTube',
                    img: 'youtube',
                    target: '_blank',
                    link: 'https://www.youtube.com/watch?v=aihdTkiTnaQ',
                },
                {
                    type: 'icon',
                    text: 'Drive Selector',
                    img: 'konfigurator',
                    target: '_blank',
                    link: "https://selector.drivesmotors.abb.com/?lang=de-DE",
                },
                {
                    type: 'icon',
                    text: 'PDF',
                    img: 'pdf',
                    target: '_blank',
                    link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/zukunftsfaehiger-und-digitaler-antrieb.pdf',
                },
                {
                    type: 'icon',
                    text: 'Training',
                    img: 'seminar',
                    target: '_blank',
                    link: 'https://new.abb.com/service/de/training/abb-university/germany/seminare/termine/großhandelspartnerprogramm/antriebstechnik-großhandel',
                },
            ]}
        >
            <Grid container wrap="nowrap" direction="column" style={{ height: '95vh' }}>
                <Grid item>
                    <QHeader title="Energieeffiziente Antriebstechnik" />
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <QBodyText>
                                In wenigen Schritten zum energieeffizienten, zukunftsfähigen und digitalen Antrieb mit
                                ABB. <strong>Die neuen EU-Verordnungen</strong> fordern Änderungen und Verbesserungen im
                                Öko-Design für Installationen von Elektromotoren und Frequenzumrichtern.
                            </QBodyText>
                            <QBodyText>
                                <strong>BAFA Förderprogramme für Unternehmen unterstützen Sie dabei.</strong>
                            </QBodyText>
                            <QList
                                headline="Branchenspezifische Lösungen für"
                                items={[
                                    'Standard Anwendungen',
                                    'Heizungs-, Lüftungs- und Klimatechnik',
                                    'Wasser und Abwasser',
                                ]}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ImageComponent
                                previewImgHeight="37vh"
                                alt="Energieeffiziente Antriebstechnik"
                                src={image1}
                            />
                            <QIconRow
                                colWidth={4}
                                items={[
                                    {
                                        title: 'Erkannte Einsparpotenziale',
                                        img: image2,
                                    },
                                    {
                                        title: 'Reduzierte Gesamtkosten',
                                        img: image3,
                                    },
                                    {
                                        title: 'Reduzierung der CO2-Emission',
                                        img: image4,
                                    },
                                ]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Slide>
    )
}
