import React from 'react'

import Grid from '@material-ui/core/Grid'

import QList from '../../../../components/QList/index'
import QIconRow from '../components/QIconRow/index'
import QBodyText from '../../../../components/QBodyText'
import QHeader from '../../../../components/QHeader'
import Slide from '../../../../components/Slide'
import ImageComponent from '../../../../components/ImageComponent'

import ABBLogo from '../../../../components/SvgIcons/ABBLogo'
import image1 from '../../../../assets/branchenkonfiguratorIndustrie/backgrounds/USV.png'
import image2 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_11_energy_efficiency_0759.svg'
import image3 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_05_space_saving_0661.svg'
import image4 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_14_repair_0525.svg'

export default function USV() {
    return (
        <Slide
            logo={<ABBLogo style={{ fontSize: '4rem' }} />}
            background={{
                enabled: true,
                alignment: 'right',
                width: '50%',
                height: 3.575 / 4,
                backgroundSize: 'cover',
                backgroundPosition: 'right',
            }}
            actions={[
                {
                    type: 'icon',
                    text: 'Web',
                    img: 'laptop',
                    target: '_blank',
                    link: 'https://new.abb.com/ups/de',
                },
                {
                    type: 'icon',
                    text: 'PDF',
                    img: 'pdf',
                    target: '_blank',
                    link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/unterbrechungsfreie_stromversorgung.pdf',
                },
                {
                    type: 'icon',
                    text: 'Training',
                    img: 'seminar',
                    target: '_blank',
                    link: 'https://new.abb.com/service/de/training/abb-university/germany/seminare/termine/großhandelspartnerprogramm/energiemanagement-großhandel',
                },
            ]}
        >
            <Grid container wrap="nowrap" direction="column" style={{ height: '95vh' }}>
                <Grid item>
                    <QHeader title="Unterbrechungsfreie Stromversorgung (USV)" />
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <QBodyText>
                                ABB unterstützt Kunden bei der Steigerung Ihrer Produktivität durch die Verbesserung der
                                Versorgung kritischer Anlagen. Neben einem breiten Portfolio an USV-Anlagen bietet ABB
                                zusätzlich zahlreiche Lösungen zur Absicherung und Spannungs- aufbereitung für
                                industrielle Anwendungen.
                            </QBodyText>
                            <QList
                                items={[
                                    'Mit internen Batterien, Batterieerweiterung für längere Autonomiezeiten',
                                    'Wartungsarme, Plug-and-Play Lösungen',
                                    'Von 10 kVA bis 500 kW pro Anlage, parallelschalt- fähig bis 5 MW',
                                    'Effiziente und kompakte Anlagen',
                                ]}
                                dense
                                variant="dot"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '100%',
                                    }}
                                    spacing={2}
                                >
                                    <ImageComponent previewImgHeight="40vh" previewImgWidth="90%" alt="USV" src={image1} />
                                </Grid>
                                <Grid item>
                                    <QIconRow
                                        colWidth={4}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                        items={[
                                            {
                                                title: 'Hohe Effizienz',
                                                img: image2,
                                            },
                                            {
                                                title: 'Kompakte Bauform',
                                                img: image3,
                                            },
                                            {
                                                title: 'Servicefreundliches Design',
                                                img: image4,
                                            },
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Slide>
    )
}
