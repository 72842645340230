import React from 'react'

import { Typography } from '@material-ui/core'

export default function QRCode({ title, img, href }) {
    return (
        <a href={href} target="_blank" rel="noreferrer" style={{ width: '40%', marginBottom: "32px" }}>
            <img alt={title} src={img} width={'100%'} />
            <Typography variant="h3" style={{fontSize: "clamp(1.2em, 2.5vh, 1.8em)"}}>{title}</Typography>
        </a>
    )
}
