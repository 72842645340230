import React from 'react'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import QHeader from '../../../../../components/QHeader'
import QBox from '../../components/QBox'
import Slide from '../../../../../components/Slide'
import { silentMoveTo } from '../../../../../static/fullpageHelpers'
import Netzanalysegeraete from '../../Overlays/Netzanalysegeraete'
import Deltec from '../../Overlays/Deltec'
import SelbstnachspannendeKlemmen from '../../Overlays/SelbstnachspannendeKlemmen'
import FehlerstromSchutzschalter from '../../Overlays/FehlerstromSchutzschalter'
import Aria from '../../Overlays/Aria'
import ElektronischeIndustrieprodukte from '../../Overlays/ElektronischeIndustrieprodukte'
import Leistungsschalter from '../../Overlays/Leistungsschalter'

import background from '../../../../../assets/branchenkonfiguratorIndustrie/backgrounds/energieerzeugung-und-bereitstellung.jpg'
import image1 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_03_for_switchgear_M2M_0584.svg'
import image2 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_03_calciner_0578.svg'
import image3 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_05_no_drilling_0643.svg'
import image4 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_14_around_service_0778.svg'
import image5 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_03_cdc_0038.svg'
import image6 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_03_device_0053.svg'
import image7 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_03_device_0050.svg'

export default function Windenergie() {
    const items = [
        {
            id: 'netzanalysegeraete',
            title: 'Leistungsüberwachung',
            img: image1,
            dialog: true,
            dialogContent: <Netzanalysegeraete />,
        },
        {
            id: 'deltec',
            title: 'Kabelmanagement',
            img: image2,
            dialog: true,
            dialogContent: <Deltec />,
        },
        {
            id: 'selbstnachspannendeklemmen',
            title: 'Schraubenlose Schaltgeräte',
            img: image3,
            dialog: true,
            dialogContent: <SelbstnachspannendeKlemmen />,
        },
        {
            id: 'fehlerstromschutzschalter',
            title: 'FI mit automatischer Prüfung',
            img: image4,
            dialog: true,
            dialogContent: <FehlerstromSchutzschalter />,
        },
        {
            id: 'aria',
            title: 'Kunststoffgehäuse für den Steuerungsbau',
            img: image5,
            dialog: true,
            dialogContent: <Aria />,
        },
        {
            id: 'elektrische-industrieprodukte',
            title: 'Elektronische Produkte und Relais',
            img: image6,
            dialog: true,
            dialogContent: <ElektronischeIndustrieprodukte />,
        },
        {
            id: 'leistungsschalter',
            title: 'Leistungsschalter',
            img: image7,
            dialog: true,
            dialogContent: <Leistungsschalter />,
        },
    ]

    return (
        <Slide
            background={{
                src: background,
                enabled: true,
                alignment: 'right',
                width: '66%',
                height: 3.575 / 4,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
            <Grid container direction="column" wrap="nowrap" justifyContent="space-between">
                <Grid item>
                    <QHeader
                        title="Smarte Lösungen"
                        subTitle="Windenergie"
                        arrow
                        arrowOverride={() => silentMoveTo('dashboard', null)}
                    />
                </Grid>
                <Grid item>
                    <Box p={2}>
                        <QBox items={items} />
                    </Box>
                </Grid>
            </Grid>
        </Slide>
    )
}
