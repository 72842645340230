import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export default function DoneIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg viewBox="0 0 82 82" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group">
            <rect id="Rectangle" fill="#000000" x="0" y="0" width="82" height="82" rx="41"></rect>
            <g id="iconmonstr-check-mark-1" transform="translate(22.000000, 26.000000)" fill="#FFFFFF" fill-rule="nonzero">
                <polygon id="Path" points="32.963125 0 14.625 18.8724737 6.03525 10.6966316 0 16.7595789 14.625 31 39 6.06131579"></polygon>
            </g>
        </g>
    </g>
            </svg>
        </SvgIcon>
    )
}
