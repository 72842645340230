import React from 'react'

import Grid from '@material-ui/core/Grid'

import QList from '../../../../components/QList/index'
import QIconRow from '../components/QIconRow/index'
import QBodyText from '../../../../components/QBodyText'
import QHeader from '../../../../components/QHeader'
import Slide from '../../../../components/Slide'
import ImageComponent from '../../../../components/ImageComponent'

import ABBLogo from '../../../../components/SvgIcons/ABBLogo'
import image1 from '../../../../assets/branchenkonfiguratorIndustrie/backgrounds/frequenzumrichter.png'
import image2 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABBicon_list_first_aid.png'
import image3 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_04_lower_maintenance_cost_0611.svg'
import image4 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_07_environment_care_0258.svg'

export default function Frequenzumrichter() {
    return (
        <Slide
            logo={<ABBLogo style={{ fontSize: '4rem' }} />}
            background={{
                enabled: true,
                alignment: 'right',
                width: '50%',
                height: 3.575 / 4,
                backgroundSize: 'cover',
                backgroundPosition: 'right',
            }}
            actions={[
                {
                    type: 'icon',
                    text: 'Web',
                    img: 'laptop',
                    target: '_blank',
                    link: 'https://www.antriebstechnik-wasser.de.abb.com/',
                },
                {
                    type: 'icon',
                    text: 'YouTube',
                    img: 'youtube',
                    target: '_blank',
                    link: 'https://www.youtube.com/watch?app=desktop&v=k8dmobqstZc',
                },
                {
                    type: 'icon',
                    text: 'Drive Selector',
                    img: 'konfigurator',
                    target: '_blank',
                    link: 'https://selector.drivesmotors.abb.com/?lang=de-DE',
                },
                {
                    type: 'icon',
                    text: 'PDF',
                    img: 'pdf',
                    target: '_blank',
                    link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/acq-580.pdf',
                },
                {
                    type: 'icon',
                    text: 'Training',
                    img: 'seminar',
                    target: '_blank',
                    link: 'https://new.abb.com/service/de/training/abb-university/germany/seminare/termine/großhandelspartnerprogramm/antriebstechnik-großhandel',
                },
            ]}
        >
            <Grid container wrap="nowrap" direction="column" style={{ height: '95vh' }}>
                <Grid item>
                    <QHeader
                        colWidth={12}
                        title={
                            <>
                                ACQ580 Frequenzumrichter für Wasser-
                                <br />
                                und Abwasseranwendungen
                            </>
                        }
                    />
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <QBodyText>
                                Die Zukunft der Wasser- und Abwasserwerke liegt in der Digitalisierung – mit ABB zum
                                energieeffizienten, zukunftsfähigen und digitalen Antrieb.
                            </QBodyText>
                            <QList
                                items={[
                                    'Pumpenreinigung: Verhinderung außerplanmäßiger Stillstandzeiten in Folge von Ablagerungen',
                                    'Trockenlaufschutz: Verhindert, dass die Pumpe trocken läuft.',
                                    'Schnelle Rampen: Verlängerte Lebensdauer einer Tauchpumpe durch geringeren Verschleiß der mechanischen Teile.',
                                    'Ultra-Low Harmonic Drives: Oberwellengehalt (THDi) unter 3%, voller Leistung auch bei schwachen Netzen, geringer Engineering Aufwand.',
                                    'Einfachste assistentengeführte Bedienbarkeit und Konnektivität mit vielen Feldbusadaptern.',
                                ]}
                                dense
                                variant="dot"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item>
                                    <ImageComponent
                                        previewImgHeight="34vh"
                                        alt="ACQ580 Frequenzumrichter"
                                        src={image1}
                                    />
                                </Grid>
                                <Grid item>
                                    <QIconRow
                                        colWidth={4}
                                        items={[
                                            {
                                                title: 'Erkannte Einsparpotenziale',
                                                img: image2,
                                            },
                                            {
                                                title: 'Reduzierte Gesamtkosten',
                                                img: image3,
                                            },
                                            {
                                                title: 'Reduzierung der CO2-Emission',
                                                img: image4,
                                            },
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Slide>
    )
}
