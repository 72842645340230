export const branchenActionData = [
    {
        id: 'chk_zement-steine-erde',
        label: 'Zement, Steine und Erde',
        link: '#zement-steine-erde',
        overlays: [
            {
                id: 'chk_digitales-energiemanagement',
                label: 'Digitales Energiemanagement',
                overlay: 'edcs',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-ability-energy-and-asset-manager.pdf',
            },
            {
                id: 'chk_vorbeugende-instandhaltung',
                label: 'Vorbeugende Instandhaltung',
                overlay: 'smartsensormotoren',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-ability-smart-sensor-fuer-motoren.pdf',
            },
            {
                id: 'chk_leistungsueberwachung',
                label: 'Leistungsüberwachung',
                overlay: 'netzanalysegeraete',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/m4m-netzanalysegeraet.pdf',
            },
            {
                id: 'chk_energieeffiziente-antriebstechnik',
                label: 'Energieeffiziente Antriebstechnik',
                overlay: 'energieeffizienteantriebstechnik',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/zukunftsfaehiger-und-digitaler-antrieb.pdf',
            },
            {
                id: 'chk_kabelmanagement',
                label: 'Kabelmanagement',
                overlay: 'deltec',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/deltec-befestigungssysteme.pdf',
            },
            {
                id: 'chk_energieeffiziente-beleuchtungssterung',
                label: 'Energieeffiziente Beleuchtungssterung',
                overlay: 'dbt',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/digitale-zeitschaltuhr-dbt.pdf',
            },
            {
                id: 'chk_schraubenlose-schaltgeraete',
                label: 'Schraubenlose Schaltgeräte',
                overlay: 'selbstnachspannendeklemmen',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/push-in-feder-anschlusstechnik.pdf',
            },
            {
                id: 'chk_motormanagementsystem',
                label: 'Motormanagementsystem',
                overlay: 'motormanagementsystem',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/intelligentes-montomanagement.pdf',
            },
            {
                id: 'chk_energiemonitoring',
                label: 'Energiemonitoring',
                overlay: 'eqmatic',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-eqmatic.pdf',
            },
            {
                id: 'chk_automatische-umschalter',
                label: 'Automatische Umschalter',
                overlay: 'truone',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/truone.pdf',
            },
            {
                id: 'chk_leistungsschalter',
                label: 'Leistungsschalter',
                overlay: 'leistungsschalter',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/leistungsschalter.pdf',
            },
        ],
    },
    {
        id: 'chk_energieerzeugung-bereitstellung',
        label: 'Energieerzeugung und Bereitstellung',
        link: '#energieerzeugung-bereitstellung',
        overlays: [
            {
                id: 'chk_digitales-energiemanagement',
                label: 'Digitales Energiemanagement',
                overlay: 'edcs',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-ability-energy-and-asset-manager.pdf',
            },
            {
                id: 'chk_beruehrungssichers-stecksockelsystem',
                label: 'Berührungssicheres Stecksockelsystem',
                overlay: 'stecksockelsystem',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/stecksockelsystem.pdf',
            },
            {
                id: 'chk_leistungsueberwachung',
                label: 'Leistungsüberwachung',
                overlay: 'netzanalysegeraete',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/m4m-netzanalysegeraet.pdf',
            },
            {
                id: 'chk_kabelmanagement',
                label: 'Kabelmanagement',
                overlay: 'kabelmanagement',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/kabelmanagement.pdf',
            },
            {
                id: 'chk_energieeffiziente-beleuchtungssterung',
                label: 'Energieeffiziente Beleuchtungssterung',
                overlay: 'dbt',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/digitale-zeitschaltuhr-dbt.pdf',
            },
            {
                id: 'chk_smarter-mobility',
                label: 'Smarter Mobility',
                overlay: 'ladeinfrastruktur',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/ladeinfrastruktur-fuer-elektrofahrzeuge.pdf',
            },
            {
                id: 'chk_netz-anlagenschutz',
                label: 'Netz- und Anlagenschutz',
                overlay: 'netzundanlagenschutz',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/netz-und-anlagenschutz.pdf',
            },
            {
                id: 'chk_automatische-umschalter',
                label: 'Automatische Umschalter',
                overlay: 'truone',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/truone.pdf',
            },
            {
                id: 'chk_leistungsschalter',
                label: 'Leistungsschalter',
                overlay: 'leistungsschalter',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/leistungsschalter.pdf',
            },
            {
                id: 'chk_gehaeusesysteme',
                label: 'Gehäusesysteme',
                overlay: 'gehaeusesysteme',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/gehaeusesysteme.pdf',
            },
            {
                id: 'chk_elektronischeIndustrieprodukte',
                label: 'Elektronische Industrieprodukte',
                overlay: 'elektronischeIndustrieprodukte',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/elektronischeIndustrieprodukte.pdf',
            },
            {
                id: 'chk_usv-anlagen',
                label: 'USV-Anlagen',
                link: '#usv-anlagen',
                overlay: 'usvanlagen',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/usv.pdf',
            },
        ],
    },
    {
        id: 'chk_holz-papier',
        label: 'Holz und Papier',
        link: '#holz-papier',
        overlays: [
            {
                id: 'chk_digitales-energiemanagement',
                label: 'Digitales Energiemanagement',
                overlay: 'edcs',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-ability-energy-and-asset-manager.pdf',
            },
            {
                id: 'chk_vorbeugende-instandhaltung',
                label: 'Vorbeugende Instandhaltung',
                overlay: 'smartsensormotoren',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-ability-smart-sensor-fuer-motoren.pdf',
            },
            {
                id: 'chk_leistungsueberwachung',
                label: 'Leistungsüberwachung',
                overlay: 'netzanalysegeraete',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/m4m-netzanalysegeraet.pdf',
            },
            {
                id: 'chk_energieeffiziente-antriebstechnik',
                label: 'Energieeffiziente Antriebstechnik',
                overlay: 'energieeffizienteantriebstechnik',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/zukunftsfaehiger-und-digitaler-antrieb.pdf',
            },
            {
                id: 'chk_kabelmanagement',
                label: 'Kabelmanagement',
                overlay: 'deltec',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/deltec-befestigungssysteme.pdf',
            },
            {
                id: 'chk_energieeffiziente-beleuchtungssterung',
                label: 'Energieeffiziente Beleuchtungssterung',
                overlay: 'dbt',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/digitale-zeitschaltuhr-dbt.pdf',
            },
            {
                id: 'chk_maschinensicherheit',
                label: 'Maschinensicherheit',
                overlay: 'maschinensicherheit',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/jokab-safety.pdf',
            },
            {
                id: 'chk_motormanagement',
                label: 'Motormanagement',
                overlay: 'motormanagementsystem',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/intelligentes-montomanagement.pdf',
            },
            {
                id: 'chk_energiemonitoring',
                label: 'Energiemonitoring',
                overlay: 'eqmatic',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-eqmatic.pdf',
            },
        ],
    },
    {
        id: 'chk_stahl-metall',
        label: 'Stahl und Metall',
        link: '#stahl-metall',
        overlays: [
            {
                id: 'chk_digitales-energiemanagement',
                label: 'Digitales Energiemanagement',
                overlay: 'edcs',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-ability-energy-and-asset-manager.pdf',
            },
            {
                id: 'chk_vorbeugende-instandhaltung',
                label: 'Vorbeugende Instandhaltung',
                overlay: 'smartsensormotoren',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-ability-smart-sensor-fuer-motoren.pdf',
            },
            {
                id: 'chk_leistungsueberwachung',
                label: 'Leistungsüberwachung',
                overlay: 'netzanalysegeraete',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/m4m-netzanalysegeraet.pdf',
            },
            {
                id: 'chk_energieeffiziente-antriebstechnik',
                label: 'Energieeffiziente Antriebstechnik',
                overlay: 'energieeffizienteantriebstechnik',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/zukunftsfaehiger-und-digitaler-antrieb.pdf',
            },
            {
                id: 'chk_kabelmanagement',
                label: 'Kabelmanagement',
                overlay: 'kabelmanagement',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/kabelmanagement.pdf',
            },
            {
                id: 'chk_energieeffiziente-beleuchtungssterung',
                label: 'Energieeffiziente Beleuchtungssterung',
                overlay: 'dbt',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/digitale-zeitschaltuhr-dbt.pdf',
            },
            {
                id: 'chk_maschinensicherheit',
                label: 'Maschinensicherheit',
                overlay: 'maschinensicherheit',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/jokab-safety.pdf',
            },
            {
                id: 'chk_motormanagement',
                label: 'Motormanagement',
                overlay: 'universalmotorcontroller',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/intelligentes-montomanagement.pdf',
            },
            {
                id: 'chk_energiemonitoring',
                label: 'Energiemonitoring',
                overlay: 'eqmatic',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-eqmatic.pdf',
            },
            {
                id: 'chk_leistungsschalter',
                label: 'Leistungsschalter',
                overlay: 'leistungsschalter',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/leistungsschalter.pdf',
            },
        ],
    },
    {
        id: 'chk_nahrungs-genussmittel',
        label: 'Nahrungs- und Genussmittel',
        link: '#nahrungs-genussmittel',
        overlays: [
            {
                id: 'chk_digitales-energiemanagement',
                label: 'Digitales Energiemanagement',
                overlay: 'edcs',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-ability-energy-and-asset-manager.pdf',
            },
            {
                id: 'chk_vorbeugende-instandhaltung',
                label: 'Vorbeugende Instandhaltung',
                overlay: 'smartsensormotoren',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-ability-smart-sensor-fuer-motoren.pdf',
            },
            {
                id: 'chk_leistungsueberwachung',
                label: 'Leistungsüberwachung',
                overlay: 'netzanalysegeraete',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/m4m-netzanalysegeraet.pdf',
            },
            {
                id: 'chk_energiemonitoring',
                label: 'Energiemonitoring',
                overlay: 'eqmatic',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-eqmatic.pdf',
            },
            {
                id: 'chk_energieeffiziente-antriebstechnik',
                label: 'Energieeffiziente Antriebstechnik',
                overlay: 'energieeffizienteantriebstechnik',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/zukunftsfaehiger-und-digitaler-antrieb.pdf',
            },
            {
                id: 'chk_kabelmanagement',
                label: 'Kabelmanagement',
                overlay: 'stahlnasenkabelbinder',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/stahlnasenkabelbinder.pdf',
            },
            {
                id: 'chk_motormanagement',
                label: 'Motormanagement',
                overlay: 'motormanagementsystem',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/intelligentes-montomanagement.pdf',
            },
            {
                id: 'chk_maschinensicherheit',
                label: 'Maschinensicherheit',
                overlay: 'maschinensicherheit',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/jokab-safety.pdf',
            },
            {
                id: 'chk_usv-anlagen',
                label: 'USV-Anlagen',
                link: '#usv-anlagen',
                overlay: 'usvanlagen',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/usv.pdf',
            },
        ],
    },
    {
        id: 'chk_pharmazie',
        label: 'Pharmazie',
        link: '#pharmazie',
        overlays: [
            {
                id: 'chk_digitales-energiemanagement',
                label: 'Digitales Energiemanagement',
                overlay: 'edcs',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-ability-energy-and-asset-manager.pdf',
            },
            {
                id: 'chk_vorbeugende-instandhaltung',
                label: 'Vorbeugende Instandhaltung',
                overlay: 'smartsensormotoren',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-ability-smart-sensor-fuer-motoren.pdf',
            },
            {
                id: 'chk_leistungsueberwachung',
                label: 'Leistungsüberwachung',
                overlay: 'netzanalysegeraete',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/m4m-netzanalysegeraet.pdf',
            },
            {
                id: 'chk_energieeffiziente-beleuchtungssterung',
                label: 'Energieeffiziente Beleuchtungssterung',
                overlay: 'dbt',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/digitale-zeitschaltuhr-dbt.pdf',
            },
            {
                id: 'chk_kabelmanagement',
                label: 'Kabelmanagement',
                overlay: 'stahlnasenkabelbinder',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/stahlnasenkabelbinder.pdf',
            },
            {
                id: 'chk_energiemonitoring',
                label: 'Energiemonitoring',
                overlay: 'eqmatic',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-eqmatic.pdf',
            },
            {
                id: 'chk_automatische-umschalter',
                label: 'Automatische Umschalter',
                overlay: 'truone',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/truone.pdf',
            },
            {
                id: 'chk_usv-anlagen',
                label: 'USV-Anlagen',
                link: '#usv-anlagen',
                overlay: 'usvanlagen',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/usv.pdf',
            },
        ],
    },
    {
        id: 'chk_chemie-oel-gas',
        label: 'Chemie, Öl und Gas',
        link: '#chemie-oel-gas',
        overlays: [
            {
                id: 'chk_digitales-energiemanagement',
                label: 'Digitales Energiemanagement',
                overlay: 'edcs',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-ability-energy-and-asset-manager.pdf',
            },
            {
                id: 'chk_beruehrungssichers-stecksockelsystem',
                label: 'Berührungssicheres Stecksockelsystem',
                overlay: 'stecksockelsystem',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/stecksockelsystem.pdf',
            },
            {
                id: 'chk_leistungsueberwachung',
                label: 'Leistungsüberwachung',
                overlay: 'netzanalysegeraete',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/m4m-netzanalysegeraet.pdf',
            },
            {
                id: 'chk_fi-automatischer-pruefung',
                label: 'FI mit automatischer Prüfung',
                overlay: 'fehlerstromschutzschalter',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/fehlerstrom-schutzschalter.pdf',
            },
            {
                id: 'chk_energieeffiziente-antriebstechnik',
                label: 'Energieeffiziente Antriebstechnik',
                overlay: 'energieeffizienteantriebstechnik',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/zukunftsfaehiger-und-digitaler-antrieb.pdf',
            },
            {
                id: 'chk_schraubenlose-schaltgeraete',
                label: 'Schraubenlose Schaltgeräte',
                overlay: 'selbstnachspannendeklemmen',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/push-in-feder-anschlusstechnik.pdf',
            },
            {
                id: 'chk_motormanagement',
                label: 'Motormanagement',
                overlay: 'motormanagementsystem',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/intelligentes-montomanagement.pdf',
            },
            {
                id: 'chk_kabelmanagement',
                label: 'Kabelmanagement',
                overlay: 'kabelmanagement',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/kabelmanagement.pdf',
            },
            {
                id: 'chk_leistungsschalter',
                label: 'Leistungsschalter',
                overlay: 'leistungsschalter',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/leistungsschalter.pdf',
            },
        ],
    },
    {
        id: 'chk_windenergie',
        label: 'Windenergie',
        link: '#windenergie',
        overlays: [
            {
                id: 'chk_leistungsueberwachung',
                label: 'Leistungsüberwachung',
                overlay: 'netzanalysegeraete',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/m4m-netzanalysegeraet.pdf',
            },
            {
                id: 'chk_kabelmanagement',
                label: 'Kabelmanagement',
                overlay: 'deltec',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/deltec-befestigungssysteme.pdf',
            },
            {
                id: 'chk_schraubenlose-schaltgeraete',
                label: 'Schraubenlose Schaltgeräte',
                overlay: 'selbstnachspannendeklemmen',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/push-in-feder-anschlusstechnik.pdf',
            },
            {
                id: 'chk_fi-automatischer-pruefung',
                label: 'FI mit automatischer Prüfung',
                overlay: 'fehlerstromschutzschalter',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/fehlerstrom-schutzschalter.pdf',
            },
            {
                id: 'chk_kunststoffgehaeuhse-steuerungsbau',
                label: 'Kunststoffgehäuse für den Steuerungsbau',
                overlay: 'aria',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/aria.pdf',
            },
            {
                id: 'chk_leistungsschalter',
                label: 'Leistungsschalter',
                overlay: 'leistungsschalter',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/leistungsschalter.pdf',
            },
            {
                id: 'chk_elektronischeIndustrieprodukte',
                label: 'Elektronische Industrieprodukte',
                overlay: 'elektronischeIndustrieprodukte',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/elektronischeIndustrieprodukte.pdf',
            },
        ],
    },
    {
        id: 'chk_facility-management',
        label: 'Facility Management',
        link: '#facility-management',
        overlays: [
            {
                id: 'chk_digitales-energiemanagement',
                label: 'Digitales Energiemanagement',
                overlay: 'edcs',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-ability-energy-and-asset-manager.pdf',
            },
            {
                id: 'chk_vorbeugende-instandhaltung',
                label: 'Vorbeugende Instandhaltung',
                overlay: 'smartsensormotoren',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-ability-smart-sensor-fuer-motoren.pdf',
            },
            {
                id: 'chk_beruehrungssichers-stecksockelsystem',
                label: 'Berührungssicheres Stecksockelsystem',
                overlay: 'stecksockelsystem',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/stecksockelsystem.pdf',
            },
            {
                id: 'chk_leistungsueberwachung',
                label: 'Leistungsüberwachung',
                overlay: 'netzanalysegeraete',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/m4m-netzanalysegeraet.pdf',
            },
            {
                id: 'chk_kabelmanagement',
                label: 'Kabelmanagement',
                overlay: 'kabelmanagement',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/kabelmanagement.pdf',
            },
            {
                id: 'chk_energieeffiziente-beleuchtungssterung',
                label: 'Energieeffiziente Beleuchtungssterung',
                overlay: 'dbt',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/digitale-zeitschaltuhr-dbt.pdf',
            },
            {
                id: 'chk_fi-automatischer-pruefung',
                label: 'FI mit automatischer Prüfung',
                overlay: 'fehlerstromschutzschalter',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/fehlerstrom-schutzschalter.pdf',
            },
            {
                id: 'chk_energiemanagement',
                label: 'Energiemanagement',
                overlay: 'energiemanagement',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/system-pro-m-compact-in-site.pdf',
            },
            {
                id: 'chk_energieeffiziente-antriebstechnik',
                label: 'Energieeffiziente Antriebstechnik',
                overlay: 'energieeffizienteantriebstechnik',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/zukunftsfaehiger-und-digitaler-antrieb.pdf',
            },
            {
                id: 'chk_smarter-mobility',
                label: 'Smarter Mobility',
                overlay: 'ladeinfrastruktur',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/ladeinfrastruktur-fuer-elektrofahrzeuge.pdf',
            },
            {
                id: 'chk_energiemonitoring',
                label: 'Energiemonitoring',
                overlay: 'eqmatic',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-eqmatic.pdf',
            },
            {
                id: 'chk_intelligente-pumpantriebe',
                label: 'Intelligente Pumpantriebe',
                overlay: 'frequenzumrichter',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/acq-580.pdf',
            },
            {
                id: 'chk_usv-anlagen',
                label: 'USV-Anlagen',
                link: '#usv-anlagen',
                overlay: 'usvanlagen',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/usv.pdf',
            },
        ],
    },
    {
        id: 'chk_maschinenbau',
        label: 'Maschinenbau',
        link: '#maschinenbau',
        overlays: [
            {
                id: 'chk_beruehrungssichers-stecksockelsystem',
                label: 'Berührungssicheres Stecksockelsystem',
                overlay: 'stecksockelsystem',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/stecksockelsystem.pdf',
            },
            {
                id: 'chk_maschinensicherheit',
                label: 'Maschinensicherheit',
                overlay: 'maschinensicherheit',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/jokab-safety.pdf',
            },
            {
                id: 'chk_energieeffiziente-beleuchtungssterung',
                label: 'Energieeffiziente Beleuchtungssterung',
                overlay: 'dbt',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/digitale-zeitschaltuhr-dbt.pdf',
            },
            {
                id: 'chk_kabelmanagement',
                label: 'Kabelmanagement',
                overlay: 'tyrap',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/ty-rap-befestigungssystem.pdf',
            },
            {
                id: 'chk_motormanagement',
                label: 'Motormanagement',
                overlay: 'motormanagementsystem',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/intelligentes-montomanagement.pdf',
            },
            {
                id: 'chk_leistungsschalter',
                label: 'Leistungsschalter',
                overlay: 'leistungsschalter',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/leistungsschalter.pdf',
            },
            {
                id: 'chk_gehaeusesysteme',
                label: 'Gehäusesysteme',
                overlay: 'gehaeusesysteme',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/gehaeusesysteme.pdf',
            },
            {
                id: 'chk_elektronischeIndustrieprodukte',
                label: 'Elektronische Industrieprodukte',
                overlay: 'elektronischeIndustrieprodukte',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/elektronischeIndustrieprodukte.pdf',
            },
            {
                id: 'chk_usv-anlagen',
                label: 'USV-Anlagen',
                link: '#usv-anlagen',
                overlay: 'usvanlagen',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/usv.pdf',
            },
        ],
    },
    {
        id: 'chk_schaltanlagenbau',
        label: 'Schaltanlagenbau',
        link: '#schaltanlagenbau',
        overlays: [
            {
                id: 'chk_digitales-energiemanagement',
                label: 'Digitales Energiemanagement',
                overlay: 'edcs',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-ability-energy-and-asset-manager.pdf',
            },
            {
                id: 'chk_beruehrungssichers-stecksockelsystem',
                label: 'Berührungssicheres Stecksockelsystem',
                overlay: 'stecksockelsystem',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/stecksockelsystem.pdf',
            },
            {
                id: 'chk_leistungsueberwachung',
                label: 'Leistungsüberwachung',
                overlay: 'netzanalysegeraete',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/m4m-netzanalysegeraet.pdf',
            },
            {
                id: 'chk_energieeffiziente-beleuchtungssterung',
                label: 'Energieeffiziente Beleuchtungssterung',
                overlay: 'dbt',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/digitale-zeitschaltuhr-dbt.pdf',
            },
            {
                id: 'chk_schraubenlose-schaltgeraete',
                label: 'Schraubenlose Schaltgeräte',
                overlay: 'selbstnachspannendeklemmen',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/push-in-feder-anschlusstechnik.pdf',
            },
            {
                id: 'chk_energiemanagement',
                label: 'Energiemanagement',
                overlay: 'energiemanagement',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/system-pro-m-compact-in-site.pdf',
            },
            {
                id: 'chk_kunststoffgehaeuhse-steuerungsbau',
                label: 'Kunststoffgehäuse für den Steuerungsbau',
                overlay: 'aria',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/aria.pdf',
            },
            {
                id: 'chk_energiemonitoring',
                label: 'Energiemonitoring',
                overlay: 'eqmatic',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-eqmatic.pdf',
            },
            {
                id: 'chk_automatische-umschalter',
                label: 'Automatische Umschalter',
                overlay: 'truone',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/truone.pdf',
            },
            {
                id: 'chk_leistungsschalter',
                label: 'Leistungsschalter',
                overlay: 'leistungsschalter',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/leistungsschalter.pdf',
            },
            {
                id: 'chk_gehaeusesysteme',
                label: 'Gehäusesysteme',
                overlay: 'gehaeusesysteme',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/gehaeusesysteme.pdf',
            },
            {
                id: 'chk_elektronischeIndustrieprodukte',
                label: 'Elektronische Industrieprodukte',
                overlay: 'elektronischeIndustrieprodukte',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/elektronischeIndustrieprodukte.pdf',
            },
        ],
    },
    {
        id: 'chk_hoch-tiefbau',
        label: 'Hoch und Tiefbau',
        link: '#hoch-tiefbau',
        overlays: [
            {
                id: 'chk_digitales-energiemanagement',
                label: 'Digitales Energiemanagement',
                overlay: 'edcs',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-ability-energy-and-asset-manager.pdf',
            },
            {
                id: 'chk_vorbeugende-instandhaltung',
                label: 'Vorbeugende Instandhaltung',
                overlay: 'smartsensormotoren',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-ability-smart-sensor-fuer-motoren.pdf',
            },
            {
                id: 'chk_leistungsueberwachung',
                label: 'Leistungsüberwachung',
                overlay: 'netzanalysegeraete',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/m4m-netzanalysegeraet.pdf',
            },
            {
                id: 'chk_energieeffiziente-antriebstechnik',
                label: 'Energieeffiziente Antriebstechnik',
                overlay: 'energieeffizienteantriebstechnik',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/zukunftsfaehiger-und-digitaler-antrieb.pdf',
            },
            {
                id: 'chk_kabelmanagement',
                label: 'Kabelmanagement',
                overlay: 'deltec',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/deltec-befestigungssysteme.pdf',
            },
            {
                id: 'chk_energieeffiziente-beleuchtungssterung',
                label: 'Energieeffiziente Beleuchtungssterung',
                overlay: 'dbt',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/digitale-zeitschaltuhr-dbt.pdf',
            },
            {
                id: 'chk_schraubenlose-schaltgeraete',
                label: 'Schraubenlose Schaltgeräte',
                overlay: 'selbstnachspannendeklemmen',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/push-in-feder-anschlusstechnik.pdf',
            },
        ],
    },
    {
        id: 'chk_infrastruktur-logistik',
        label: 'Infrastruktur und Logistik',
        link: '#infrastruktur-logistik',
        overlays: [
            {
                id: 'chk_digitales-energiemanagement',
                label: 'Digitales Energiemanagement',
                overlay: 'edcs',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-ability-energy-and-asset-manager.pdf',
            },
            {
                id: 'chk_beruehrungssichers-stecksockelsystem',
                label: 'Berührungssicheres Stecksockelsystem',
                overlay: 'stecksockelsystem',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/stecksockelsystem.pdf',
            },
            {
                id: 'chk_leistungsueberwachung',
                label: 'Leistungsüberwachung',
                overlay: 'netzanalysegeraete',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/m4m-netzanalysegeraet.pdf',
            },
            {
                id: 'chk_schraubenlose-schaltgeraete',
                label: 'Schraubenlose Schaltgeräte',
                overlay: 'selbstnachspannendeklemmen',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/push-in-feder-anschlusstechnik.pdf',
            },
            {
                id: 'chk_fi-automatischer-pruefung',
                label: 'FI mit automatischer Prüfung',
                overlay: 'fehlerstromschutzschalter',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/fehlerstrom-schutzschalter.pdf',
            },
            {
                id: 'chk_energiemanagement',
                label: 'Energiemanagement',
                overlay: 'energiemanagement',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/system-pro-m-compact-in-site.pdf',
            },
            {
                id: 'chk_kabelmanagement',
                label: 'Kabelmanagement',
                overlay: 'kabelmanagement',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/kabelmanagement.pdf',
            },
            {
                id: 'chk_energieeffiziente-beleuchtungssterung',
                label: 'Energieeffiziente Beleuchtungssterung',
                overlay: 'dbt',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/digitale-zeitschaltuhr-dbt.pdf',
            },
            {
                id: 'chk_smarter-mobility',
                label: 'Smarter Mobility',
                overlay: 'ladeinfrastruktur',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/ladeinfrastruktur-fuer-elektrofahrzeuge.pdf',
            },
            {
                id: 'chk_energiemonitoring',
                label: 'Energiemonitoring',
                overlay: 'eqmatic',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-eqmatic.pdf',
            },
            {
                id: 'chk_gehaeusesysteme',
                label: 'Gehäusesysteme',
                overlay: 'gehaeusesysteme',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/gehaeusesysteme.pdf',
            },
            {
                id: 'chk_usv-anlagen',
                label: 'USV-Anlagen',
                link: '#usv-anlagen',
                overlay: 'usvanlagen',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/usv.pdf',
            },
        ],
    },
    {
        id: 'chk_data-center',
        label: 'Data Center',
        link: '#data-center',
        overlays: [
            {
                id: 'chk_digitales-energiemanagement',
                label: 'Digitales Energiemanagement',
                overlay: 'edcs',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-ability-energy-and-asset-manager.pdf',
            },
            {
                id: 'chk_beruehrungssichers-stecksockelsystem',
                label: 'Berührungssicheres Stecksockelsystem',
                overlay: 'stecksockelsystem',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/stecksockelsystem.pdf',
            },
            {
                id: 'chk_leistungsueberwachung',
                label: 'Leistungsüberwachung',
                overlay: 'netzanalysegeraete',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/m4m-netzanalysegeraet.pdf',
            },
            {
                id: 'chk_fi-automatischer-pruefung',
                label: 'FI mit automatischer Prüfung',
                overlay: 'fehlerstromschutzschalter',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/fehlerstrom-schutzschalter.pdf',
            },
            {
                id: 'chk_energiemonitoring',
                label: 'Energiemonitoring',
                overlay: 'eqmatic',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-eqmatic.pdf',
            },
            {
                id: 'chk_energiemanagement',
                label: 'Energiemanagement',
                overlay: 'energiemanagement',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/system-pro-m-compact-in-site.pdf',
            },
            {
                id: 'chk_automatische-umschalter',
                label: 'Automatische Umschalter',
                overlay: 'truone',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/truone.pdf',
            },
            {
                id: 'chk_gehaeusesysteme',
                label: 'Gehäusesysteme',
                overlay: 'gehaeusesysteme',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/gehaeusesysteme.pdf',
            },
            {
                id: 'chk_usv-anlagen',
                label: 'USV-Anlagen',
                link: '#usv-anlagen',
                overlay: 'usvanlagen',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/usv.pdf',
            },
        ],
    },
    {
        id: 'chk_instandhaltung-service',
        label: 'Instandhaltung und Service',
        link: '#instandhaltung-service',
        overlays: [
            {
                id: 'chk_digitales-energiemanagement',
                label: 'Digitales Energiemanagement',
                overlay: 'edcs',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-ability-energy-and-asset-manager.pdf',
            },
            {
                id: 'chk_vorbeugende-instandhaltung',
                label: 'Vorbeugende Instandhaltung',
                overlay: 'smartsensormotoren',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-ability-smart-sensor-fuer-motoren.pdf',
            },
            {
                id: 'chk_leistungsueberwachung',
                label: 'Leistungsüberwachung',
                overlay: 'netzanalysegeraete',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/m4m-netzanalysegeraet.pdf',
            },
            {
                id: 'chk_energieeffiziente-antriebstechnik',
                label: 'Energieeffiziente Antriebstechnik',
                overlay: 'energieeffizienteantriebstechnik',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/zukunftsfaehiger-und-digitaler-antrieb.pdf',
            },
            {
                id: 'chk_energieeffiziente-beleuchtungssterung',
                label: 'Energieeffiziente Beleuchtungssterung',
                overlay: 'dbt',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/digitale-zeitschaltuhr-dbt.pdf',
            },
            {
                id: 'chk_schraubenlose-schaltgeraete',
                label: 'Schraubenlose Schaltgeräte',
                overlay: 'selbstnachspannendeklemmen',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/push-in-feder-anschlusstechnik.pdf',
            },
            {
                id: 'chk_fi-automatischer-pruefung',
                label: 'FI mit automatischer Prüfung',
                overlay: 'fehlerstromschutzschalter',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/fehlerstrom-schutzschalter.pdf',
            },
            {
                id: 'chk_energiemanagement',
                label: 'Energiemanagement',
                overlay: 'energiemanagement',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/system-pro-m-compact-in-site.pdf',
            },
            {
                id: 'chk_motormanagement',
                label: 'Motormanagement',
                overlay: 'motormanagementsystem',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/intelligentes-montomanagement.pdf',
            },
            {
                id: 'chk_energiemonitoring',
                label: 'Energiemonitoring',
                overlay: 'eqmatic',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-eqmatic.pdf',
            },
            {
                id: 'chk_intelligente-pumpantriebe',
                label: 'Intelligente Pumpantriebe',
                overlay: 'frequenzumrichter',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/acq-580.pdf',
            },
            {
                id: 'chk_kabelmanagement',
                label: 'Kabelmanagement',
                overlay: 'kabelmanagement',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/kabelmanagement.pdf',
            },
        ],
    },
    {
        id: 'chk_bahn-schienenfahrzeuge',
        label: 'Bahn und Schienenfahrzeuge',
        link: '#bahn-schienenfahrzeuge',
        overlays: [
            {
                id: 'chk_produkte-bahnzulassung',
                label: 'Produkte mit Bahnzulassung',
                overlay: 'bedingungenaufderschiene',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/applikationen-fuer-spezifische-bedingungen-auf-der-schiene.pdf',
            },
            {
                id: 'chk_kabelmanagement',
                label: 'Kabelmanagement',
                overlay: 'tyrap',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/ty-rap-befestigungssystem.pdf',
            },
            {
                id: 'chk_kunststoffgehaeuhse-steuerungsbau',
                label: 'Kunststoffgehäuse für den Steuerungsbau',
                overlay: 'aria',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/aria.pdf',
            },
        ],
    },
    {
        id: 'chk_schiffbau-marine',
        label: 'Schiffbau und Marine',
        link: '#schiffbau-marine',
        overlays: [
            {
                id: 'chk_kabelmanagement',
                label: 'Kabelmanagement',
                overlay: 'kabelmanagement',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/kabelmanagement.pdf',
            },
            {
                id: 'chk_energieeffiziente-beleuchtungssterung',
                label: 'Energieeffiziente Beleuchtungssterung',
                overlay: 'dbt',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/digitale-zeitschaltuhr-dbt.pdf',
            },
            {
                id: 'chk_schraubenlose-schaltgeraete',
                label: 'Schraubenlose Schaltgeräte',
                overlay: 'selbstnachspannendeklemmen',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/push-in-feder-anschlusstechnik.pdf',
            },
            {
                id: 'chk_beruehrungssichers-stecksockelsystem',
                label: 'Berührungssicheres Stecksockelsystem',
                overlay: 'stecksockelsystem',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/stecksockelsystem.pdf',
            },
            {
                id: 'chk_leistungsschalter',
                label: 'Leistungsschalter',
                overlay: 'leistungsschalter',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/leistungsschalter.pdf',
            },
        ],
    },
    {
        id: 'chk_gesundheitswesen',
        label: 'Gesundheitswesen',
        link: '#gesundheitswesen',
        overlays: [
            {
                id: 'chk_digitales-energiemanagement',
                label: 'Digitales Energiemanagement',
                overlay: 'edcs',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-ability-energy-and-asset-manager.pdf',
            },
            {
                id: 'chk_beruehrungssichers-stecksockelsystem',
                label: 'Berührungssicheres Stecksockelsystem',
                overlay: 'stecksockelsystem',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/stecksockelsystem.pdf',
            },
            {
                id: 'chk_leistungsueberwachung',
                label: 'Leistungsüberwachung',
                overlay: 'netzanalysegeraete',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/m4m-netzanalysegeraet.pdf',
            },
            {
                id: 'chk_energieeffiziente-beleuchtungssterung',
                label: 'Energieeffiziente Beleuchtungssterung',
                overlay: 'dbt',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/digitale-zeitschaltuhr-dbt.pdf',
            },
            {
                id: 'chk_energiemanagement',
                label: 'Energiemanagement',
                overlay: 'energiemanagement',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/system-pro-m-compact-in-site.pdf',
            },
            {
                id: 'chk_kabelmanagement',
                label: 'Kabelmanagement',
                overlay: 'tyrap',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/ty-rap-befestigungssystem.pdf',
            },
            {
                id: 'chk_smarter-mobility',
                label: 'Smarter Mobility',
                overlay: 'ladeinfrastruktur',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/ladeinfrastruktur-fuer-elektrofahrzeuge.pdf',
            },
            {
                id: 'chk_energiemonitoring',
                label: 'Energiemonitoring',
                overlay: 'eqmatic',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-eqmatic.pdf',
            },
            {
                id: 'chk_usv-anlagen',
                label: 'USV-Anlagen',
                link: '#usv-anlagen',
                overlay: 'usvanlagen',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/usv.pdf',
            },
        ],
    },
    {
        id: 'chk_wasser-abwasser',
        label: 'Wasser und Abwasser',
        link: '#wasser-abwasser',
        overlays: [
            {
                id: 'chk_digitales-energiemanagement',
                label: 'Digitales Energiemanagement',
                overlay: 'edcs',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-ability-energy-and-asset-manager.pdf',
            },
            {
                id: 'chk_vorbeugende-instandhaltung',
                label: 'Vorbeugende Instandhaltung',
                overlay: 'smartsensormotoren',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-ability-smart-sensor-fuer-motoren.pdf',
            },
            {
                id: 'chk_leistungsueberwachung',
                label: 'Leistungsüberwachung',
                overlay: 'netzanalysegeraete',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/m4m-netzanalysegeraet.pdf',
            },
            {
                id: 'chk_energieeffiziente-antriebstechnik',
                label: 'Energieeffiziente Antriebstechnik',
                overlay: 'energieeffizienteantriebstechnik',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/zukunftsfaehiger-und-digitaler-antrieb.pdf',
            },
            {
                id: 'chk_kabelmanagement',
                label: 'Kabelmanagement',
                overlay: 'deltec',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/deltec-befestigungssysteme.pdf',
            },
            {
                id: 'chk_fi-automatischer-pruefung',
                label: 'FI mit automatischer Prüfung',
                overlay: 'fehlerstromschutzschalter',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/fehlerstrom-schutzschalter.pdf',
            },
            {
                id: 'chk_kunststoffgehaeuhse-steuerungsbau',
                label: 'Kunststoffgehäuse für den Steuerungsbau',
                overlay: 'aria',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/aria.pdf',
            },
            {
                id: 'chk_motormanagement',
                label: 'Motormanagement',
                overlay: 'motormanagementsystem',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/intelligentes-montomanagement.pdf',
            },
            {
                id: 'chk_energiemonitoring',
                label: 'Energiemonitoring',
                overlay: 'eqmatic',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-eqmatic.pdf',
            },
            {
                id: 'chk_intelligente-pumpantriebe',
                label: 'Intelligente Pumpantriebe',
                overlay: 'frequenzumrichter',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/acq-580.pdf',
            },
            {
                id: 'chk_automatische-umschalter',
                label: 'Automatische Umschalter',
                overlay: 'truone',
                link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/truone.pdf',
            },
        ],
    },
]
