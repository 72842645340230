import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export default function ABBLogo(props) {
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 746.291 301.146">
                <defs />
                <path
                    fill="#ec1b24"
                    d="M107.483 7.086l-49.055 140.19h88.882V7.086h-39.827zm45.618 0v140.19h89.546L193.59 7.086h-40.49zm-96.684 145.98L7.081 294.06h71.431l20.286-57.278h48.512v-83.716H56.417zm96.684 0v83.716h49.175l19.783 57.278h71.935l-49.337-140.994h-91.556zM313.777 7.086v140.19h83.575V7.086h-83.575zm89.345 0v140.19h87.254a86.11 86.11 0 00-29.152-19.1c19.74-11.06 33.052-32.184 33.052-56.433 0-35.71-28.878-64.657-64.596-64.657-.276 0-.55-.003-.824 0h-25.734zm-89.345 145.98V294.06h83.575V153.066h-83.575zm89.345 0V294.06h25.734c.276.002.547 0 .824 0 47.484 0 85.967-38.46 85.967-85.927 0-20.96-7.53-40.154-20.004-55.067h-92.521zM537.42 7.086v140.19h83.072V7.086H537.42zm88.862 0v140.19h87.656a86.002 86.002 0 00-29.131-19.1c19.764-11.06 33.132-32.184 33.132-56.433 0-35.71-28.959-64.657-64.677-64.657-.255 0-.509-.003-.764 0h-26.216zm-88.862 145.98V294.06h83.072V153.066H537.42zm88.862 0V294.06h26.216c.254.002.51 0 .764 0 47.485 0 85.948-38.46 85.948-85.927 0-20.96-7.515-40.154-19.984-55.067h-92.944z"
                />
            </svg>
        </SvgIcon>
    )
}
