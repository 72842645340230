import React from 'react'

import Grid from '@material-ui/core/Grid'

import QList from '../../../../components/QList/index'
import QIconRow from '../components/QIconRow/index'
import QBodyText from '../../../../components/QBodyText'
import QHeader from '../../../../components/QHeader'
import Slide from '../../../../components/Slide'
import ImageComponent from '../../../../components/ImageComponent'

import ABBLogo from '../../../../components/SvgIcons/ABBLogo'
import image1 from '../../../../assets/branchenkonfiguratorIndustrie/backgrounds/eq-matic.png'
import image2 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_11_energy_efficiency_0759.svg'
import image3 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABBicon_pc_mobile.png'
import image4 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_14_globe_0780.svg'

export default function EQMatic() {
    return (
        <Slide
            logo={<ABBLogo style={{ fontSize: '4rem' }} />}
            background={{
                enabled: true,
                alignment: 'right',
                width: '50%',
                height: 3.575 / 4,
                backgroundSize: 'cover',
                backgroundPosition: 'right',
            }}
            actions={[
                {
                    type: 'icon',
                    text: 'Web',
                    img: 'laptop',
                    target: '_blank',
                    link: 'https://new.abb.com/low-voltage/de/produkte/installationsgeraete/messgeraete/eqmatic',
                },
                {
                    type: 'icon',
                    text: 'YouTube',
                    img: 'youtube',
                    target: '_blank',
                    link: 'https://www.youtube.com/watch?app=desktop&v=yBDFEg_dG6k',
                },
                {
                    type: 'icon',
                    text: 'e-Configure',
                    img: 'konfigurator',
                    target: '_blank',
                    link: 'https://econfigure.de.abb.com/#/selector/9AAC189204',
                },
                {
                    type: 'icon',
                    text: 'PDF',
                    img: 'pdf',
                    target: '_blank',
                    link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-eqmatic.pdf',
                },
                {
                    type: 'icon',
                    text: 'Training',
                    img: 'seminar',
                    target: '_blank',
                    link: 'https://new.abb.com/service/de/training/abb-university/germany/seminare/termine/großhandelspartnerprogramm/energiemanagement-großhandel',
                },
            ]}
        >
            <Grid container wrap="nowrap" direction="column" style={{ height: '95vh' }}>
                <Grid item>
                    <QHeader title="ABB EQmatic Energiemonitoring" />
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <QBodyText>
                                Kompakte Lösung zur Überwachung, Speicherung, Visualisierung und Analyse von Energie-
                                und Verbrauchsdaten von Elektrizitäts-, Gas-, Wasser-, oder Wärmezählern über M-Bus oder
                                Modbus RTU.
                            </QBodyText>
                            <QList
                                items={[
                                    'Kostensenkung und erhöhte Energieeffizienz',
                                    'Unterstützung bei der Implementierung von Energiemanagementsystemen z. B. ISO 50001',
                                    'Schnelle, einfache Installation und Inbetriebnahme, keine zusätzliche PC-Software notwendig',
                                    'Herstellerunabhängige integration von Elektrizitäts-, Gas-, Wasser-, oder Wärmezählern',
                                ]}
                                dense
                                variant="dot"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '100%',
                                    }}
                                    spacing={2}
                                >
                                    <ImageComponent previewImgHeight="40vh" alt="EQMatic" src={image1} />
                                </Grid>
                                <Grid item>
                                    <QIconRow
                                        colWidth={4}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                        items={[
                                            {
                                                title: 'Energie-Effizienz',
                                                img: image2,
                                            },
                                            {
                                                title: 'Intelligente, skalierbare Lösung',
                                                img: image3,
                                            },
                                            {
                                                title: 'Betriebskontinuität',
                                                img: image4,
                                            },
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Slide>
    )
}
