import React, { useState, cloneElement } from 'react'

import Swiper from 'react-id-swiper'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

export default function QSlide({ width, children }) {
    const [swiper, setSwiper] = useState(null)

    const params = {
        slidesPerView: 1,
        centeredSlides: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
    }

    const SlidesWithSwiper = (props) => cloneElement(props.children, { swiper })

    const slides = children.map((slide, i) => (
        <Box key={`slide-${i}`} width="100%" height="100%">
            <SlidesWithSwiper>{slide}</SlidesWithSwiper>
        </Box>
    ))

    return (
        <Grid
            container
            style={{
                width: width,
                height: '100vh',
            }}
            alignItems="center"
            justifyContent="center"
        >
            <Grid item xs={12} style={{ height: '100vh' }}>
                <Swiper getSwiper={setSwiper} {...params}>
                    {slides}
                </Swiper>
            </Grid>
        </Grid>
    )
}

QSlide.defaultProps = {
    width: 'calc(100vw)',
    children: [],
}
