import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles(() => ({
    wrapper: {
        position: 'absolute',
        bottom: '15%',
        right: '0%',
        width: 'calc(90vw/ 2)',
    },
    card: {
        height: '100%',
        boxShadow: 'none',
    },
    cardText: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 'clamp(7px, 1.2vw, 1.2rem)',
        height: '14px',
        fontFamily: "'ABBvoice-Bold'",
        textAlign: 'center',
        lineHeight: '1.3',
    },
    imgWrapper: {
        overflow: 'hidden',
        margin: '0 auto',
        '& > img': {
            width: 'auto',
            height: 'calc(100vh / 15)',
        },
    },
}))
export default function QIconRow({ items, colWidth, objectFitOverride, imageMaxHeight }) {
    const classes = useStyles({ objectFitOverride, imageMaxHeight })

    return (
        <Box className={classes.wrapper} mt={items.length <= 8 ? '7%' : '3%'}>
            <Grid
                container
                direction="row"
                spacing={2}
                justifyContent="flex-start"
                alignItems="stretch"
                className={classes.gridWidth}
            >
                {items.map((item, i) => (
                    <Grid item xs={colWidth} key={`item-${i}`}>
                        {!Object.prototype.hasOwnProperty.call(item, 'placeholder') && (
                            <Paper className={classes.card} elevation={4}>
                                <Box p={1} paddingBottom={3}>
                                    <Grid container direction="column" justifyContent="center" alignItems="center" spacing={3}>
                                        <Grid item className={classes.imgWrapper}>
                                            <img src={item.img} alt={item.title} />
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.cardText}>{item.title}</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        )}
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}

QIconRow.defaultProps = {
    items: [],
    objectFitOverride: 'cover',
    imageMaxHeight: 'none',
    colWidth: 3,
}
