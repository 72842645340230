import React from 'react'

import { makeStyles, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import QActionCheckbox from '../QActionCheckbox'

const useStyles = makeStyles((theme) => ({
    overlayElement: {
        marginLeft: theme.spacing(6),
    },
}))

const StyledAccordion = withStyles((theme) => ({
    root: {
        background: 'transparent',
        boxShadow: 'none',
        padding: 0,
    },
}))(Accordion)

const StyledAccordionSummary = withStyles((theme) => ({
    root: {
        minHeight: 30,
        '&.Mui-expanded': {
            minHeight: 30,
        },
        '& .MuiAccordionSummary-content': {
            margin: 0,
        },
        '& .MuiAccordionSummary-expandIcon': {
            padding: '0 12px',
        },
    },
}))(AccordionSummary)

const StyledAccordionDetails = withStyles((theme) => ({
    root: {
        padding: 0,
    },
}))(AccordionDetails)

export default function QCheckboxAccordion({
    items,
    values,
    expanded,
    setExpanded,
    handleChangeAction,
    handleChangeDetails,
    readOnly,
}) {
    const classes = useStyles()

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    return (
        <Grid container>
            {items.map((action) => {
                if (action.overlays.length === 0) {
                    return (
                        <Grid key={action.id} item xs={12}>
                            <QActionCheckbox
                                valueID={action.id}
                                label={action.label}
                                value={values.includes(action.id)}
                                setAsset={handleChangeAction}
                                readOnly={readOnly}
                            />
                        </Grid>
                    )
                } else {
                    return (
                        <Grid key={action.id} item xs={12}>
                            <StyledAccordion expanded={expanded === action.id} onChange={handleChange(action.id)}>
                                <StyledAccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`${action.id}_content`}
                                    id={`${action.id}_header`}
                                >
                                    <QActionCheckbox
                                        valueID={action.id}
                                        label={action.label}
                                        value={values.includes(action.id)}
                                        setAsset={handleChangeAction}
                                        readOnly={readOnly}
                                    />
                                </StyledAccordionSummary>
                                <StyledAccordionDetails>
                                    <Grid container>
                                        {action.overlays.map((overlay) => {
                                            return (
                                                <Grid
                                                    key={`${action.id}_${overlay.id}`}
                                                    className={classes.overlayElement}
                                                    item
                                                    xs={12}
                                                >
                                                    <QActionCheckbox
                                                        valueID={`${action.id}_${overlay.id}`}
                                                        label={overlay.label}
                                                        value={values.includes(`${action.id}_${overlay.id}`)}
                                                        setAsset={handleChangeDetails}
                                                        readOnly={readOnly}
                                                    />
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </StyledAccordionDetails>
                            </StyledAccordion>
                        </Grid>
                    )
                }
            })}
        </Grid>
    )
}
