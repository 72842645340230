import React from 'react'

import Grid from '@material-ui/core/Grid'

import QList from '../../../../components/QList/index'
import QIconRow from '../components/QIconRow/index'
import QBodyText from '../../../../components/QBodyText'
import QHeader from '../../../../components/QHeader'
import Slide from '../../../../components/Slide'
import ImageComponent from '../../../../components/ImageComponent'

import ABBLogo from '../../../../components/SvgIcons/ABBLogo'
import image1 from '../../../../assets/branchenkonfiguratorIndustrie/backgrounds/TruONE.png'
import image2 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_14_easy_to_install_0779.svg'
import image3 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_13_speed_up_your_projects_0776.svg'
import image4 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_08_affordable_range_0702.svg'

export default function TruONE() {
    return (
        <Slide
            logo={<ABBLogo style={{ fontSize: '4rem' }} />}
            background={{
                enabled: true,
                alignment: 'right',
                width: '50%',
                height: 3.575 / 4,
            }}
            actions={[
                {
                    type: 'icon',
                    text: 'Web',
                    img: 'laptop',
                    target: '_blank',
                    link: 'https://new.abb.com/low-voltage/de/produkteinfuehrungen/truone-ats',
                },
                {
                    type: 'icon',
                    text: 'YouTube',
                    img: 'youtube',
                    target: '_blank',
                    link: 'https://www.youtube.com/watch?v=LkEOask3KmU&list=PLW1uFXqHQgue2joZ3dxMWHHJTwchdniZD&index=4',
                },
                {
                    type: 'icon',
                    text: 'e-Configure',
                    img: 'konfigurator',
                    target: '_blank',
                    link: 'https://econfigure.de.abb.com/#/selector/9AAC404840',
                },
                {
                    type: 'icon',
                    text: 'PDF',
                    img: 'pdf',
                    target: '_blank',
                    link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/truone.pdf',
                },
                {
                    type: 'icon',
                    text: 'Training',
                    img: 'seminar',
                    target: '_blank',
                    link: 'https://new.abb.com/service/de/training/abb-university/germany/seminare/termine/großhandelspartnerprogramm/energiemanagement-großhandel',
                },
            ]}
        >
            <Grid container wrap="nowrap" direction="column" style={{ height: '95vh' }}>
                <Grid item>
                    <QHeader title="TruONE® – Der automatische Netzumschalter" />
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <QBodyText>
                                TruONE<sup>™</sup> ist der weltweit erste automatische Umschalter (ATS), der Schalter
                                und Steuerung nahtlos in einer Einheit vereint.
                            </QBodyText>
                            <QList
                                items={[
                                    'Schnelle Installation und höchste Zuverlässigkeit dank reduzierter Kabel und Anschlüsse',
                                    'Reduzierte Ausfallzeiten und Servicekosten dank vorbeugender Wartung und modularer Komponenten',
                                    'Fortschrittliche Verbindungsfähigkeit',
                                    'Manueller Notbetrieb unter Spannung zur sofortigen Wiederherstellung der Stromversorgung im Falle einer Gerätefehlfunktion möglich',
                                ]}
                                dense
                                variant="dot"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item>
                                    <ImageComponent
                                        previewImgHeight="40vh"
                                        alt={
                                            <>
                                                TruONE<sup>®</sup>
                                            </>
                                        }
                                        src={image1}
                                    />
                                </Grid>
                                <Grid item>
                                    <QIconRow
                                        colWidth={4}
                                        items={[
                                            {
                                                title: 'Einfach',
                                                img: image2,
                                            },
                                            {
                                                title: 'Schnelle Installation',
                                                img: image3,
                                            },
                                            {
                                                title: 'Effizient',
                                                img: image4,
                                            },
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Slide>
    )
}
