import React, { useEffect } from 'react'

import { useAtomValue } from 'jotai/utils'

import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'

import { currentSlideAtom } from '../../atoms'
import { useDispatch, useSelector } from 'react-redux'
import { addTo, getBranchenkonfiguratorIndustrieData } from '../../redux/modules/chapterBranchenkonfiguratorIndustrie'

const useStyles = makeStyles(() => ({
    closeButton: {
        position: 'fixed',
        right: '20px',
        top: '20px',
        color: '#000',
        zIndex: '999',
        padding: '0',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    inheritHeight: {
        height: 'inherit',
        overflow: 'hidden',
    },
    height100: {
        height: '100%',
    },
    width100: {
        width: '100%',
    },
}))

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />)

export default function QDialog({ id, onCancel, open, children }) {
    const classes = useStyles()

    const dispatch = useDispatch()
    const { detailOverlays } = useSelector(getBranchenkonfiguratorIndustrieData)
    const currentSlide = useAtomValue(currentSlideAtom)

    useEffect(() => {
        if (id && currentSlide) {
            const curDia = currentSlide.overlays.find((el) => el.overlay === id)
            if (curDia && !detailOverlays.value.find((el) => el === `${currentSlide.id}_${curDia.id}`)) {
                dispatch(addTo({ id: 'detailOverlays', value: `${currentSlide.id}_${curDia.id}` }))
            }
        }
    }, [id, currentSlide, detailOverlays, dispatch])

    return (
        <Dialog fullScreen open={open} onClose={onCancel} TransitionComponent={Transition}>
            <IconButton className={classes.closeButton} color="inherit" onClick={onCancel} aria-label="close">
                <CloseIcon fontSize="large" />
            </IconButton>

            <Grid container justifyContent="center" alignItems="center" className={clsx(classes.inheritHeight)}>
                <Grid item className={clsx(classes.height100, classes.width100)}>
                    <Box className={classes.height100}>{children}</Box>
                </Grid>
            </Grid>
        </Dialog>
    )
}
