import React from 'react'

import root from 'window-or-global'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import Box from '@material-ui/core/Box'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'

import { useModal } from 'mui-modal-provider'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'

import QDialog from '../QDialog'

const useStyles = makeStyles(() => ({
    card: {
        height: '100%',
    },
    cardText: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 'clamp(13px, 1.2vw, 1.5rem)',
        height: '34px',
        fontFamily: "'ABBvoice-Bold'",
    },
    imgWrapper: {
        maxHeight: 'calc(100vh / 3.5)',
        overflow: 'hidden',
    },
    contentFit: {
        objectFit: (props) => props.objectFitOverride,
        maxHeight: (props) => props.imageMaxHeight,
    },
}))
const container = {
    maxWidth: '95%',
    margin: '0px auto',
}

export default function QBoxList({ items, colWidth, objectFitOverride, imageMaxHeight }) {
    const classes = useStyles({ objectFitOverride, imageMaxHeight })
    const { showModal } = useModal()

    return (
        <Grid container direction="row" spacing={2} justifyContent="flex-start" alignItems="center" style={container}>
            {items.map((item, i) => {
                const action = (e) => {
                    if (item.dialog) {
                        const dialog = showModal(QDialog, {
                            id: item.id,
                            children: item.dialogContent,
                            onConfirm: () => {
                                dialog.hide()
                            },
                            onCancel: () => {
                                dialog.hide()
                            },
                        })
                    } else if (
                        Object.prototype.hasOwnProperty.call(item, 'link') &&
                        Object.prototype.hasOwnProperty.call(item, 'target')
                    ) {
                        root.open(item.link, item.target, 'noopener')
                    } else if (Object.prototype.hasOwnProperty.call(item, 'callback')) {
                        item.callback(item, e)
                    }
                }

                return (
                    <Grid className={classes.card} item xs={colWidth} key={`item-${i}`}>
                        {!Object.prototype.hasOwnProperty.call(item, 'placeholder') && (
                            <PopupState variant="popover" popupId="demo-popup-popover">
                                {(popupState) => (
                                    <>
                                        <Card
                                            className={classes.card}
                                            onClick={action}
                                            {...(Object.prototype.hasOwnProperty.call(item, 'popover') && {
                                                ...bindTrigger(popupState),
                                            })}
                                        >
                                            <CardActionArea>
                                                <Box className={classes.imgWrapper}>
                                                    <CardMedia
                                                        component="img"
                                                        image={item.img}
                                                        alt={item.title}
                                                        className={classes.contentFit}
                                                    />
                                                </Box>
                                                <CardContent className={classes.cardText}>{item.title}</CardContent>
                                            </CardActionArea>
                                        </Card>
                                        {Object.prototype.hasOwnProperty.call(item, 'popover') && item.popover && (
                                            <Popover
                                                {...bindPopover(popupState)}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                }}
                                            >
                                                <Box p={2}>
                                                    <Typography onClick={popupState.close}>
                                                        {item.popoverContent}
                                                    </Typography>
                                                </Box>
                                            </Popover>
                                        )}
                                    </>
                                )}
                            </PopupState>
                        )}
                    </Grid>
                )
            })}
        </Grid>
    )
}

QBoxList.defaultProps = {
    items: [],
    objectFitOverride: 'cover',
    imageMaxHeight: 'none',
    colWidth: 3,
}
