import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const useStyles = makeStyles(() => ({
    font: {
        '& span': {
            fontSize: '18px',
        },
    },
}))

export default function QActionCheckbox({ value, valueID, label, setAsset, readOnly }) {
    const classes = useStyles()
    const toggle = (event) => {
        event.stopPropagation()
        event.preventDefault()
        setAsset(valueID, !value)
    }
    return (
        <FormControl component="fieldset" className={classes.font}>
            <FormControlLabel
                control={<Checkbox checked={value} value={value} id={valueID} color="primary" />}
                label={label}
                labelPlacement="end"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                onChange={toggle}
                disabled={readOnly}
            />
        </FormControl>
    )
}
