import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles({
    listItem: {
        fontSize: (props) => props.fontSize,
    },
    headline: {
        fontWeight: 'bold',
        marginBottom: '0',
        fontSize: 'clamp(1.1rem, 1.5vw, 1.5rem)',
    },
})
export default function QList({ items, variant, dense, divider, headline, fontSize }) {
    const classes = useStyles({ fontSize })

    let v

    switch (variant) {
        case 'dot':
            v = '●'
            break
        case 'arrow':
            v = '»'
            break
        case 'none':
            v = ''
            break
        default:
            v = '●'
    }
    const listItems = items.map((item, i) => [
        <ListItem
            key={`listItem-${i}`}
            style={{
                fontSize: 'clamp(1.1rem, 1.5vw, 1.5rem)',
                paddingTop: '3px',
                paddingBottom: '3px',
                alignItems: 'unset',
            }}
        >
            {variant !== 'none' && <ListItemIcon style={{ fontSize: '.8rem', paddingTop: '7px' }}>{v}</ListItemIcon>}
            <ListItemText className={classes.listItem}>{item}</ListItemText>
        </ListItem>,
        divider && i !== items.length - 1 && <Divider key={`divider-${i}`} />,
    ])

    return (
        <div>
            {headline && <p className={classes.headline}>{headline}</p>}
            <List dense={dense}>{listItems}</List>
        </div>
    )
}

QList.defaultProps = {
    items: [],
    variant: 'dot',
    dense: false,
    headline: '',
    divider: false,
    fontSize: null,
}
