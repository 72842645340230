import React from 'react'
import { useSelector } from 'react-redux'

import clsx from 'clsx'
import { makeStyles, styled } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { getBranchenkonfiguratorIndustrieData } from '../../../../../redux/modules/chapterBranchenkonfiguratorIndustrie'

const useStyles = makeStyles((theme) => ({
    root: {
        borderLeft: `7px solid ${theme.palette.common.abbPrimary}`,
        minHeight: 80,
    },
    spacing: {
        padding: (props) =>
            !props.right
                ? `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(2)}px 0`
                : `${theme.spacing(2)}px 0 ${theme.spacing(2)}px ${theme.spacing(4)}px`,
    },
    clickable: {
        cursor: 'pointer',
    },
}))

const QListItem = styled('div')(({ theme, sub }) => ({
    paddingTop: theme.spacing(1.2),
    marginBottom: !sub ? theme.spacing(1) : null,
    fontSize: sub ? 'clamp(1.1rem, 1.5vw, 1.5rem)' : '1.6rem',
    fontFamily: sub ? 'ABBvoice-Light' : 'ABBvoice-Regular',
    fontWeight: sub ? 'lighter' : 'bold',
    textUnderlineOffset: '.25rem',
}))

export default function QActionBox({ direction, item }) {
    const classes = useStyles({ right: direction === 'right' })
    const { detailOverlays } = useSelector(getBranchenkonfiguratorIndustrieData)

    const right = direction === 'right'
    const justifyContent = right ? 'flex-end' : 'flex-start'
    const align = right ? 'left' : 'right'

    const overlays = item.overlays?.filter((el) => detailOverlays.value.includes(`${item.id}_${el.id}`))

    return (
        <Grid container justifyContent={justifyContent}>
            <Grid item xs={6} className={clsx(right && classes.root, classes.spacing)} align={align}>
                <QListItem>
                    <span className={classes.clickable} onClick={() => window.open(`${item.link}`, '_blank')}>
                        {item.label}
                    </span>
                </QListItem>
                {overlays?.map((overlay) => {
                    return (
                        <QListItem key={`${item.id}_${overlay.id}`} sub>
                            <span
                                className={classes.clickable}
                                onClick={() => {
                                    if (overlay.link) {
                                        window.open(overlay.link, '_blank')
                                    } else {
                                        window.open(`${item.link}?open=${overlay.overlay}`, '_blank')
                                    }
                                }}
                            >
                                {overlay.label}
                            </span>
                        </QListItem>
                    )
                })}
            </Grid>
            {!right && <Grid item xs={6} className={classes.root} />}
        </Grid>
    )
}
