import React from 'react'

import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { useModal } from 'mui-modal-provider'

import QDialog from '../QDialog'

import imgElektrospicker from '../../assets/icons/elektrospicker.svg'
import imgPreisbeispiel from '../../assets/icons/preisbeispiel.svg'
import imgPreisliste from '../../assets/icons/preisliste.svg'
import imgLaptop from '../../assets/icons/laptop.svg'
import imgInfo from '../../assets/icons/infoIcon.svg'
import imgRegler from '../../assets/icons/regler.svg'
import imgMicrophone from '../../assets/icons/microphone.svg'
import imgYouTube from '../../assets/icons/youtube.svg'
import imgBestelldaten from '../../assets/icons/bestelldaten.svg'
import imgPDF from '../../assets/icons/pdf.svg'
import imgStromkompass from '../../assets/icons/stromkompass.svg'
import imgKonfigurator from '../../assets/icons/konfigurator.svg'
import imgSeminar from '../../assets/icons/seminar.svg'

const useStyles = makeStyles(() => ({
    slideWrap: {
        position: 'relative',
        height: 'inherit',
    },
    slideContent: {
        height: 'inherit',
        padding: '0.5rem 1.5rem 0 1.5rem',
    },
    actions: {
        position: 'absolute',
        display: 'flex',
        bottom: '15px',
        cursor: 'pointer',
        '& img': {
            width: 'auto',
            maxHeight: '40px',
            maxWidth: '45px',
        },
        '& .MuiBox-root': {
            width: 85,
        },
        paddingRight: '2.5rem',
        paddingLeft: '2.5rem',
    },
    childWrap: {
        paddingRight: '1rem',
        paddingLeft: '1rem',
        position: 'relative',
        height: 'inherit',
        '& > div:nth-of-type(1)': {
            height: '90%',
        },
    },
    actionLink: {
        fontSize: '12px',
        lineHeight: '.8rem',
    },
    logo: {
        position: 'absolute',
        bottom: '12px',
        right: '75px',
        zIndex: 200,
    },
}))

const BackgroundBox = ({ settings }) => (
    <Box
        left={settings.alignment === 'left' ? '0' : ''}
        right={settings.alignment === 'right' ? '0' : ''}
        position="absolute"
        width={settings.width}
        height={settings.height}
        bgcolor="var(--grey-20)"
        style={{
            backgroundSize: settings.backgroundSize,
            backgroundPosition: settings.backgroundPosition,
            backgroundImage: settings.src !== '' || settings.src !== null ? `url(${settings.src})` : null,
            backgroundRepeat: 'no-repeat',
            zIndex: settings.backgroundZIndex,
        }}
    />
)

export default function Slide({ children, actions, background, noSlideClass, logo }) {
    const classes = useStyles()
    const { showModal } = useModal()

    const dialog = (children) => {
        const d = showModal(QDialog, {
            children,
            onConfirm: () => {
                d.hide()
            },
            onCancel: () => {
                d.hide()
            },
        })
    }

    const actionItems = actions.map((action, i) => {
        let item = null
        let img = null
        switch (action.img) {
            case 'elektrospicker':
                img = imgElektrospicker
                break
            case 'preisbeispiel':
                img = imgPreisbeispiel
                break
            case 'preisliste':
                img = imgPreisliste
                break
            case 'laptop':
                img = imgLaptop
                break
            case 'info':
                img = imgInfo
                break
            case 'regler':
                img = imgRegler
                break
            case 'microphone':
                img = imgMicrophone
                break
            case 'youtube':
                img = imgYouTube
                break
            case 'bestelldaten':
                img = imgBestelldaten
                break
            case 'pdf':
                img = imgPDF
                break
            case 'stromkompass':
                img = imgStromkompass
                break
            case 'konfigurator':
                img = imgKonfigurator
                break
            case 'seminar':
                img = imgSeminar
                break
            default:
                img = action.imgSrc
                break
        }

        switch (action.type) {
            case 'button':
                item = (
                    <a href={action.link} target={action.target}>
                        <Button
                            variant="contained"
                            style={action.buttonBackground === 'white' ? { backgroundColor: '#fff' } : null}
                        >
                            {action.label}
                        </Button>
                    </a>
                )
                break
            case 'dialogWithButton':
                item = (
                    <Button
                        variant="contained"
                        style={action.buttonBackground === 'white' ? { backgroundColor: '#fff' } : null}
                        onClick={() => dialog(action.children)}
                    >
                        {action.label}
                    </Button>
                )
                break
            case 'dialog':
                item = <img src={img} onClick={() => dialog(action.children)} alt="slideImg1" />
                break
            case 'icon':
                item = (
                    <a href={action.link} target={action.target} className={classes.actionLink}>
                        <Grid container alignItems="flex-end" style={{ height: '100%' }}>
                            <Grid item xs={12} align="center">
                                <img src={img} alt="slideImg1" />
                            </Grid>
                            <Grid item xs={12} align="center" style={{ marginTop: '0.6em' }}>
                                {action.text !== undefined ? <span>{action.text}</span> : null}
                            </Grid>
                        </Grid>
                    </a>
                )
                break
            case 'custom':
                item = action.custom
                break
            default:
                break
        }

        return (
            <Box item xs={2} key={`${i}-action`}>
                {item}
            </Box>
        )
    })

    return (
        <Box className={clsx(noSlideClass ? '' : 'slide', classes.slideWrap)}>
            {background.enabled && <BackgroundBox settings={background} />}
            <Box className={classes.slideContent}>
                <Box className={classes.childWrap}>{children}</Box>
            </Box>
            {actionItems.length > 0 && (
                <Box className={classes.actions} container spacing={2}>
                    {actionItems}
                </Box>
            )}
            {logo !== null && <Box className={classes.logo}>{logo}</Box>}
        </Box>
    )
}

Slide.defaultProps = {
    children: null,
    actions: [],
    background: {
        enabled: false,
        alignment: 'right',
        width: 0,
        height: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'inherit',
    },
    noSlideClass: false,
    logo: null,
}
