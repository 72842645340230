import React from 'react'

import Grid from '@material-ui/core/Grid'

import QList from '../../../../components/QList/index'
import QIconRow from '../components/QIconRow/index'
import QBodyText from '../../../../components/QBodyText'
import QHeader from '../../../../components/QHeader'
import Slide from '../../../../components/Slide'
import ImageComponent from '../../../../components/ImageComponent'

import ABBLogo from '../../../../components/SvgIcons/ABBLogo'
import image1 from '../../../../assets/branchenkonfiguratorIndustrie/backgrounds/ABB-ability-software-monitors-frei.png'
import image2 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_13_speed_up_your_projects_0776.svg'
import image3 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_11_energy_efficiency_0759.svg'
import image4 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_14_easy_to_install_0779.svg'

export default function EDCS() {
    return (
        <Slide
            logo={<ABBLogo style={{ fontSize: '4rem' }} />}
            background={{
                enabled: true,
                alignment: 'right',
                width: '50%',
                height: 3.575 / 4,
            }}
            actions={[
                {
                    type: 'icon',
                    text: 'Web',
                    img: 'laptop',
                    target: '_blank',
                    link: 'https://new.abb.com/de/ueber-uns/geschaeftsbereiche/elektrifizierung/energy-and-asset-manager',
                },
                {
                    type: 'icon',
                    text: 'YouTube',
                    img: 'youtube',
                    target: '_blank',
                    link: 'https://www.youtube.com/watch?v=p6eseKeGe0g',
                },
                {
                    type: 'icon',
                    text: 'e-Configure',
                    img: 'konfigurator',
                    target: '_blank',
                    link: 'https://econfigure.de.abb.com/#/selector/9AAC167304',
                },
                {
                    type: 'icon',
                    text: 'PDF',
                    img: 'pdf',
                    target: '_blank',
                    link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-ability-energy-and-asset-manager.pdf',
                },
                {
                    type: 'icon',
                    text: 'Training',
                    img: 'seminar',
                    target: '_blank',
                    link: 'https://new.abb.com/service/de/training/abb-university/germany/seminare/termine/großhandelspartnerprogramm/energiemanagement-großhandel',
                },
            ]}
        >
            <Grid container wrap="nowrap" direction="column" style={{ height: '95vh' }}>
                <Grid item>
                    <QHeader
                        title={
                            <>
                                ABB Ability<sup>™</sup> Energy and Asset Manager
                            </>
                        }
                    />
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <QBodyText>
                                ABB Ability™ Energy and Asset Manager (EAM) ist eine cloud-basierte Plattform, die den
                                Energiefluss innerhalb des Energieversorgungssystems aufzeichnet, optimiert und
                                analysiert.
                            </QBodyText>

                            <QList
                                items={[
                                    'Basiert auf moderner Cloud-Architektur',
                                    'System ist überall und zu jeder Zeit verfügbar',
                                    'Höchstes Maß an Verlässlichkeit und Sicherheit',
                                ]}
                                dense
                                variant="dot"
                            />
                        </Grid>
                        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
                            <ImageComponent
                                wrapperHeight="34vh"
                                src={image1}
                                alt="ABB Ability™ Energy and Asset Manager Monitor Mockup"
                            />
                            <QIconRow
                                colWidth={4}
                                items={[
                                    {
                                        title: 'Projekt beschleunigen',
                                        img: image2,
                                    },
                                    {
                                        title: 'Hohe Energieeffizienz',
                                        img: image3,
                                    },
                                    {
                                        title: 'Einfach zu installieren',
                                        img: image4,
                                    },
                                ]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Slide>
    )
}
