import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import QList from '../../../../components/QList/index'
import QIconRow from '../components/QIconRow/index'
import QBodyText from '../../../../components/QBodyText'
import QHeader from '../../../../components/QHeader'
import Slide from '../../../../components/Slide'

import ABBLogo from '../../../../components/SvgIcons/ABBLogo'
import image1 from '../../../../assets/branchenkonfiguratorIndustrie/backgrounds/AG300405_hell.jpg'
import image2 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_15_electric_train_0790.svg'
import image3 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_14_easy_to_install_0779.svg'
import image4 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_12_reliable_in_extreme_conditions_0771.svg'

const useStyles = makeStyles((theme) => ({
    link: {
        textDecoration: 'underline',
        cursor: 'pointer',
        '&:after': {
            content: '"\\25B8"',
            marginLeft: theme.spacing(0.5),
        },
    },
}))

export default function BedingungenaufderSchiene({ swiper }) {
    const classes = useStyles()

    const goToSlide = (slideIndex) => {
        if (swiper) {
            swiper.slideTo(slideIndex)
        }
    }

    return (
        <Slide
            logo={<ABBLogo style={{ fontSize: '4rem' }} />}
            background={{
                src: image1,
                enabled: true,
                alignment: 'right',
                width: '50%',
                height: 3.575 / 4,
                backgroundSize: 'cover',
                backgroundPosition: 'left',
            }}
            actions={[
                {
                    type: 'icon',
                    text: 'Web',
                    img: 'laptop',
                    target: '_blank',
                    link: 'https://new.abb.com/low-voltage/de/schiene',
                },
                {
                    type: 'icon',
                    text: 'PDF',
                    img: 'pdf',
                    target: '_blank',
                    link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/applikationen-fuer-spezifische-bedingungen-auf-der-schiene.pdf',
                },
                {
                    type: 'icon',
                    text: 'Training',
                    img: 'seminar',
                    target: '_blank',
                    link: 'https://new.abb.com/service/de/training/abb-university/germany/seminare/termine/großhandelspartnerprogramm/schaltschrank-lösungen-großhandel',
                },
            ]}
        >
            <Grid container wrap="nowrap" direction="column" style={{ height: '95vh' }}>
                <Grid item>
                    <QHeader title="Applikationen für spezifische Bedingungen auf der Schiene" />
                </Grid>
                <Grid item>
                    <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Grid item xs={6}>
                            <QBodyText>
                                ABB kennt die Bedeutung und Komplexität des Betriebs von Eisenbahnnetzen und bietet
                                Produkte und Lösungen für energieeffiziente und zuverlässige Infrastrukturlösungen.
                            </QBodyText>
                            <QList
                                items={[
                                    <span key={0} className={swiper && classes.link} onClick={() => goToSlide(1)}>
                                        Hilfsschütze und Leistungsschütze
                                    </span>,
                                    <span key={0} className={swiper && classes.link} onClick={() => goToSlide(2)}>
                                        Elektronische Relais
                                    </span>,
                                    <span key={0} className={swiper && classes.link} onClick={() => goToSlide(3)}>
                                        Hochleistungssicherungsautomaten, Leitungsschutzschalter und
                                        Fehlerstromschutzeinrichtungen
                                    </span>,
                                ]}
                                dense
                                variant="dot"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <QIconRow
                                colWidth={4}
                                items={[
                                    {
                                        title: 'Lösungen für die Schiene',
                                        img: image2,
                                    },
                                    {
                                        title: 'Einfache Installation',
                                        img: image3,
                                    },
                                    {
                                        title: 'Zuverlässig in extremer Umgebung',
                                        img: image4,
                                    },
                                ]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Slide>
    )
}
