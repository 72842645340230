import branchenImg from '../assets/branchenkonfiguratorIndustrie/backgrounds/Oil & Gas 4.jpg'

export const dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' }

export const chapters = {
    branchenkonfiguratorIndustrie: {
        slug: 'branchenkonfigurator-industrie',
        label: 'BranchenSelect',
        link: '/branchenkonfigurator-industrie',
        chapterID: 10,
        img: branchenImg,
        frontpage: {
            titlePosition: {
                pos1: 'topleft',
                pos2: 'topright',
                pos3: 'topleft',
                pos4: 'topright',
            },
        },
        actionPage: null,
    },
}

export const typeOfChapters = {
    image: {
        label: 'Image',
        chapters: [],
    },
    products: {
        label: 'Produkte',
        chapters: ['branchenkonfiguratorIndustrie'],
    },
    applications: {
        label: 'Anwendungen',
        chapters: [],
    },
}
