import React from 'react'

import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { sectionStart } from '../../static/fullpageHelpers'

import FilledArrowLeft from '../SvgIcons/FilledArrowLeft'

const useStyles = makeStyles(() => ({
    title: {
        fontFamily: 'ABBvoice-Bold',
    },
    subTitle: {
        fontFamily: 'ABBvoice-Light',
    },
    arrowWrap: {
        cursor: 'pointer',
        margin: '16px 10px 0px 0px',
    },
    largeTitle: {
        fontSize: '42px',
    },
    largeSubTitle: {
        fontSize: '20px',
    },
    smallTitle: {
        fontSize: '20px',
    },
    smallSubTitle: {
        fontSize: '15px',
    },
}))

export default function QHeader({ title, subTitle, arrow, arrowOverride, size, colWidth, disableOnClick, ...props }) {
    const classes = useStyles()

    let titleSize
    let subTitleSize
    switch (size) {
        case 'large':
        case 'small':
        default: {
            titleSize = classes.largeTitle
            subTitleSize = classes.largeSubTitle
        }
    }

    const handleClick = disableOnClick ? () => {} : sectionStart

    return (
        <Grid container direction="column">
            <Grid
                item
                xs={typeof colWidth === 'undefined' ? 9 : colWidth}
                style={{ display: 'flex', alignItems: 'center' }}
            >
                {arrow && (
                    <div
                        id="qBackArrow"
                        className={classes.arrowWrap}
                        onClick={arrowOverride === null ? handleClick : arrowOverride}
                    >
                        <FilledArrowLeft color="primary" fontSize="12px" />
                    </div>
                )}
                <div>
                    <Typography
                        variant="h3"
                        className={clsx(classes.title, titleSize)}
                        gutterBottom={!subTitle}
                        {...props}
                        onClick={handleClick}
                    >
                        {title}
                    </Typography>
                    <Typography
                        variant="h5"
                        className={clsx(classes.subTitle, subTitleSize)}
                        gutterBottom
                        {...props}
                        onClick={handleClick}
                    >
                        {subTitle}
                    </Typography>
                </div>
            </Grid>
        </Grid>
    )
}

QHeader.defaultProps = {
    arrow: false,
    arrowOverride: null,
    size: 'large',
}
