import React from 'react'

import Grid from '@material-ui/core/Grid'

import QList from '../../../../components/QList/index'
import QIconRow from '../components/QIconRow/index'
import QHeader from '../../../../components/QHeader'
import Slide from '../../../../components/Slide'
import ImageComponent from '../../../../components/ImageComponent'
import QBodyText from '../../../../components/QBodyText'

import ABBLogo from '../../../../components/SvgIcons/ABBLogo'
import image1 from '../../../../assets/branchenkonfiguratorIndustrie/backgrounds/elektroindustrieprodukte.png'
import image2 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_03_enclosures_0580.svg'
import image3 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_14_easy_to_install_0779.svg'
import image4 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_05_operator_shedule_0653.svg'

export default function ElektronischeIndustrieprodukte() {
    return (
        <Slide
            logo={<ABBLogo style={{ fontSize: '4rem' }} />}
            background={{
                enabled: true,
                alignment: 'right',
                width: '50%',
                height: 3.575 / 4,
                backgroundSize: 'cover',
                backgroundPosition: 'right',
            }}
            actions={[
                {
                    type: 'icon',
                    text: 'Web',
                    img: 'laptop',
                    target: '_blank',
                    link: 'https://new.abb.com/low-voltage/de/produkte/elektronische-industrieprodukte',
                },
                {
                    type: 'icon',
                    text: 'e-Configure',
                    img: 'konfigurator',
                    target: '_blank',
                    link: 'https://econfigure.de.abb.com/#/categories/9AAC100114',
                },
                {
                    type: 'icon',
                    text: 'YouTube',
                    img: 'youtube',
                    target: '_blank',
                    link: 'https://eur03.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DfpWl89GBoLU&data=04%7C01%7Cjohanna.sommerfeld%40de.abb.com%7C3bcaf27e0ea942fdf65908d97da7e08d%7C372ee9e09ce04033a64ac07073a91ecd%7C0%7C0%7C637678982237470106%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C1000&sdata=F3%2FJ%2BSv008vC%2FAYs6jk4ABFVUsyLZAy2Of8F3tdRSGc%3D&reserved=0',
                },
                {
                    type: 'icon',
                    text: 'PDF',
                    img: 'pdf',
                    target: '_blank',
                    link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/elektronischeIndustrieprodukte.pdf',
                },
            ]}
        >
            <Grid container wrap="nowrap" direction="column" style={{ height: '95vh' }}>
                <Grid item>
                    <QHeader title="Elektronische Industrieprodukte" />
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <QList
                                headline="ABB bietet das umfassendste Angebot an"
                                items={[
                                    'elektronischen Zeitrelais',
                                    'Mess- und Überwachungsrelais',
                                    'Interface-Relais und',
                                    'Schaltnetzteilen',
                                ]}
                                dense
                                variant="dot"
                            />
                            <QBodyText>
                                Dies ermöglicht Ihnen, entscheidende Komponenten von einem global aufgestellten
                                Lieferanten zu beziehen.
                            </QBodyText>
                        </Grid>
                        <Grid item xs={6}>
                            <ImageComponent previewImgHeight="38vh" alt="Elektronische Relais" src={image1} />
                            <QIconRow
                                colWidth={4}
                                items={[
                                    {
                                        title: 'Umfangreiches Sortiment',
                                        img: image2,
                                    },
                                    {
                                        title: 'Einfache Installation',
                                        img: image3,
                                    },
                                    {
                                        title: 'Schnelle Konfiguration',
                                        img: image4,
                                    },
                                ]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Slide>
    )
}
