import React from 'react'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import QHeader from '../../../../../components/QHeader'
import QBox from '../../components/QBox'
import Slide from '../../../../../components/Slide'
import { silentMoveTo } from '../../../../../static/fullpageHelpers'
import Edcs from '../../Overlays/EDCS'
import Stecksockelsystem from '../../Overlays/Stecksockelsystem'
import Netzanalysegeraete from '../../Overlays/Netzanalysegeraete'
import TyRap from '../../Overlays/TyRap'
import SelbstnachspannendeKlemmen from '../../Overlays/SelbstnachspannendeKlemmen'
import EnergieeffizienteAntriebstechnik from '../../Overlays/EnergieeffizienteAntriebstechnik'
import UniversalMotorController from '../../Overlays/UniversalMotorController'
import FehlerstromSchutzschalter from '../../Overlays/FehlerstromSchutzschalter'
import QSlide from '../../components/QSlide'
import Deltec from '../../Overlays/Deltec'
import Softstarter from '../../Overlays/Softstarter'
import Leistungsschalter from '../../Overlays/Leistungsschalter'

import background from '../../../../../assets/branchenkonfiguratorIndustrie/backgrounds/chemie-oel-gas.jpg'
import image1 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_12_cloud_driven_mobile_devices_0768.svg'
import image2 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_14_easy_to_install_0779.svg'
import image3 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_03_for_switchgear_M2M_0584.svg'
import image4 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_14_around_service_0778.svg'
import image5 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_03_drive_for_switchgear_ACS550_0582.svg'
import image6 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_05_no_drilling_0643.svg'
import image7 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_03_for_switchgear_PSTB_0589.svg'
import image8 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_03_calciner_0578.svg'
import image9 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_03_device_0050.svg'

export default function ChemieOelGas() {
    const items = [
        {
            id: 'edcs',
            title: 'Digitales Energiemanagement',
            img: image1,
            dialog: true,
            dialogContent: <Edcs />,
        },
        {
            id: 'stecksockelsystem',
            title: 'Berührungssicheres Stecksockelsystem',
            img: image2,
            dialog: true,
            dialogContent: <Stecksockelsystem />,
        },
        {
            id: 'netzanalysegeraete',
            title: 'Leistungsüberwachung',
            img: image3,
            dialog: true,
            dialogContent: <Netzanalysegeraete />,
        },
        {
            id: 'fehlerstromschutzschalter',
            title: 'FI mit automatischer Prüfung',
            img: image4,
            dialog: true,
            dialogContent: <FehlerstromSchutzschalter />,
        },
        {
            id: 'energieeffizienteantriebstechnik',
            title: 'Energieeffiziente Antriebstechnik',
            img: image5,
            dialog: true,
            dialogContent: <EnergieeffizienteAntriebstechnik />,
        },
        {
            id: 'selbstnachspannendeklemmen',
            title: 'Schraubenlose Schaltgeräte',
            img: image6,
            dialog: true,
            dialogContent: <SelbstnachspannendeKlemmen />,
        },
        {
            id: 'motormanagementsystem',
            title: 'Motormanagement',
            img: image7,
            dialog: true,
            dialogContent: (
                <QSlide>
                    <UniversalMotorController />
                    <Softstarter />
                </QSlide>
            ),
        },
        {
            id: 'kabelmanagement',
            title: 'Kabelmanagement',
            img: image8,
            dialog: true,
            dialogContent: (
                <QSlide>
                    <TyRap />
                    <Deltec />
                </QSlide>
            ),
        },
        {
            id: 'leistungsschalter',
            title: 'Leistungsschalter',
            img: image9,
            dialog: true,
            dialogContent: <Leistungsschalter />,
        },
    ]

    return (
        <Slide
            background={{
                src: background,
                enabled: true,
                alignment: 'right',
                width: '66%',
                height: 3.575 / 4,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
            <Grid container direction="column" wrap="nowrap" justifyContent="space-between">
                <Grid item>
                    <QHeader
                        title="Smarte Lösungen"
                        subTitle="Chemie, Öl und Gas"
                        arrow
                        arrowOverride={() => silentMoveTo('dashboard', null)}
                    />
                </Grid>
                <Grid item>
                    <Box p={2}>
                        <QBox items={items} />
                    </Box>
                </Grid>
            </Grid>
        </Slide>
    )
}
