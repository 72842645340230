import React, { useState, forwardRef } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'

import SwiperComponent from '../SwiperComponent'

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'sticky',
        backgroundColor: 'var(--grey-20)',
        color: 'black',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    container: {
        width: 'fit-content',
        height: 'fit-content',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    imgContainer: {
        height: '90vh',
        width: '90vw',
    },
    imgMax: {
        display: 'flex',
        alignItems: 'center',
        maxHeight: '80% !important',
        maxWidth: '80% !important',
    },
    imgPreview: {
        maxHeight: '100%',
        maxWidth: '100%',
        width: '100%',
        objectFit: 'scale-down',
    },
    imgSlide: {
        height: 'inherit',
        width: 'inherit',
    },
}))

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />)

export default function ImageComponent(props) {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [moving, setMoving] = useState(true)

    const handleClickOpen = (e) => {
        if (!moving) {
            e.stopPropagation()
            e.preventDefault()
            setOpen(true)
        } else {
            setMoving(false)
        }
    }

    const handleClose = (e) => {
        e.stopPropagation()
        e.preventDefault()
        setOpen(false)
        setMoving(true)
    }

    const handleMoving = (e) => {
        e.stopPropagation()
        e.preventDefault()
        setMoving(true)
    }

    const params = {
        speed: 800,
        loop: false,
        zoom: {
            maxRatio: 5,
        },
    }

    return (
        <div
            className={classes.container}
            style={{
                height: props.wrapperHeight,
                width: props.wrapperWidth,
                position: props.wrapperPosition,
                display: props.display,
            }}
        >
            <img
                onClick={handleClickOpen}
                onTouchMove={handleMoving}
                onTouchStart={handleClickOpen}
                {...props}
                alt={props.alt}
                src={props.thumbnail ? props.thumbnail : props.src}
                className={
                    props.thumbnailClass ? props.thumbnailClass : props.className ? props.className : classes.imgPreview
                }
                style={{
                    height: props.previewImgHeight,
                    width: props.previewImgWidth,
                    maxHeight: props.previewImgMaxHeight,
                    maxWidth: props.previewImgMaxWidth,
                    position: props.wrapperPosition,
                }}
            />
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            {props.alt}
                        </Typography>
                        <IconButton autoFocus color="inherit" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <SwiperComponent params={params}>
                    <div className={classes.imgContainer}>
                        <div className="swiper-zoom-container">
                            <img src={props.src} alt={props.alt} className={classes.imgMax} />
                        </div>
                    </div>
                </SwiperComponent>
            </Dialog>
        </div>
    )
}

ImageComponent.defaultProps = {
    wrapperHeight: 'auto',
    wrapperWidth: 'auto',
    wrapperPosition: 'relative',
}
