import React from 'react'

import Grid from '@material-ui/core/Grid'

import QHeader from '../../../../components/QHeader'
import QIconRow from '../components/QIconRow/index'
import Slide from '../../../../components/Slide'
import ImageComponent from '../../../../components/ImageComponent'
import QList from '../../../../components/QList'
import QBodyText from '../../../../components/QBodyText'

import ABBLogo from '../../../../components/SvgIcons/ABBLogo'
import image1 from '../../../../assets/branchenkonfiguratorIndustrie/backgrounds/EnergiemonitoringUndEnergiemanagement.png'
import image2 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_11_energy_efficiency_0759.svg'
import image3 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABBicon_pc_mobile.png'
import image4 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_14_globe_0780.svg'

export default function EnergiemonitoringUndEnergiemanagement() {
    return (
        <Slide
            logo={<ABBLogo style={{ fontSize: '4rem' }} />}
            background={{
                enabled: true,
                alignment: 'right',
                width: '50%',
                height: 3.575 / 4,
                backgroundSize: 'cover',
                backgroundPosition: 'right',
            }}
            actions={[
                {
                    type: 'icon',
                    text: 'Web',
                    img: 'laptop',
                    target: '_blank',
                    link: 'https://new.abb.com/low-voltage/de-ch/produkte/installationsgeraete/messgeraete/system-pro-m-compact-insite',
                },
                {
                    type: 'icon',
                    text: 'YouTube',
                    img: 'youtube',
                    target: '_blank',
                    link: 'https://www.youtube.com/watch?v=k9qBIpc5IdM',
                },
                {
                    type: 'icon',
                    text: 'e-Configure',
                    img: 'konfigurator',
                    target: '_blank',
                    link: 'https://econfigure.de.abb.com/#/selector/9AAC174354',
                },
                {
                    type: 'icon',
                    text: 'PDF',
                    img: 'pdf',
                    target: '_blank',
                    link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/system-pro-m-compact-in-site.pdf',
                },
            ]}
        >
            <Grid container wrap="nowrap" direction="column" style={{ height: '95vh' }}>
                <Grid item>
                    <QHeader title="System pro M compact® InSite" />
                </Grid>
                <Grid container wrap="nowrap" direction="row" spacing={2}>
                    <Grid item xs={6}>
                        <QBodyText>
                            System pro M compact<sup>®</sup> InSite wurde speziell entwickelt, um die Anforderungen des
                            Energie- und Anlagenmanagements durch Überwachung und Steuerung des Energieflusses in
                            Unterverteilern zu erfüllen.
                        </QBodyText>

                        <QList
                            headline="Highlights SCU100"
                            items={[
                                'Sammelt Messdaten von bis zu 16 Energie- und Leistungsmessern sowie 96 Stromsensoren und digitalen Sensoren gleichzeitig ',
                                'Fernüberwachung des Systems dank einer digitalen Kommunikation, die verschiedene Protokolle unterstützt (Modbus RTU, TCP oder SNMP v1, v2 und verschlüsselt v3) ',
                                '2 Schnittstellen garantieren eine einfache Integration in jede IT-Infrastruktur: LAN (TCP/IP oder Modbus TCP) und RS485 (Modbus RTU)',
                            ]}
                            dense
                            variant="dot"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <ImageComponent
                            previewImgHeight="37vh"
                            alt="Energiemonitoring und Energiemanagement"
                            src={image1}
                        />
                        <QIconRow
                            colWidth={4}
                            items={[
                                {
                                    title: 'Energie-Effizienz',
                                    img: image2,
                                },
                                {
                                    title: 'Intelligente, skalierbare Lösung',
                                    img: image3,
                                },
                                {
                                    title: 'Betriebskontinuität',
                                    img: image4,
                                },
                            ]}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Slide>
    )
}
