import React from 'react'

import root from 'window-or-global'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { useModal } from 'mui-modal-provider'

import QDialog from '../../../../../components/QDialog'

const useStyles = makeStyles(() => ({
    gridWidth: {
        width: '95% !important',
        margin: '0px auto',
    },
    card: {
        height: '100%',
        cursor: 'pointer',
    },
    cardText: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 'clamp(13px, 1.2vw, 1.5rem)',
        height: '34px',
        fontFamily: "'ABBvoice-Bold'",
        textAlign: 'center',
    },
    imgWrapper: {
        overflow: 'hidden',
        margin: '0 auto',
        '& > img': {
            width: 'auto',
            height: 'calc(100vh / 10.5)',
        },
    },
}))
export default function QBox({ items, colWidth, objectFitOverride, imageMaxHeight }) {
    const classes = useStyles({ objectFitOverride, imageMaxHeight })
    const { showModal } = useModal()

    return (
        <Grid
            container
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            alignItems="stretch"
            className={classes.gridWidth}
        >
            {items.map((item, i) => {
                const action = (e) => {
                    if (item.dialog) {
                        const dialog = showModal(QDialog, {
                            id: item.id,
                            children: item.dialogContent,
                            onConfirm: () => {
                                dialog.hide()
                            },
                            onCancel: () => {
                                dialog.hide()
                            },
                        })
                    } else if (
                        Object.prototype.hasOwnProperty.call(item, 'link') &&
                        Object.prototype.hasOwnProperty.call(item, 'target')
                    ) {
                        root.open(item.link, item.target, 'noopener')
                    } else if (Object.prototype.hasOwnProperty.call(item, 'callback')) {
                        item.callback(item, e)
                    }
                }

                return (
                    <Grid item xs={colWidth} onClick={action} key={`item-${i}`}>
                        {!Object.prototype.hasOwnProperty.call(item, 'placeholder') && (
                            <Paper className={classes.card}>
                                <Box p={2}>
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        spacing={3}
                                    >
                                        <Grid item className={classes.imgWrapper}>
                                            <img src={item.img} alt={item.title} />
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.cardText}>{item.title}</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        )}
                    </Grid>
                )
            })}
        </Grid>
    )
}

QBox.defaultProps = {
    items: [],
    objectFitOverride: 'cover',
    imageMaxHeight: 'none',
    colWidth: 3,
}
