import React from 'react'

import Grid from '@material-ui/core/Grid'

import QList from '../../../../components/QList/index'
import QIconRow from '../components/QIconRow/index'
import QHeader from '../../../../components/QHeader'
import Slide from '../../../../components/Slide'
import ImageComponent from '../../../../components/ImageComponent'

import ABBLogo from '../../../../components/SvgIcons/ABBLogo'
import image1 from '../../../../assets/branchenkonfiguratorIndustrie/backgrounds/leistungsschalter.png'
import image2 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_06_cloud_connection_0201.svg'
import image3 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_03_for_switchgear_M2M_0584.svg'
import image4 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_13_speed_up_your_projects_0776.svg'

export default function Leistungsschalter() {
    return (
        <Slide
            logo={<ABBLogo style={{ fontSize: '4rem' }} />}
            background={{
                enabled: true,
                alignment: 'right',
                width: '50%',
                height: 3.575 / 4,
                backgroundSize: 'cover',
                backgroundPosition: 'right',
            }}
            actions={[
                {
                    type: 'icon',
                    text: 'Web',
                    img: 'laptop',
                    target: '_blank',
                    link: 'https://new.abb.com/low-voltage/de/produkte/leistungsschalter',
                },
                {
                    type: 'icon',
                    text: 'e-Configure',
                    img: 'konfigurator',
                    target: '_blank',
                    link: 'https://econfigure.de.abb.com/#/categories/9AAC100085',
                },
                {
                    type: 'icon',
                    text: 'YouTube',
                    img: 'youtube',
                    target: '_blank',
                    link: 'https://eur03.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DpnYgfMNWlxg&data=04%7C01%7Cjohanna.sommerfeld%40de.abb.com%7C3bcaf27e0ea942fdf65908d97da7e08d%7C372ee9e09ce04033a64ac07073a91ecd%7C0%7C0%7C637678982237470106%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C1000&sdata=e7SlNKdT0nXTqaMxF1qjNdxESCIEjDDdBnvY8lvF0x8%3D&reserved=0',
                },
                {
                    type: 'icon',
                    text: 'PDF',
                    img: 'pdf',
                    target: '_blank',
                    link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/leistungsschalter.pdf',
                },
            ]}
        >
            <Grid container wrap="nowrap" direction="column" style={{ height: '95vh' }}>
                <Grid item>
                    <QHeader title="SACE Emax 2 und Tmax XT" />
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <QList
                                headline="Anlagenmanagement der Zukunft"
                                items={[
                                    'Cloud-basiert: Zugriff auf Informationen, Überwachung und Kontrolle aus der Ferne, jederzeit und überall. Effizienz steigern und Energie sparen',
                                    'Maßgeschneidert: Maximale Flexibilität für jede Anwendung. Einfache Auswahl, ein universelles Zubehör und ein intuitives Design ebnen den Weg für die intelligente Fertigung von Panels und schnellen Upgrades. Selbst für kritische Projekte.'
                                ]}
                                dense
                                variant="dot"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ImageComponent previewImgHeight="40vh" alt="Elektronische Relais" src={image1} />
                            <QIconRow
                                colWidth={4}
                                items={[
                                    {
                                        title: 'Schnell via Cloud einzurichten',
                                        img: image2,
                                    },
                                    {
                                        title: 'Eine Benutzeroberfläche',
                                        img: image3,
                                    },
                                    {
                                        title: 'Schnelle Konfiguration',
                                        img: image4,
                                    },
                                ]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Slide>
    )
}
