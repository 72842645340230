import React from 'react'

import Grid from '@material-ui/core/Grid'

import QList from '../../../../components/QList/index'
import QIconRow from '../components/QIconRow/index'
import QHeader from '../../../../components/QHeader'
import Slide from '../../../../components/Slide'
import ImageComponent from '../../../../components/ImageComponent'

import ABBLogo from '../../../../components/SvgIcons/ABBLogo'
import image1 from '../../../../assets/branchenkonfiguratorIndustrie/backgrounds/elektronische-relais.png'
import image2 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_15_electric_train_0790.svg'
import image3 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_14_easy_to_install_0779.svg'
import image4 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_12_reliable_in_extreme_conditions_0771.svg'

export default function ElektronischeRelais() {
    return (
        <Slide
            logo={<ABBLogo style={{ fontSize: '4rem' }} />}
            background={{
                enabled: true,
                alignment: 'right',
                width: '50%',
                height: 3.575 / 4,
                backgroundSize: 'cover',
                backgroundPosition: 'right',
            }}
            actions={[
                {
                    type: 'icon',
                    text: 'Web',
                    img: 'laptop',
                    target: '_blank',
                    link: 'https://new.abb.com/low-voltage/de/industries/schiene/schienenfahrzeuge',
                },
                {
                    type: 'icon',
                    text: 'e-Configure',
                    img: 'konfigurator',
                    target: '_blank',
                    link: 'https://econfigure.de.abb.com/#/categories/9AAC100114',
                },
            ]}
        >
            <Grid container wrap="nowrap" direction="column" style={{ height: '95vh' }}>
                <Grid item>
                    <QHeader title="Elektronische Relais" />
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <QList
                                items={[
                                    'Störungsfreie Funktion der Zeit- und Überwachungsrelais mit hoher Rüttel- und Schockfestigkeit',
                                    'Mit ihrer hohen Temperaturbeständigkeit übertragen Relais und Hilfsschütze Steuersignale unabhängig vom Einbauort',
                                ]}
                                dense
                                variant="dot"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ImageComponent previewImgHeight="34vh" alt="Elektronische Relais" src={image1} />
                            <QIconRow
                                colWidth={4}
                                items={[
                                    {
                                        title: 'Lösungen für die Schiene',
                                        img: image2,
                                    },
                                    {
                                        title: 'Einfache Installation',
                                        img: image3,
                                    },
                                    {
                                        title: 'Zuverlässig in extremer Umgebung',
                                        img: image4,
                                    },
                                ]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Slide>
    )
}
