import React from 'react'

import Grid from '@material-ui/core/Grid'

import QList from '../../../../components/QList/index'
import QIconRow from '../components/QIconRow/index'
import QBodyText from '../../../../components/QBodyText'
import QHeader from '../../../../components/QHeader'
import Slide from '../../../../components/Slide'
import ImageComponent from '../../../../components/ImageComponent'

import ABBLogo from '../../../../components/SvgIcons/ABBLogo'
import image1 from '../../../../assets/branchenkonfiguratorIndustrie/backgrounds/Stahlnasenkabelbinder.png'
import image2 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_10_eye_0755.svg'
import image3 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_14_easy_to_install_0779.svg'
import image4 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_05_firehose_0630.svg'

export default function Stahlnasenkabelbinder() {
    return (
        <Slide
            logo={<ABBLogo style={{ fontSize: '4rem' }} />}
            background={{
                enabled: true,
                alignment: 'right',
                width: '50%',
                height: 3.575 / 4,
            }}
            actions={[
                {
                    type: 'icon',
                    text: 'Web',
                    img: 'laptop',
                    target: '_blank',
                    link: 'https://new.abb.com/low-voltage/de/produkte/innovative-kabelmanagement-systeme/kabelbinder-und-zubehoer/ty-rap/ty-rap-detectable',
                },
                {
                    type: 'icon',
                    text: 'e-Configure',
                    img: 'konfigurator',
                    target: '_blank',
                    link: 'https://econfigure.de.abb.com/#/selector/9AAC183568',
                },
                {
                    type: 'icon',
                    text: 'PDF',
                    img: 'pdf',
                    target: '_blank',
                    link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/stahlnasenkabelbinder.pdf',
                },
            ]}
        >
            <Grid container wrap="nowrap" direction="column" style={{ height: '95vh' }}>
                <Grid item>
                    <QHeader
                        title={
                            <>
                                Detektierbare Ty-Rap<sup>®</sup> Stahlnasenkabelbinder
                            </>
                        }
                    />
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <QBodyText>
                                        Die detektierbaren Kabelbinder wurden mit einer speziellen, zum Patent
                                        angemeldeten Polymerverbindung entwickelt, welche Metalldetektoren,
                                        Röntgengeräte und visuelle Erkennungssysteme aktiviert.
                                    </QBodyText>
                                    <QBodyText>
                                        Für Nahrungsmittel- und Getränkeverarbeitung, Pharmazeutische Industrie,
                                        Chemische Industrie
                                    </QBodyText>
                                </Grid>
                                <Grid item>
                                    <QList
                                        items={[
                                            'Erkennbar durch Metalldetektoren und Röntgengeräte',
                                            'Visuelle Erkennung durch die Farbe blau',
                                            'Hilft, die HACCP EU-Richtlinie umzusetzen',
                                            'Alle Vorteile des Ty-Rap®-Kabelbinders wie z. B. der Stahlnasenverschluss',
                                        ]}
                                        dense
                                        variant="dot"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid
                                    item
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <ImageComponent
                                        previewImgHeight="37vh"
                                        alt={
                                            <>
                                                Detektierbare Ty-Rap<sup>®</sup> Stahlnasenkabelbinder
                                            </>
                                        }
                                        src={image1}
                                    />
                                </Grid>
                                <Grid item>
                                    <QIconRow
                                        colWidth={4}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                        items={[
                                            {
                                                title: 'Detektierbar',
                                                img: image2,
                                            },
                                            {
                                                title: 'Einfache Installation',
                                                img: image3,
                                            },
                                            {
                                                title: 'Halogenfrei',
                                                img: image4,
                                            },
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Slide>
    )
}
