import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles(() => ({
    scrollBar: {
        overflowY: 'scroll',
        overflowX: 'hidden',
        height: (props) => props.overrideHeight,
        maxHeight: (props) => props.maxHeight,
        '&::-webkit-scrollbar': {
            '-webkit-appearance': 'none',
            width: '7px',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            backgroundColor: 'rgba(0, 0, 0, .5)',
            '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, .5)',
        },
    },
}))

export default function QScrollBody({ children, maxHeight, overrideHeight }) {
    const classes = useStyles({ maxHeight, overrideHeight })
    return (
        <Box className={classes.scrollBar} id="QSrollBody">
            {children}
        </Box>
    )
}

QScrollBody.defaultProps = {
    maxHeight: '100vh',
    overrideHeight: '100vh',
}
