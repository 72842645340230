import { combineReducers } from 'redux'

import branchenkonfiguratorIndustrie from './chapterBranchenkonfiguratorIndustrie'

const RESET_APP = 'branchenkonfigurator/rootReducer/RESET_APP'

const reducers = {
    branchenkonfiguratorIndustrie,
}

const appReducer = combineReducers({
    ...reducers,
})

const rootReducer = (state, action) => {
    if (action.type === RESET_APP) {
        state = {}
    }

    return appReducer(state, action)
}

export const resetApp = () => ({
    type: RESET_APP,
})

export default rootReducer
