import React from 'react'

import Box from '@material-ui/core/Box'

export default function QBackgroundBox({ settings }) {
    return (
        <Box
            left={settings.alignment === 'left' ? '0' : ''}
            right={settings.alignment === 'right' ? '0' : ''}
            position="absolute"
            width={settings.width}
            height={settings.height}
            bgcolor="var(--grey-20)"
            style={{
                backgroundSize: settings.backgroundSize,
                backgroundPosition: settings.backgroundPosition,
                backgroundImage: settings.src !== '' || settings.src !== null ? `url(${settings.src})` : null,
                backgroundRepeat: 'no-repeat',
                zIndex: settings.backgroundZIndex,
            }}
        />
    )
}
