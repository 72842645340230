import React from 'react'

import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(() => ({
    header: {
        fontFamily: 'ABBvoice-Regular',
        fontSize: 'clamp(18px, 1vw, 1.125rem)',
    },
    ml: {
        marginLeft: '1.5%',
    },
    content: {
        fontSize: 'clamp(18px, 1vw, 1.125rem)',
    },
}))

export default function QActionPaper({ title, context, children }) {
    const classes = useStyles()
    return (
        <Box pt={.2} pb={.2} pl={2} pr={2}>
            <Grid container direction="column" wrap="nowrap">
                <Grid item>
                    <Typography
                        className={clsx({
                            [classes.header]: true,
                            [classes.ml]: context === 'dialog',
                        })}
                    >
                        {title}
                    </Typography>
                </Grid>
                <Grid item>
                    <Paper square elevation={3}>
                        <Box p={1} className={classes.content}>
                            {children}
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    )
}

QActionPaper.defaultProps = {
    title: '',
    context: 'dialog',
    children: [],
}
