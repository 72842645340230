import React from 'react'

import Grid from '@material-ui/core/Grid'

import QIconRow from '../components/QIconRow/index'
import QBodyText from '../../../../components/QBodyText'
import QHeader from '../../../../components/QHeader'
import Slide from '../../../../components/Slide'
import ImageComponent from '../../../../components/ImageComponent'

import ABBLogo from '../../../../components/SvgIcons/ABBLogo'
import image1 from '../../../../assets/branchenkonfiguratorIndustrie/backgrounds/Ladeinfrastruktur.png'
import image2 from '../../../../assets/branchenkonfiguratorIndustrie/icons/massentaugliche-elektromobilitaet.svg'
import image3 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_14_around_service_0778.svg'
import image4 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_06_pc_app_0226.svg'

export default function Ladeinfrastruktur() {
    return (
        <Slide
            logo={<ABBLogo style={{ fontSize: '4rem' }} />}
            background={{
                enabled: true,
                alignment: 'right',
                width: '50%',
                height: 3.575 / 4,
            }}
            actions={[
                {
                    type: 'icon',
                    text: 'Web',
                    img: 'laptop',
                    target: '_blank',
                    link: 'https://new.abb.com/ev-charging/de',
                },
                {
                    type: 'icon',
                    text: 'YouTube',
                    img: 'youtube',
                    target: '_blank',
                    link: 'https://www.youtube.com/watch?v=FbVXyLNhhsc',
                },
                {
                    type: 'icon',
                    text: 'e-Configure',
                    img: 'konfigurator',
                    target: '_blank',
                    link: 'https://econfigure.de.abb.com/#/categories/9AAC172658',
                },
                {
                    type: 'icon',
                    text: 'PDF',
                    img: 'pdf',
                    target: '_blank',
                    link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/ladeinfrastruktur-fuer-elektrofahrzeuge.pdf',
                },
                {
                    type: 'icon',
                    text: 'Training',
                    img: 'seminar',
                    target: '_blank',
                    link: 'https://new.abb.com/service/de/training/abb-university/germany/seminare/termine/großhandelspartnerprogramm/energiemanagement-großhandel',
                },
            ]}
        >
            <Grid container wrap="nowrap" direction="column" style={{ height: '95vh' }}>
                <Grid item>
                    <QHeader title="Ladeinfrastruktur für Elektrofahrzeuge" />
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <QBodyText>
                                ABB bietet eine Gesamtlösung von kompakten AC-Wandladestationen, zuverlässigen
                                DC-Schnellladestationen mit stabiler Konnektivität bis hin zu innovativen
                                On-Demand-Ladesystemen für Elektrobusse.
                            </QBodyText>
                        </Grid>
                        <Grid item xs={6}>
                            <ImageComponent
                                previewImgHeight="40vh"
                                alt="Energieeffiziente Antriebstechnik"
                                src={image1}
                            />
                            <QIconRow
                                colWidth={4}
                                items={[
                                    {
                                        title: 'Massentaugliche Elektromobilität',
                                        img: image2,
                                    },
                                    {
                                        title: 'Service und Wartung',
                                        img: image3,
                                    },
                                    {
                                        title: 'Dynamisches Lastmanagement',
                                        img: image4,
                                    },
                                ]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Slide>
    )
}
