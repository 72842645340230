import React from 'react'

import Grid from '@material-ui/core/Grid'

import QList from '../../../../components/QList/index'
import QIconRow from '../components/QIconRow/index'
import QHeader from '../../../../components/QHeader'
import Slide from '../../../../components/Slide'
import ImageComponent from '../../../../components/ImageComponent'

import ABBLogo from '../../../../components/SvgIcons/ABBLogo'
import image1 from '../../../../assets/branchenkonfiguratorIndustrie/backgrounds/hilfsschuetze.png'
import image2 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_15_electric_train_0790.svg'
import image3 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_14_easy_to_install_0779.svg'
import image4 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_12_reliable_in_extreme_conditions_0771.svg'

export default function HilfsschuetzeUndLeistungsschuetze() {
    return (
        <Slide
            logo={<ABBLogo style={{ fontSize: '4rem' }} />}
            background={{
                enabled: true,
                alignment: 'right',
                width: '50%',
                height: 3.575 / 4,
                backgroundSize: 'cover',
                backgroundPosition: 'right',
            }}
            actions={[
                {
                    type: 'icon',
                    text: 'Web',
                    img: 'laptop',
                    target: '_blank',
                    link: 'https://new.abb.com/low-voltage/de/industries/schiene/schienenfahrzeuge',
                },
                {
                    type: 'icon',
                    text: 'e-Configure',
                    img: 'konfigurator',
                    target: '_blank',
                    link: 'https://econfigure.de.abb.com/#/selector/9AAC184308',
                },
            ]}
        >
            <Grid container wrap="nowrap" direction="column" style={{ height: '95vh' }}>
                <Grid item>
                    <QHeader title="Hilfsschütze und Leistungsschütze" />
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <QList
                                items={[
                                    'Durch ihre geringe Spulenleistung sind Hilfs- und Leistungsschütze in Schienenfahrzeugen besonders energieeffizient.',
                                    'Insbesondere bei Bremsanlagen und Klimaanlagen kommen die ABB Leistungsschütze mit besonders geringem Gewicht und geringem Bauvolumen zum Einsatz.',
                                    'Die AFZB-Schütze erfüllen die neuesten Normen für rollende Eisenbahnfahrzeuge und ermöglichen den Einbau in Fahrgast- oder Führerständen von Zügen, die häufig in Tunneln oder Unterführungen verkehren.',
                                ]}
                                dense
                                variant="dot"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item>
                                    <ImageComponent
                                        previewImgHeight="23vh"
                                        alt="Hilfsschütze und Leistungsschütze"
                                        src={image1}
                                    />
                                </Grid>
                                <Grid item>
                                    <QIconRow
                                        colWidth={4}
                                        items={[
                                            {
                                                title: 'Lösungen für die Schiene',
                                                img: image2,
                                            },
                                            {
                                                title: 'Einfache Installation',
                                                img: image3,
                                            },
                                            {
                                                title: 'Zuverlässig in extremer Umgebung',
                                                img: image4,
                                            },
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Slide>
    )
}
