import React from 'react'

import Grid from '@material-ui/core/Grid'

import QList from '../../../../components/QList/index'
import QIconRow from '../components/QIconRow/index'
import QBodyText from '../../../../components/QBodyText'
import QHeader from '../../../../components/QHeader'
import Slide from '../../../../components/Slide'
import ImageComponent from '../../../../components/ImageComponent'

import ABBLogo from '../../../../components/SvgIcons/ABBLogo'
import image1 from '../../../../assets/branchenkonfiguratorIndustrie/backgrounds/FehlerstromSchutzschalter.png'
import image2 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_08_piggy_bank_0718.svg'
import image3 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_13_speed_up_your_projects_0776.svg'
import image4 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_06_system_status_0679.svg'

export default function FehlerstromSchutzschalter() {
    return (
        <Slide
            logo={<ABBLogo style={{ fontSize: '4rem' }} />}
            background={{
                enabled: true,
                alignment: 'right',
                width: '50%',
                height: 3.575 / 4,
                backgroundSize: 'cover',
                backgroundPosition: 'right',
            }}
            actions={[
                {
                    type: 'icon',
                    text: 'Web',
                    img: 'laptop',
                    target: '_blank',
                    link: 'https://new.abb.com/low-voltage/de/produkte/installationsgeraete/fehlerstrom-schutzeinrichtungen/fis-mit-automatischer-prueffunktion',
                },
                {
                    type: 'icon',
                    text: 'YouTube',
                    img: 'youtube',
                    target: '_blank',
                    link: 'https://search.abb.com/library/Download.aspx?DocumentID=9AKK107046A8793&LanguageCode=de&DocumentPartId=&Action=Launch',
                },
                {
                    type: 'icon',
                    text: 'e-Configure',
                    img: 'konfigurator',
                    target: '_blank',
                    link: 'https://econfigure.de.abb.com/#/selector/9AAC187564',
                },
                {
                    type: 'icon',
                    text: 'PDF',
                    img: 'pdf',
                    target: '_blank',
                    link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/fehlerstrom-schutzschalter.pdf',
                },
            ]}
        >
            <Grid container wrap="nowrap" direction="column" style={{ height: '95vh' }}>
                <Grid item>
                    <QHeader title="Fehlerstrom-Schutzschalter mit automatischer Prüffunktion" />
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <QBodyText>
                                ATI Test und F-ARI Test sind RCCBs (Fehlerstrom-Schutzschalter) mit automatischer
                                unterbrechungsfreier Prüffunktion. RCCBs sollen je nach Anwendung alle sechs Monate
                                geprüft werden. Damit wird gewährleistet, dass die Kontakte ordnungsgemäß funktionieren
                                und dass das Gerät bei einem Fehlerstrom gegen Erde auslöst.
                            </QBodyText>
                            <QList
                                items={[
                                    'Regelmäßige automatische Tests (alle 28 Tage) ohne Unterbrechung der Anlage',
                                    'Die Bedienung durch das Wartungspersonal kann deshalb auf ein Minimum reduziert werden. ',
                                    'Inkl. automatischer Wiedereinschaltung (F-ARI)',
                                    'Modubus-Modul optional',
                                ]}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ImageComponent
                                previewImgHeight="47vh"
                                previewImgWidth="35vw"
                                alt="Fehlerstrom Schutzschalter"
                                src={image1}
                            />
                            <QIconRow
                                colWidth={4}
                                items={[
                                    {
                                        title: 'Einsparung',
                                        img: image2,
                                    },
                                    {
                                        title: 'Schnelle Installation',
                                        img: image3,
                                    },
                                    {
                                        title: 'Intelligent',
                                        img: image4,
                                    },
                                ]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Slide>
    )
}
