/* eslint-disable no-console */
import axios from 'axios'
import { toast } from 'react-toastify'

class APIService {
    constructor() {
        let service = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 10000,
        })

        this.service = service
    }

    isNetworkError = (error) => !!error.isAxiosError && !error.response

    handleRequest = (config) => {
        return config
    }

    handleRequestError = (error) => Promise.reject(error)

    handleResponseSuccess = (response) => {
        if (process.env.NODE_ENV === 'development') {
            console.log(`%c${response.config.url}`, 'color:cyan; font-size: 15px')
            console.table(response.data)
            console.log('%c----------------------', 'color:purple; font-size: 15px')
        }
        return response
    }

    handleResponseError = (error) => {
        console.log('ERROR: ', this.isNetworkError(error))
        if (this.isNetworkError(error)) {
            toast.error('Backend nicht erreichbar.')
            return Promise.reject(error)
        }
        return Promise.reject(error)
    }

    redirectTo = (document, path) => {
        document.location = path
    }

    async get(path, callback = () => {}) {
        return this.service.get(path).then((response) => callback(response.status, response.data))
    }

    async delete(path, callback = () => {}) {
        return this.service.delete(path).then((response) => callback(response.status, response.data))
    }

    async patch(path, payload, callback = () => {}) {
        return this.service
            .request({
                method: 'PATCH',
                url: path,
                responseType: 'json',
                data: payload,
            })
            .then((response) => callback(response.status, response.data))
    }

    async post(path, payload, responseType, callback = () => {}) {
        const response = await this.service.request({
            method: 'POST',
            url: path,
            responseType,
            data: payload,
        })
        return callback(response.status, response.data)
    }
}

export default new APIService()
