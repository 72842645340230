import React from 'react'

import BedingungenaufderSchiene from '../../Overlays/BedingungenaufderSchiene'
import Edcs from '../../Overlays/EDCS'
import Maschinensicherheit from '../../Overlays/Maschinensicherheit'
import Dbt from '../../Overlays/DBT'
import FehlerstromSchutzschalter from '../../Overlays/FehlerstromSchutzschalter'
import EnergieeffizienteAntriebstechnik from '../../Overlays/EnergieeffizienteAntriebstechnik'
import Netzanalysegeraete from '../../Overlays/Netzanalysegeraete'
import Softstarter from '../../Overlays/Softstarter'
import Stecksockelsystem from '../../Overlays/Stecksockelsystem'
import UniversalMotorController from '../../Overlays/UniversalMotorController'
import EnergiemonitoringUndEnergiemanagement from '../../Overlays/EnergiemonitoringUndEnergiemanagement'
import Aria from '../../Overlays/Aria'
import Ladeinfrastruktur from '../../Overlays/Ladeinfrastruktur'
import SelbstnachspannendeKlemmen from '../../Overlays/SelbstnachspannendeKlemmen'
import Stahlnasenkabelbinder from '../../Overlays/Stahlnasenkabelbinder'
import TruONE from '../../Overlays/TruONE'
import Deltec from '../../Overlays/Deltec'
import TyRap from '../../Overlays/TyRap'
import SmartSensorMotoren from '../../Overlays/SmartSensorMotoren'
import EQMatic from '../../Overlays/EQMatic'
import NetzUndAnlagenschutz from '../../Overlays/NetzUndAnlagenschutz'
import Frequenzumrichter from '../../Overlays/Frequenzumrichter'
import QSlide from '../QSlide'
import HilfsschuetzeUndLeistungsschuetze from '../../Overlays/HilfsschuetzeUndLeistungsschuetze'
import ElektronischeRelais from '../../Overlays/ElektronischeRelais'
import Hochleistungssicherungsautomaten from '../../Overlays/Hochleistungssicherungsautomaten'
import ElektronischeIndustrieprodukte from '../../Overlays/ElektronischeIndustrieprodukte'
import Gehaeusesysteme from '../../Overlays/Gehaeusesysteme'
import Leistungsschalter from '../../Overlays/Leistungsschalter'

const detailOverlays = {
    aria: {
        component: <Aria />,
    },
    bedingungenaufderschiene: {
        component: (
            <QSlide>
                <BedingungenaufderSchiene />
                <HilfsschuetzeUndLeistungsschuetze />
                <ElektronischeRelais />
                <Hochleistungssicherungsautomaten />
            </QSlide>
        ),
    },
    dbt: {
        component: <Dbt />,
    },
    deltec: {
        component: <Deltec />,
    },
    edcs: {
        component: <Edcs />,
    },
    energieeffizienteantriebstechnik: {
        component: <EnergieeffizienteAntriebstechnik />,
    },
    energiemanagement: {
        component: <EnergiemonitoringUndEnergiemanagement />,
    },
    eqmatic: {
        component: <EQMatic />,
    },
    fehlerstromschutzschalter: {
        component: <FehlerstromSchutzschalter />,
    },
    frequenzumrichter: {
        component: <Frequenzumrichter />,
    },
    kabelmanagement: {
        component: (
            <QSlide>
                <TyRap />
                <Deltec />
            </QSlide>
        ),
    },
    ladeinfrastruktur: {
        component: <Ladeinfrastruktur />,
    },
    maschinensicherheit: {
        component: <Maschinensicherheit />,
    },
    motormanagementsystem: {
        component: (
            <QSlide>
                <UniversalMotorController />
                <Softstarter />
            </QSlide>
        ),
    },
    netzanalysegeraete: {
        component: <Netzanalysegeraete />,
    },
    netzundanlagenschutz: {
        component: <NetzUndAnlagenschutz />,
    },
    selbstnachspannendeklemmen: {
        component: <SelbstnachspannendeKlemmen />,
    },
    smartsensormotoren: {
        component: <SmartSensorMotoren />,
    },
    softstarter: {
        component: <Softstarter />,
    },
    stahlnasenkabelbinder: {
        component: <Stahlnasenkabelbinder />,
    },
    stecksockelsystem: {
        component: <Stecksockelsystem />,
    },
    systemprocompact: {
        component: <EnergiemonitoringUndEnergiemanagement />,
    },
    truone: {
        component: <TruONE />,
    },
    tyrap: {
        component: <TyRap />,
    },
    universalmotorcontroller: {
        component: <UniversalMotorController />,
    },
    elektronischeIndustrieprodukte: {
        component: <ElektronischeIndustrieprodukte />,
    },
    gehaeusesysteme: {
        component: <Gehaeusesysteme />,
    },
    leistungsschalter: {
        component: <Leistungsschalter />,
    },
}

export default function OverlaySelector({ id }) {
    let renderData = detailOverlays[id.toLowerCase()].component

    return renderData
}

OverlaySelector.defaultProps = {
    id: 'aria',
}
