import React from 'react'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import QHeader from '../../../../../components/QHeader'
import QBox from '../../components/QBox'
import Slide from '../../../../../components/Slide'
import { silentMoveTo } from '../../../../../static/fullpageHelpers'
import BedingungenaufderSchiene from '../../Overlays/BedingungenaufderSchiene'
import TyRap from '../../Overlays/TyRap'
import Aria from '../../Overlays/Aria'
import QSlide from '../../components/QSlide'
import HilfsschuetzeUndLeistungsschuetze from '../../Overlays/HilfsschuetzeUndLeistungsschuetze'
import ElektronischeRelais from '../../Overlays/ElektronischeRelais'
import Hochleistungssicherungsautomaten from '../../Overlays/Hochleistungssicherungsautomaten'

import background from '../../../../../assets/branchenkonfiguratorIndustrie/backgrounds/bahn-und-schienenfahrzeuge.jpg'
import image1 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_15_old_train_0798.svg'
import image2 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_03_calciner_0578.svg'
import image3 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_03_cdc_0038.svg'

export default function BahnSchienenfahrzeuge() {
    const items = [
        {
            id: 'bedingungenaufderschiene',
            title: 'Produkte mit Bahnzulassung',
            img: image1,
            dialog: true,
            dialogContent: (
                <QSlide>
                    <BedingungenaufderSchiene />
                    <HilfsschuetzeUndLeistungsschuetze />
                    <ElektronischeRelais />
                    <Hochleistungssicherungsautomaten />
                </QSlide>
            ),
        },
        {
            id: 'tyrap',
            title: 'Kabelmanagement',
            img: image2,
            dialog: true,
            dialogContent: <TyRap />,
        },
        { placeholder: true },
        { placeholder: true },
        {
            id: 'aria',
            title: 'Kunststoffgehäuse für den Steuerungsbau',
            img: image3,
            dialog: true,
            dialogContent: <Aria />,
        },
    ]

    return (
        <Slide
            background={{
                src: background,
                enabled: true,
                alignment: 'right',
                width: '66%',
                height: 3.575 / 4,
                backgroundSize: 'cover',
                backgroundPosition: 'left',
            }}
        >
            <Grid container direction="column" wrap="nowrap" justifyContent="space-between">
                <Grid item>
                    <QHeader
                        title="Smarte Lösungen"
                        subTitle="Bahn und Schienenfahrzeuge"
                        arrow
                        arrowOverride={() => silentMoveTo('dashboard', null)}
                    />
                </Grid>
                <Grid item>
                    <Box p={2}>
                        <QBox items={items} />
                    </Box>
                </Grid>
            </Grid>
        </Slide>
    )
}
