import React from 'react'

import Grid from '@material-ui/core/Grid'

import QList from '../../../../components/QList/index'
import QIconRow from '../components/QIconRow/index'
import QBodyText from '../../../../components/QBodyText'
import QHeader from '../../../../components/QHeader'
import Slide from '../../../../components/Slide'
import ImageComponent from '../../../../components/ImageComponent'

import ABBLogo from '../../../../components/SvgIcons/ABBLogo'
import image1 from '../../../../assets/branchenkonfiguratorIndustrie/backgrounds/aria.png'
import image2 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_03_enclosures_0580.svg'
import image3 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_14_easy_to_install_0779.svg'
import image4 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_05_operator_shedule_0653.svg'

export default function Aria() {
    return (
        <Slide
            logo={<ABBLogo style={{ fontSize: '4rem' }} />}
            background={{
                enabled: true,
                alignment: 'right',
                width: '50%',
                height: 3.575 / 4,
            }}
            actions={[
                {
                    type: 'icon',
                    text: 'Web',
                    img: 'laptop',
                    target: '_blank',
                    link: 'https://industrialsolutions.de.abb.com/produkte/gehau-se/aria-universelle-polyester-schranke',
                },
                {
                    type: 'icon',
                    text: 'e-Configure',
                    img: 'konfigurator',
                    target: '_blank',
                    link: 'https://econfigure.de.abb.com/#/search/Aria',
                },
                {
                    type: 'icon',
                    text: 'PDF',
                    img: 'pdf',
                    target: '_blank',
                    link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/aria.pdf'
                },
                {
                    type: 'icon',
                    text: 'Training',
                    img: 'seminar',
                    target: '_blank',
                    link: 'https://new.abb.com/service/de/training/abb-university/germany/seminare/termine/großhandelspartnerprogramm/schaltschrank-lösungen-großhandel'
                },
            ]}
        >
            <Grid container wrap="nowrap" direction="column" style={{ height: '95vh' }}>
                <Grid item>
                    <QHeader title="ARIA – Universelle Gehäuse aus Polyester" />
                </Grid>
                <Grid item>
                    <Grid container>
                        <Grid item xs={6}>
                            <QBodyText>
                                Umfangreiche Auswahl an Gehäusen, sowohl mit Abdeckungen (Kästen) als auch mit Türen
                                (Schränke). Gehäuse aus Polyester für Außenanwendungen bieten zahlreiche
                                Auslegungsoptionen und Lösungen für fast jeden Anspruch.
                            </QBodyText>
                            <QList
                                items={[
                                    'Ergonomisches Schließsystem',
                                    'Reduzierte Schließkräfte um bis zu 25%',
                                    'Patentierter Türheber stellt sicher, dass die Tür beim Schließen zentriert ist',
                                    'Selbsthaftende Regenschutzkante als Standardzubehör zu jedem Schrank',
                                ]}
                                dense
                                variant="dot"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ImageComponent
                                previewImgHeight="38vh"
                                alt="ARIA - Universelle Gehäuse aus Polyester"
                                src={image1}
                            />
                            <QIconRow
                                colWidth={4}
                                items={[
                                    {
                                        title: 'Umfangreiches Sortiment',
                                        img: image2,
                                    },
                                    {
                                        title: 'Einfache Installation',
                                        img: image3,
                                    },
                                    {
                                        title: 'Schnelle Konfiguration',
                                        img: image4,
                                    },
                                ]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Slide>
    )
}
