import { createSlice } from '@reduxjs/toolkit'

export const branchenkonfiguratorIndustrieSlice = createSlice({
    name: 'branchenkonfiguratorIndustrie',
    initialState: {
        detailOverlays: {
            description: 'Lesezeichen für das Kapitel',
            value: [],
        },
    },
    reducers: {
        setAsset: (state, action) => {
            state[action.payload.id].value = action.payload.value
        },
        addTo: (state, action) => {
            if (Array.isArray(action.payload.value)) {
                state[action.payload.id].value = [...state[action.payload.id].value, ...action.payload.value]
            } else {
                state[action.payload.id].value = [...state[action.payload.id].value, action.payload.value]
            }
        },
        removeFrom: (state, action) => {
            const valueTag = action.payload.value

            if (Array.isArray(valueTag)) {
                state[action.payload.id].value = state[action.payload.id].value.filter(
                    (v) => !valueTag.find((id) => v === id)
                )
            } else {
                state[action.payload.id].value = state[action.payload.id].value.filter((v) => v !== valueTag)
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const { setAsset, addTo, removeFrom } = branchenkonfiguratorIndustrieSlice.actions

//selector
export const getBranchenkonfiguratorIndustrieData = (state) => state.branchenkonfiguratorIndustrie

export default branchenkonfiguratorIndustrieSlice.reducer
