import React from 'react'

import Grid from '@material-ui/core/Grid'

import QList from '../../../../components/QList/index'
import QIconRow from '../components/QIconRow/index'
import QBodyText from '../../../../components/QBodyText'
import QHeader from '../../../../components/QHeader'
import Slide from '../../../../components/Slide'

import ABBLogo from '../../../../components/SvgIcons/ABBLogo'
import image1 from '../../../../assets/branchenkonfiguratorIndustrie/backgrounds/Stecksockelsystem.jpg'
import image2 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_03_enclosures_0580.svg'
import image3 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_13_speed_up_your_projects_0776.svg'
import image4 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABBicon_robot.svg'

export default function Stecksockelsystem() {
    return (
        <Slide
            logo={<ABBLogo style={{ fontSize: '4rem' }} />}
            background={{
                src: image1,
                enabled: true,
                alignment: 'right',
                width: '50%',
                height: 3.575 / 4,
                backgroundSize: 'cover',
                backgroundPosition: 'left',
            }}
            actions={[
                {
                    type: 'icon',
                    text: 'Web',
                    img: 'laptop',
                    target: '_blank',
                    link: 'https://new.abb.com/low-voltage/de/produkte/installationsgeraete/smissline-tp',
                },
                {
                    type: 'icon',
                    text: 'YouTube',
                    img: 'youtube',
                    target: '_blank',
                    link: 'https://search.abb.com/library/Download.aspx?DocumentID=9AKK107046A7019&LanguageCode=de&DocumentPartId=&Action=Launch',
                },
                {
                    type: 'icon',
                    text: 'e-Configure',
                    img: 'konfigurator',
                    target: '_blank',
                    link: 'https://econfigure.de.abb.com/#/categories/9AAC100502',
                },
                {
                    type: 'icon',
                    text: 'PDF',
                    img: 'pdf',
                    target: '_blank',
                    link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/stecksockelsystem.pdf',
                },
                {
                    type: 'icon',
                    text: 'Training',
                    img: 'seminar',
                    target: '_blank',
                    link: 'https://new.abb.com/service/de/training/abb-university/germany/seminare/termine/großhandelspartnerprogramm/schaltschrank-lösungen-großhandel',
                },
            ]}
        >
            <Grid container wrap="nowrap" direction="column" style={{ height: '95vh' }}>
                <Grid item>
                    <QHeader title="Stecksockelsystem SMISSLINE TP" />
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <QBodyText>
                                Steckbare Geräte lassen sich schnell, sicher und einfach im laufenden Betrieb ergänzen
                                und auswechseln. Dies ist ohne persönliche Schutzausrüstung gegen elektrische Gefährdung
                                möglich.
                            </QBodyText>
                            <QList
                                items={[
                                    '4-7 Stunden Zeitersparnis',
                                    '20 % Platzersparnis',
                                    'Flexibel wie ein Schweizer Taschenmesser',
                                    'Fingersicher, erlaubt Gerätewechsel unter Spannung',
                                ]}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container justifyContent="center">
                                <Grid item xs={12}>
                                    <QIconRow
                                        colWidth={4}
                                        items={[
                                            {
                                                title: 'Verfügbarkeit',
                                                img: image2,
                                            },
                                            {
                                                title: 'Zeitersparnis',
                                                img: image3,
                                            },
                                            {
                                                title: 'Arbeitssicherheit',
                                                img: image4,
                                            },
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Slide>
    )
}
