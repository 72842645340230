import React from 'react'

import Swiper from 'react-id-swiper'
// Version >= 2.4.0
import 'swiper/css/swiper.css'

const SwiperComponent = (props) => {
    SwiperComponent.defaultProps = {
        containerClass: 'swiper-container',
        wrapperClass: '',
        slideClass: '',
        autoHeight: false,
    }
    return (
        <Swiper containerClass={props.containerClass} {...props.params} autoHeight={props.autoHeight}>
            {props.children}
        </Swiper>
    )
}

export default SwiperComponent
