import React from 'react'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import QHeader from '../../../../../components/QHeader'
import QBox from '../../components/QBox'
import Slide from '../../../../../components/Slide'
import { silentMoveTo } from '../../../../../static/fullpageHelpers'
import Stecksockelsystem from '../../Overlays/Stecksockelsystem'
import Maschinensicherheit from '../../Overlays/Maschinensicherheit'
import TyRap from '../../Overlays/TyRap'
import UniversalMotorController from '../../Overlays/UniversalMotorController'
import ElektronischeIndustrieprodukte from '../../Overlays/ElektronischeIndustrieprodukte'
import Softstarter from '../../Overlays/Softstarter'
import Gehaeusesysteme from '../../Overlays/Gehaeusesysteme'
import Leistungsschalter from '../../Overlays/Leistungsschalter'
import QSlide from '../../components/QSlide'
import USV from '../../Overlays/USV'
import IndustrieUSVSpannungsaufbereitung from '../../Overlays/IndustrieUSVSpannungsaufbereitung'

import background from '../../../../../assets/branchenkonfiguratorIndustrie/backgrounds/maschinenbau.jpg'
import image1 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_14_easy_to_install_0779.svg'
import image2 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_05_headphones_glasses_0636.svg'
import image3 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_03_drive_for_switchgear_ACS550_0582.svg'
import image4 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_03_calciner_0578.svg'
import image5 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_03_for_switchgear_PSTB_0589.svg'
import image6 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_03_device_0053.svg'
import image7 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_03_panel_buildes_0092.svg'
import image8 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_03_device_0050.svg'
import image9 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_03_switchgear_UPS_0593.png'
import EnergieeffizienteAntriebstechnik from '../../Overlays/EnergieeffizienteAntriebstechnik'

export default function Maschinenbau() {
    const items = [
        {
            id: 'stecksockelsystem',
            title: 'Berührungssicheres Stecksockelsystem',
            img: image1,
            dialog: true,
            dialogContent: <Stecksockelsystem />,
        },
        {
            id: 'maschinensicherheit',
            title: 'Maschinensicherheit',
            img: image2,
            dialog: true,
            dialogContent: <Maschinensicherheit />,
        },
        {
            id: 'dbt',
            title: 'Energieeffiziente Antriebstechik',
            img: image3,
            dialog: true,
            dialogContent: <EnergieeffizienteAntriebstechnik />,
        },
        {
            id: 'tyrap',
            title: 'Kabelmanagement',
            img: image4,
            dialog: true,
            dialogContent: <TyRap />,
        },
        {
            id: 'motormanagementsystem',
            title: 'Motormanagement',
            img: image5,
            dialog: true,
            dialogContent: (
                <QSlide>
                    <UniversalMotorController />
                    <Softstarter />
                </QSlide>
            ),
        },
        {
            id: 'elektrische-industrieprodukte',
            title: 'Elektronische Produkte und Relais',
            img: image6,
            dialog: true,
            dialogContent: <ElektronischeIndustrieprodukte />,
        },
        {
            id: 'gehaeusesysteme',
            title: 'Gehäusesysteme',
            img: image7,
            dialog: true,
            dialogContent: <Gehaeusesysteme />,
        },
        {
            id: 'leistungsschalter',
            title: 'Leistungsschalter',
            img: image8,
            dialog: true,
            dialogContent: <Leistungsschalter />,
        },
        {
            id: 'usvanlagen',
            title: 'USV-Anlagen',
            img: image9,
            dialog: true,
            dialogContent: (
                <QSlide>
                    <USV />
                    <IndustrieUSVSpannungsaufbereitung />
                </QSlide>
            ),
        },
    ]

    return (
        <Slide
            background={{
                src: background,
                enabled: true,
                alignment: 'right',
                width: '66%',
                height: 3.575 / 4,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
            <Grid container direction="column" wrap="nowrap" justifyContent="space-between">
                <Grid item>
                    <QHeader
                        title="Smarte Lösungen"
                        subTitle="Maschinenbau"
                        arrow
                        arrowOverride={() => silentMoveTo('dashboard', null)}
                    />
                </Grid>
                <Grid item>
                    <Box p={2}>
                        <QBox items={items} />
                    </Box>
                </Grid>
            </Grid>
        </Slide>
    )
}
