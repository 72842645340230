import React from 'react'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import QHeader from '../../../../../components/QHeader'
import QBox from '../../components/QBox'
import Slide from '../../../../../components/Slide'
import { silentMoveTo } from '../../../../../static/fullpageHelpers'
import Edcs from '../../Overlays/EDCS'
import Stecksockelsystem from '../../Overlays/Stecksockelsystem'
import Netzanalysegeraete from '../../Overlays/Netzanalysegeraete'
import FehlerstromSchutzschalter from '../../Overlays/FehlerstromSchutzschalter'
import SystemProCompact from '../../Overlays/SystemProCompact'
import EQmatic from '../../Overlays/EQMatic'
import TruONE from '../../Overlays/TruONE'
import Gehaeusesysteme from '../../Overlays/Gehaeusesysteme'
import USV from '../../Overlays/USV'
import IndustrieUSVSpannungsaufbereitung from '../../Overlays/IndustrieUSVSpannungsaufbereitung'
import QSlide from '../../components/QSlide'

import background from '../../../../../assets/branchenkonfiguratorIndustrie/backgrounds/rechenzentren.jpg'
import image1 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_12_cloud_driven_mobile_devices_0768.svg'
import image2 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_14_easy_to_install_0779.svg'
import image3 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_03_for_switchgear_M2M_0584.svg'
import image4 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_14_around_service_0778.svg'
import image5 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_10_manual_monitoring_0444.svg'
import image6 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_06_bulletin_growth_0668.svg'
import image7 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_11_energy_mark_0760.svg'
import image8 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_03_panel_buildes_0092.svg'
import image9 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_03_switchgear_UPS_0593.png'

export default function DataCenter() {
    const items = [
        {
            id: 'edcs',
            title: 'Digitales Energiemanagement',
            img: image1,
            dialog: true,
            dialogContent: <Edcs />,
        },
        {
            id: 'stecksockelsystem',
            title: 'Berührungssicheres Stecksockelsystem',
            img: image2,
            dialog: true,
            dialogContent: <Stecksockelsystem />,
        },
        {
            id: 'netzanalysegeraete',
            title: 'Leistungsüberwachung',
            img: image3,
            dialog: true,
            dialogContent: <Netzanalysegeraete />,
        },
        {
            id: 'fehlerstromschutzschalter',
            title: 'FI mit automatischer Prüfung',
            img: image4,
            dialog: true,
            dialogContent: <FehlerstromSchutzschalter />,
        },
        {
            id: 'eqmatic',
            title: 'Energiemonitoring',
            img: image5,
            dialog: true,
            dialogContent: <EQmatic />,
        },
        {
            id: 'energiemanagement',
            title: 'Energiemanagement',
            img: image6,
            dialog: true,
            dialogContent: <SystemProCompact />,
        },
        {
            id: 'truone',
            title: 'Automatische Umschalter',
            img: image7,
            dialog: true,
            dialogContent: <TruONE />,
        },
        {
            id: 'gehaeusesysteme',
            title: 'Gehäusesysteme',
            img: image8,
            dialog: true,
            dialogContent: <Gehaeusesysteme />,
        },
        {
            id: 'usvanlagen',
            title: 'USV-Anlagen',
            img: image9,
            dialog: true,
            dialogContent: (
                <QSlide>
                    <USV />
                    <IndustrieUSVSpannungsaufbereitung />
                </QSlide>
            ),
        },
    ]

    return (
        <Slide
            background={{
                src: background,
                enabled: true,
                alignment: 'right',
                width: '66%',
                height: 3.575 / 4,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
            <Grid container direction="column" wrap="nowrap" justifyContent="space-between">
                <Grid item>
                    <QHeader
                        title="Smarte Lösungen"
                        subTitle="Data Center"
                        arrow
                        arrowOverride={() => silentMoveTo('dashboard', null)}
                    />
                </Grid>
                <Grid item>
                    <Box p={2}>
                        <QBox items={items} />
                    </Box>
                </Grid>
            </Grid>
        </Slide>
    )
}
