import React from 'react'

import Grid from '@material-ui/core/Grid'

import QList from '../../../../components/QList/index'
import QIconRow from '../components/QIconRow/index'
import QBodyText from '../../../../components/QBodyText'
import QHeader from '../../../../components/QHeader'
import Slide from '../../../../components/Slide'
import ImageComponent from '../../../../components/ImageComponent'

import ABBLogo from '../../../../components/SvgIcons/ABBLogo'
import image1 from '../../../../assets/branchenkonfiguratorIndustrie/backgrounds/dbt.png'
import image2 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_12_cloud_driven_mobile_devices_0768.svg'
import image3 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_14_easy_to_install_0779.svg'
import image4 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_09_paper_stack_0421.svg'

export default function DBT() {
    return (
        <Slide
            logo={<ABBLogo style={{ fontSize: '4rem' }} />}
            background={{
                enabled: true,
                alignment: 'right',
                width: '50%',
                height: 3.575 / 4,
                backgroundSize: 'cover',
                backgroundPosition: 'right',
            }}
            actions={[
                {
                    type: 'icon',
                    text: 'Web',
                    img: 'laptop',
                    target: '_blank',
                    link: 'https://new.abb.com/low-voltage/de/produkte/installationsgeraete/steuer-und-automationsgeraete/digitale-zeitschaltuhren-mit-bluetooth',
                },
                {
                    type: 'icon',
                    text: 'YouTube',
                    img: 'youtube',
                    target: '_blank',
                    link: 'https://www.youtube.com/watch?v=jo_uQJZDGw4',
                },
                {
                    type: 'icon',
                    text: 'e-Configure',
                    img: 'konfigurator',
                    target: '_blank',
                    link: 'https://econfigure.de.abb.com/#/selector/9AAC134995',
                },
                {
                    type: 'icon',
                    text: 'PDF',
                    img: 'pdf',
                    target: '_blank',
                    link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/digitale-zeitschaltuhr-dbt.pdf',
                },
                {
                    type: 'icon',
                    text: 'Training',
                    img: 'seminar',
                    target: '_blank',
                    link: 'https://new.abb.com/service/de/training/abb-university/germany/seminare/termine/großhandelspartnerprogramm/schaltschrank-lösungen-großhandel',
                },
            ]}
        >
            <Grid container wrap="nowrap" direction="column" style={{ height: '95vh' }}>
                <Grid item>
                    <QHeader title="Digitale Zeitschaltuhr DBT" />
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <QBodyText>
                                Mit der bedarfsgerechten Beleuchtungs-und Heizungssteuerung wird die Energie nur dann
                                verbraucht, wenn sie benötigt wird und es lässt sich der Energieverbrauch um
                                durchschnittlich 20 % reduzieren.
                            </QBodyText>
                            <QList
                                items={[
                                    'Zur Montage auf der Hutschiene',
                                    'Mit integrierter Bluetooth-Schnittstelle',
                                    'Einfaches und schnelles Einrichten per Smartphone oder Tablet',
                                    'Für LED und konventionelle Leuchtmittel',
                                    'Individuell erweiterbar DY365',
                                ]}
                            />
                        </Grid>
                        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
                            <ImageComponent previewImgHeight="40vh" alt="Digitale Zeitschaltuhr DBT" src={image1} />
                            <QIconRow
                                colWidth={4}
                                items={[
                                    {
                                        title: 'Einfaches Einrichten',
                                        img: image2,
                                    },
                                    {
                                        title: 'Schnelles Auslesen',
                                        img: image3,
                                    },
                                    {
                                        title: 'Kopierfunktion',
                                        img: image4,
                                    },
                                ]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Slide>
    )
}
