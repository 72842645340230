import React from 'react'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import QHeader from '../../../../../components/QHeader'
import QBox from '../../components/QBox'
import Slide from '../../../../../components/Slide'
import { silentMoveTo } from '../../../../../static/fullpageHelpers'
import TyRap from '../../Overlays/TyRap'
import Dbt from '../../Overlays/DBT'
import SelbstnachspannendeKlemmen from '../../Overlays/SelbstnachspannendeKlemmen'
import Stecksockelsystem from '../../Overlays/Stecksockelsystem'
import Leistungsschalter from '../../Overlays/Leistungsschalter'

import background from '../../../../../assets/branchenkonfiguratorIndustrie/backgrounds/wasser-und-abwasserversorgung.jpg'
import image1 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_03_calciner_0578.svg'
import image2 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_11_energy_care_0459.svg'
import image3 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_05_no_drilling_0643.svg'
import image4 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_14_easy_to_install_0779.svg'
import image5 from '../../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_03_device_0050.svg'
import Deltec from '../../Overlays/Deltec'
import QSlide from '../../components/QSlide'

export default function SchiffbauMarine() {
    const items = [
        {
            id: 'kabelmanagement',
            title: 'Kabelmanagement',
            img: image1,
            dialog: true,
            dialogContent: (
                <QSlide>
                    <TyRap />
                    <Deltec />
                </QSlide>
            ),
        },
        {
            id: 'dbt',
            title: 'Energieeffiziente Beleuchtungssteuerung',
            img: image2,
            dialog: true,
            dialogContent: <Dbt />,
        },
        { placeholder: true },
        { placeholder: true },
        {
            id: 'selbstnachspannendeklemmen',
            title: 'Schraubenlose Schaltgeräte',
            img: image3,
            dialog: true,
            dialogContent: <SelbstnachspannendeKlemmen />,
        },
        {
            id: 'stecksockelsystem',
            title: 'Berührungssicheres Stecksockelsystem',
            img: image4,
            dialog: true,
            dialogContent: <Stecksockelsystem />,
        },
        {
            id: 'leistungsschalter',
            title: 'Leistungsschalter',
            img: image5,
            dialog: true,
            dialogContent: <Leistungsschalter />,
        },
    ]

    return (
        <Slide
            background={{
                src: background,
                enabled: true,
                alignment: 'right',
                width: '66%',
                height: 3.575 / 4,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
            <Grid container direction="column" wrap="nowrap" justifyContent="space-between">
                <Grid item>
                    <QHeader
                        title="Smarte Lösungen"
                        subTitle="Schiffbau und Marine"
                        arrow
                        arrowOverride={() => silentMoveTo('dashboard', null)}
                    />
                </Grid>
                <Grid item>
                    <Box p={2}>
                        <QBox items={items} />
                    </Box>
                </Grid>
            </Grid>
        </Slide>
    )
}
