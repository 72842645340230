import React from 'react'

import Grid from '@material-ui/core/Grid'

import QList from '../../../../components/QList/index'
import QIconRow from '../components/QIconRow/index'
import QBodyText from '../../../../components/QBodyText'
import QHeader from '../../../../components/QHeader'
import Slide from '../../../../components/Slide'

import ABBLogo from '../../../../components/SvgIcons/ABBLogo'
import image1 from '../../../../assets/branchenkonfiguratorIndustrie/backgrounds/ABB-Smart-Sensor-Small.jpg'
import image2 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_12_cloud_driven_mobile_devices_0768.svg'
import image3 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_14_easy_to_install_0779.svg'
import image4 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_04_maintenance_forecast_0613.svg'

export default function SmartSensorMotoren() {
    return (
        <Slide
            logo={<ABBLogo style={{ fontSize: '4rem' }} />}
            background={{
                src: image1,
                enabled: true,
                alignment: 'right',
                width: '50%',
                height: 3.575 / 4,
                backgroundSize: 'cover',
                backgroundPosition: 'right',
            }}
            actions={[
                {
                    type: 'icon',
                    text: 'Web',
                    img: 'laptop',
                    target: '_blank',
                    link: 'https://new.abb.com/motors-generators/de/motoren-generatorenservice/erweitertes-service-angebot/smart-sensor',
                },
                {
                    type: 'icon',
                    text: 'YouTube',
                    img: 'youtube',
                    target: '_blank',
                    link: 'https://search.abb.com/library/Download.aspx?DocumentID=9AKK107492A8825&LanguageCode=de&DocumentPartId=&Action=Launch',
                },
                {
                    type: 'icon',
                    text: 'PDF',
                    img: 'pdf',
                    target: '_blank',
                    link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/abb-ability-smart-sensor-fuer-motoren.pdf',
                },
                {
                    type: 'icon',
                    text: 'Training',
                    img: 'seminar',
                    target: '_blank',
                    link: 'https://new.abb.com/service/de/training/abb-university/germany/seminare/termine/großhandelspartnerprogramm/antriebstechnik-großhandel',
                },
            ]}
        >
            <Grid container wrap="nowrap" direction="column" style={{ height: '95vh' }}>
                <Grid item>
                    <QHeader
                        title={
                            <>
                                ABB Ability<sup>™</sup> Smart Sensor für Motoren
                            </>
                        }
                    />
                </Grid>
                <Grid item>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={6}>
                            <QBodyText>
                                Die neuartige Sensortechnologie bietet Anlagenbetreibern und Dienstleistern nicht nur
                                enorme Einsparmöglichkeiten bei der Wartung und Instandhaltung, sondern macht künftig
                                auch Millionen von Motoren auf einfache Weise zugänglich für das Internet of Things
                                (IoT). Der ABB Ability<sup>™</sup> Smart Sensor liefert Daten zu Betriebs- und
                                Zustandsparametern, diese werden analysiert und für die Wartungsplanung zur Verfügung
                                gestellt.
                            </QBodyText>
                            <QList
                                items={[
                                    'Stillstandzeiten bis zu 70% reduzieren',
                                    'Lebensdauer um bis zu 30% verlängern',
                                    'Eigenverbrauch um bis zu 10% reduzieren',
                                    'Zuverlässig verbunden',
                                ]}
                                dense
                                variant="dot"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <QIconRow
                                colWidth={4}
                                items={[
                                    {
                                        title: 'Einfaches Einrichten',
                                        img: image2,
                                    },
                                    {
                                        title: 'Schnelles Auslesen',
                                        img: image3,
                                    },
                                    {
                                        title: 'Geringere Wartungskosten',
                                        img: image4,
                                    },
                                ]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Slide>
    )
}
