import React from 'react'

import Grid from '@material-ui/core/Grid'

import QList from '../../../../components/QList/index'
import QIconRow from '../components/QIconRow/index'
import QBodyText from '../../../../components/QBodyText'
import QHeader from '../../../../components/QHeader'
import Slide from '../../../../components/Slide'
import ImageComponent from '../../../../components/ImageComponent'

import ABBLogo from '../../../../components/SvgIcons/ABBLogo'
import image1 from '../../../../assets/branchenkonfiguratorIndustrie/backgrounds/TyRap.png'
import image2 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_06_URL_link_0681.svg'
import image3 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_14_easy_to_install_0779.svg'
import image4 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_15_oil_platform_0797.svg'

export default function TyRap() {
    return (
        <Slide
            logo={<ABBLogo style={{ fontSize: '4rem' }} />}
            background={{
                enabled: true,
                alignment: 'right',
                width: '50%',
                height: 3.575 / 4,
            }}
            actions={[
                {
                    type: 'icon',
                    text: 'Web',
                    img: 'laptop',
                    target: '_blank',
                    link: 'https://new.abb.com/low-voltage/de/produkte/innovative-kabelmanagement-systeme/kabelbinder-und-zubehoer/ty-rap',
                },
                {
                    type: 'icon',
                    text: 'YouTube',
                    img: 'youtube',
                    target: '_blank',
                    link: 'https://www.youtube.com/watch?v=HhkqXbKqti4',
                },
                {
                    type: 'icon',
                    text: 'e-Configure',
                    img: 'konfigurator',
                    target: '_blank',
                    link: 'https://econfigure.de.abb.com/#/selector/9AAC183568',
                },
                {
                    type: 'icon',
                    text: 'PDF',
                    img: 'pdf',
                    target: '_blank',
                    link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/ty-rap-befestigungssystem.pdf',
                },
                {
                    type: 'icon',
                    text: 'Training',
                    img: 'seminar',
                    target: '_blank',
                    link: 'https://new.abb.com/service/de/training/abb-university/germany/seminare/termine/großhandelspartnerprogramm/maschinensicherheit-großhandel',
                },
            ]}
        >
            <Grid container wrap="nowrap" direction="column" style={{ height: '95vh' }}>
                <Grid item>
                    <QHeader
                        title={
                            <>
                                Ty-Rap<sup>®</sup> Premiumkabelbinder
                            </>
                        }
                    />
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <QBodyText>
                                Das vollständige Ty-Rap<sup>®</sup> Sortiment umfasst Kabelbinder für spezielle
                                Anwendungsbereiche – egal, ob Widerstandsfähigkeit gegenüber extremen Temperaturen,
                                Flammen, ständiger Sonneneinstrahlung, UV-Strahlen und aggressiven Chemikalien oder ob
                                hohe Zugfestigkeit erforderlich ist.
                            </QBodyText>
                            <QList
                                items={[
                                    'Antimagnetischer Edelstahlverschluss für maximale Stärke und lange Lebensdauer',
                                    'Weniger Stresspunkte, höhere Lebensdauer',
                                    'Einfache Installation dank flachem Kopf und ovaler Form',
                                    'Leistung in extremen und rauen Umgebungen: Verfügbar für den Einsatz in ätzenden, chemischen, heißen und kalten und anderen schwierigen Umgebungen',
                                ]}
                                dense
                                variant="dot"
                            />
                        </Grid>
                        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
                            <ImageComponent previewImgWidth="37vw" alt="TyRap" src={image1} />
                            <QIconRow
                                colWidth={4}
                                items={[
                                    {
                                        title: 'Fest verbunden',
                                        img: image2,
                                    },
                                    {
                                        title: 'Einfache Installation',
                                        img: image3,
                                    },
                                    {
                                        title: 'Für robuste Umgebungen',
                                        img: image4,
                                    },
                                ]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Slide>
    )
}
