import React, { Component } from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'

import root from 'window-or-global'

//Toastify
import { ToastContainer, toast } from 'react-toastify'

//Redux
import configureStore from './redux/store/configureStore'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

//Material UI
import { ThemeProvider } from '@material-ui/core'
import { theme } from './theme'
import ModalProvider from 'mui-modal-provider'
import Box from '@material-ui/core/Box'

//Material UI Picker
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import deLocale from 'date-fns/locale/de'

//StylesProvider for overriding MaterialUI
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles'

//tippy.js
import 'tippy.js/dist/tippy.css'

//Sentry https://docs.sentry.io/platforms/javascript/react/
// import * as Sentry from '@sentry/react';

//Fullpage ScrollOverflow
import 'fullpage.js/vendors/scrolloverflow'

//Components
import Menu from './components/Menu'

// Code-splitting is automated for routes
import BranchenkonfiguratorIndustrie from './routes/Chapters/branchenkonfiguratorIndustrie'
import Result from './routes/Chapters/result'

import APIService from './api/index'
import Share from './routes/Chapters/share'

const generateClassName = createGenerateClassName({
    productionPrefix: 'qmarketing',
})

export default class App extends Component {
    /** Gets fired when the route changes.
     *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
     *	@param {string} event.url	The newly routed URL
     */
    handleRoute = (e) => {
        this.currentUrl = e.url
    }

    handleConnection(event) {
        if (event.type === 'offline') {
            toast.error('Keine Internetverbindung.')
        }
        if (event.type === 'online') {
            toast.info('Sie sind wieder online.')
        }
    }

    async componentDidMount() {
        if (process.env.NODE_ENV !== 'development') {
            await APIService.get('api/pb/hit')
        }

        root.addEventListener('online', this.handleConnection)
        root.addEventListener('offline', this.handleConnection)
    }

    render() {
        const { store, persistor } = configureStore({})
        return (
            <div id="app">
                <ToastContainer
                    position="bottom-center"
                    toastClassName="toast-container"
                    autoClose={3000}
                    hideProgressBar
                />
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <StylesProvider injectFirst={false} generateClassName={generateClassName}>
                            <ThemeProvider theme={theme}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                                    <ModalProvider>
                                        <Box>
                                            <Router onChange={this.handleRoute} hashType="noslash">
                                                <Menu />
                                                <Switch>
                                                    <Route exact path="/share/:id">
                                                        <Share />
                                                    </Route>
                                                    <Route exact path="/result">
                                                        <Result />
                                                    </Route>
                                                    <Route path="/">
                                                        <BranchenkonfiguratorIndustrie />
                                                    </Route>
                                                </Switch>
                                            </Router>
                                        </Box>
                                    </ModalProvider>
                                </MuiPickersUtilsProvider>
                            </ThemeProvider>
                        </StylesProvider>
                    </PersistGate>
                </Provider>
            </div>
        )
    }
}
