import React from 'react'

import Grid from '@material-ui/core/Grid'

import QList from '../../../../components/QList/index'
import QIconRow from '../components/QIconRow/index'
import QBodyText from '../../../../components/QBodyText'
import QHeader from '../../../../components/QHeader'
import Slide from '../../../../components/Slide'
import ImageComponent from '../../../../components/ImageComponent'

import ABBLogo from '../../../../components/SvgIcons/ABBLogo'
import image1 from '../../../../assets/branchenkonfiguratorIndustrie/backgrounds/netz-und-anlagenschutz.png'
import image2 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_14_easy_to_install_0779.svg'
import image3 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_03_for_switchgear_M2M_0584.svg'
import image4 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_09_paper_stack_0421.svg'

export default function NetzUndAnlagenschutz() {
    return (
        <Slide
            logo={<ABBLogo style={{ fontSize: '4rem' }} />}
            background={{
                enabled: true,
                alignment: 'right',
                width: '50%',
                height: 3.575 / 4,
                backgroundSize: 'cover',
                backgroundPosition: 'right',
            }}
            actions={[
                {
                    type: 'icon',
                    text: 'Web',
                    img: 'laptop',
                    target: '_blank',
                    link: 'https://new.abb.com/products/de/1SVR560731R3701/cm-ufd-m31m-netzeinspeiseuberw-relais-3we-l-l0-540vac-l-n-0-312vac-modbusrtu-n',
                },
                {
                    type: 'icon',
                    text: 'e-Configure',
                    img: 'konfigurator',
                    target: '_blank',
                    link: 'https://econfigure.de.abb.com/#/selector/9AAC159149',
                },
                {
                    type: 'icon',
                    text: 'PDF',
                    img: 'pdf',
                    target: '_blank',
                    link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/netz-und-anlagenschutz.pdf',
                },
                {
                    type: 'icon',
                    text: 'Training',
                    img: 'seminar',
                    target: '_blank',
                    link: 'https://new.abb.com/service/de/training/abb-university/germany/seminare/termine/großhandelspartnerprogramm/schaltschrank-lösungen-großhandel',
                },
            ]}
        >
            <Grid container wrap="nowrap" direction="column" style={{ height: '95vh' }}>
                <Grid item>
                    <QHeader
                        title={
                            <>
                                Netz- und Anlagenschutz
                                <br />
                                CM-UFD.M31
                            </>
                        }
                    />
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item xs={6} spacing={2}>
                            <QBodyText>
                                Das Netzeinspeiseüberwachungssrelais CM-UFD.M31 überwacht Spannung und Frequenz in Ein-
                                und Dreiphasennetzen. Es entspricht in Verbindung mit Wechselrichtern mit integrierter
                                Inselnetzerkennung den Bedingungen für den NA-Schutz nach VDE-AR-N 4105:2018-11 und
                                VDE-AR-N 4110:2018-11.
                            </QBodyText>
                            <QList
                                items={[
                                    'Ein- und dreiphasige Spannungs- und Frequenzüberwachung mit Neutralleitererkennung',
                                    'Für den Verteilereinbau',
                                    'NA-Schutz nach der Anwendungsrichtlinie VDE-AR-N 4105:2018-11 und VDEAR-N 4110:2018-11',
                                    'Voreingestellte Grenzwerte nach VDE-AR-N 4105:2018-11 und VDE-AR-N 4110:2018-11',
                                ]}
                                dense
                                variant="dot"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item xs={12}>
                                    <ImageComponent previewImgHeight="36vh" alt="Netz und Anlagenschutz" src={image1} />
                                </Grid>
                                <Grid item xs={12}>
                                    <QIconRow
                                        colWidth={4}
                                        items={[
                                            {
                                                title: 'Einfache Inbertiebnahme',
                                                img: image2,
                                            },
                                            {
                                                title: 'Großes Display',
                                                img: image3,
                                            },
                                            {
                                                title: 'TÜV zertifiziert',
                                                img: image4,
                                            },
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Slide>
    )
}
