import React, { useEffect, cloneElement, Children } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useUpdateAtom } from 'jotai/utils'

import ReactFullpage from '@fullpage/react-fullpage'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import Section from '../Section'
import QHeader from '../QHeader'
import Slide from '../Slide'
import QScrollBody from '../QScrollBody'

import { addTo, getBranchenkonfiguratorIndustrieData } from '../../redux/modules/chapterBranchenkonfiguratorIndustrie'
import { branchenActionData } from '../../static/branchenActionData'
import { currentSlideAtom } from '../../atoms'

const useStyles = makeStyles(() => ({
    actions: {
        position: 'absolute',
        bottom: '25px',
        '& img': {
            height: '25px',
            widht: 'auto',
        },
    },
    logo: {
        position: 'absolute',
        bottom: '7px',
        right: '40px',
        zIndex: 200,
    },
}))

export default function ChapterWrapper(props) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const section = props.chapterData.link.slice(1)
    const children = Children.toArray(props.children)

    const location = useLocation()
    const setCurrentSlide = useUpdateAtom(currentSlideAtom)
    const { detailOverlays } = useSelector(getBranchenkonfiguratorIndustrieData)

    useEffect(() => {
        const currentSlide = branchenActionData.find((el) => el.link === location.pathname.replace('/', '#'))
        setCurrentSlide(currentSlide)
        if (currentSlide && !detailOverlays.value.find((el) => el === currentSlide.id)) {
            dispatch(addTo({ id: 'detailOverlays', value: currentSlide.id }))
        }
    }, [location, dispatch, detailOverlays, setCurrentSlide])

    useEffect(() => {
        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.log('Mount chapter: ', section)
        }
        // eslint-disable-next-line no-undef
        fullpage_api.reBuild()

        return () => {
            if (process.env.NODE_ENV === 'development') {
                // eslint-disable-next-line no-console
                console.log('Unmount chapter: ', section)
            }
            // eslint-disable-next-line no-undef
            fullpage_api.destroy('all')
        }
    }, [props.chapterData.chapterID, section])

    let anchors = []

    const slides = children.map((slide) => {
        const { anchor } = slide.props
        anchors.push(anchor)

        const actions = slide.props.actions !== undefined ? slide.props.actions : []

        const actionItems = actions.map((action, i) => (
            <Grid item key={`action-${i}`}>
                <a href={action.link}>
                    <img src={action.imgSrc} alt="img" />
                </a>
            </Grid>
        ))

        return (
            <Section key={`${section}-${anchor}`} selector={section} {...slide.props}>
                <Box style={{ height: 'inherit' }} id="slideWrapper">
                    {slide}
                    {actionItems.length > 0 && (
                        <Grid className={classes.actions} container spacing={2}>
                            {actionItems}
                        </Grid>
                    )}
                </Box>
            </Section>
        )
    })

    const actionPageWithProps =
        props.chapterData.actionPage !== null
            ? cloneElement(props.chapterData.actionPage, {
                  context: 'fullpage',
              })
            : props.chapterData.actionPage

    const actionSection = (
        <Section selector={section}>
            <Slide
                background={{
                    enabled: true,
                    alignment: 'left',
                    width: '100%',
                    height: '100%',
                }}
            >
                <Grid container direction="column" spacing={2} wrap="nowrap">
                    <Grid item>
                        <QHeader title={props.chapterData.label} subTitle="Aktionsliste" />
                    </Grid>
                    <Grid item>
                        <QScrollBody maxHeight="70vh">{actionPageWithProps}</QScrollBody>
                    </Grid>
                </Grid>
            </Slide>
        </Section>
    )

    if (props.chapterData.actionPage !== null) {
        anchors.push('aktionsliste')
    }

    anchors.push('themenwahl')

    return (
        <>
            <ReactFullpage
                licenseKey="82375DBB-66484CC6-A59E8B65-42EC72CD"
                anchors={anchors}
                showActiveTooltip={false}
                slidesNavigation
                slidesNavPosition={'bottom'}
                continuousVertical={false}
                touchSensitivity={5}
                scrollOverflowOptions={{
                    click: true,
                }}
                autoScrolling
                verticalCentered={false}
                scrollingSpeed={500}
                css3
                easingcss3={'ease'}
                normalScrollElements=".MuiDialog-root, .MuiDialog-scrollPaper, .MuiAutocomplete-popper, #consultationDialog, .autoScroll, #QSrollBody"
                sectionSelector={`.${section}`}
                render={() => (
                    <ReactFullpage.Wrapper>
                        {slides}
                        {props.chapterData.actionPage !== null && actionSection}
                    </ReactFullpage.Wrapper>
                )}
            />
            {props.logo !== null && <Box className={classes.logo}>{props.logo}</Box>}
        </>
    )
}

ChapterWrapper.defaultProps = {
    anchors: [],
    section: '',
    actions: null,
    normalScrollElements: [],
    logo: null,
}
