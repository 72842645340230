import React from 'react'

import ChapterWrapper from '../../../components/ChapterWrapper'
import { chapters } from '../../../static/data'
import InfrastrukturLogistik from './sections/ InfrastrukturLogistik'
import BahnSchienenfahrzeuge from './sections/BahnSchienenfahrzeuge'
import ChemieOelGas from './sections/ChemieOelGas'
import Dashboard from './sections/Dashboard'
// import DebugSection from './sections/DebugSection'
import EnergieerzeugungBereitstellung from './sections/EnergieerzeugungBereitstellung'
import FacilityManagement from './sections/FacilityManagement'
import Gesundheitswesen from './sections/Gesundheitswesen'
import HochTiefbau from './sections/HochTiefbau'
import HolzPapier from './sections/HolzPapier'
import InstandhaltungService from './sections/InstandhaltungService'
import Maschinenbau from './sections/Maschinenbau'
import NahrungsGenussmittel from './sections/NahrungsGenussmittel'
import Pharmazie from './sections/Pharmazie'
import Schaltanlagenbau from './sections/Schaltanlagenbau'
import SchiffbauMarine from './sections/SchiffbauMarine'
import StahlMetall from './sections/StahlMetall'
import WasserAbwasser from './sections/WasserAbwasser'
import ZementSteineErde from './sections/ZementSteineErde'

import ABBLogo from '../../../components/SvgIcons/ABBLogo'
import DataCenter from './sections/DataCenter'
import Windenergie from './sections/Windenergie'

export default function BranchenkonfiguratorIndustrie() {
    const chapterData = chapters.branchenkonfiguratorIndustrie
    return (
        <ChapterWrapper chapterData={chapterData} logo={<ABBLogo style={{ fontSize: '4rem' }} />}>
            <Dashboard anchor="dashboard" />
            <ZementSteineErde anchor="zement-steine-erde" />
            <EnergieerzeugungBereitstellung anchor="energieerzeugung-bereitstellung" />
            <HolzPapier anchor="holz-papier" />
            <StahlMetall anchor="stahl-metall" />
            <NahrungsGenussmittel anchor="nahrungs-genussmittel" />
            <Pharmazie anchor="pharmazie" />
            <ChemieOelGas anchor="chemie-oel-gas" />
            <Windenergie anchor="windenergie" />
            <FacilityManagement anchor="facility-management" />
            <Maschinenbau anchor="maschinenbau" />
            <Schaltanlagenbau anchor="schaltanlagenbau" />
            <HochTiefbau anchor="hoch-tiefbau" />
            <InfrastrukturLogistik anchor="infrastruktur-logistik" />
            <DataCenter anchor="data-center" />
            <InstandhaltungService anchor="instandhaltung-service" />
            <BahnSchienenfahrzeuge anchor="bahn-schienenfahrzeuge" />
            <SchiffbauMarine anchor="schiffbau-marine" />
            <Gesundheitswesen anchor="gesundheitswesen" />
            <WasserAbwasser anchor="wasser-abwasser" />
        </ChapterWrapper>
    )
}
