import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Collapse from '@material-ui/core/Collapse'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import FileCopyIcon from '@material-ui/icons/FileCopy'

import { resetApp } from '../../../redux/modules/reducers'
import {
    getBranchenkonfiguratorIndustrieData,
    addTo,
    removeFrom,
} from '../../../redux/modules/chapterBranchenkonfiguratorIndustrie'

import QActionPaper from '../../QActionPaper'
import QCheckboxAccordion from '../../QCheckboxAccordion'
import QScrollBody from '../../QScrollBody'

import { branchenActionData } from '../../../static/branchenActionData'
import APIService from '../../../api/index'

export default function BranchenkonfiguratorIndustrie({ context }) {
    const dispatch = useDispatch()
    const { detailOverlays } = useSelector(getBranchenkonfiguratorIndustrieData)

    const [link, setLink] = useState('')
    const [values, setValues] = useState([])
    const [expanded, setExpanded] = useState(null)

    const half = Math.ceil(branchenActionData.length / 2)
    const firstHalf = branchenActionData.slice(0, half)
    const secondHalf = branchenActionData.slice(-(half - 1))

    useEffect(() => {
        if (!link) {
            setValues(detailOverlays.value)
        }
    }, [detailOverlays, link])

    const handleChangeAction = (id, value) => {
        const elem = branchenActionData.find((el) => el.id === id)
        let itemsToChange = [elem.id, ...elem.overlays.map((el) => `${elem.id}_${el.id}`)]

        if (value && expanded === elem.id) {
            setTimeout(() => setExpanded(null), 250)
        }

        handleChange(itemsToChange, value)
    }

    const handleChangeDetails = (id, value) => {
        let itemsToChange = []
        const splittedId = id.split('_') // chk_{parentId}_chk_{overlayId}
        if (splittedId) {
            const parentId = `${splittedId[0]}_${splittedId[1]}` // chk_{parentId}
            const found = values.find((el) => el === parentId)
            if (!found && value) {
                itemsToChange = [parentId, id]
                handleChange(itemsToChange, value)
            } else if (found) {
                const foundOther = values.find((el) => el !== parentId && el !== id && el.includes(parentId))
                if (foundOther) {
                    handleChange(id, value)
                } else {
                    itemsToChange = [parentId, id]
                    handleChange(itemsToChange, value)
                }
            }
        }
    }

    const handleChange = (id, value) => {
        if (!value) {
            dispatch(removeFrom({ id: 'detailOverlays', value: id }))
        } else {
            dispatch(addTo({ id: 'detailOverlays', value: id }))
        }
    }

    const finish = () => {
        APIService.post('/api/result', { values, date: new Date() }, null, (status, data) => {
            if (status === 200) {
                setLink(`${process.env.REACT_APP_SHARE_URL}/#/share/${data}`)
                dispatch(resetApp())
            }
        })
    }

    const openLink = () => {
        window.open(link, '_blank')
    }

    const copyLink = () => {
        const linkField = document.getElementById('share-link')
        linkField.select() //select the text area
        document.execCommand('copy') //copy to clipboard
        linkField.blur() // unselect the text area
        toast('Der Link wurde kopiert')
    }

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <QScrollBody overrideHeight="100%" maxHeight="65vh">
                    <QActionPaper title="Lösungen" context={context}>
                        <Grid container>
                            <Grid item xs={6}>
                                <QCheckboxAccordion
                                    items={firstHalf}
                                    values={values}
                                    expanded={expanded}
                                    setExpanded={setExpanded}
                                    handleChangeAction={handleChangeAction}
                                    handleChangeDetails={handleChangeDetails}
                                    readOnly={!!link}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <QCheckboxAccordion
                                    items={secondHalf}
                                    values={values}
                                    expanded={expanded}
                                    setExpanded={setExpanded}
                                    handleChangeAction={handleChangeAction}
                                    handleChangeDetails={handleChangeDetails}
                                    readOnly={!!link}
                                />
                            </Grid>
                        </Grid>
                    </QActionPaper>
                </QScrollBody>
            </Grid>
            <Grid item>
                <Grid container>
                    <Grid item xs={12} align="center">
                        <Collapse in={!link}>
                            <Button variant="contained" color="primary" onClick={finish}>
                                Abschließen
                            </Button>
                        </Collapse>
                    </Grid>
                    <Grid item xs={12}>
                        <Collapse in={!!link}>
                            <Grid container alignItems="center">
                                <Grid item xs={7} md={6}>
                                    <QActionPaper>
                                        <TextField id="share-link" value={link} variant="outlined" fullWidth />
                                    </QActionPaper>
                                </Grid>
                                <Grid item xs={5} md={6}>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                startIcon={<OpenInNewIcon />}
                                                onClick={openLink}
                                            >
                                                Öffnen
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                startIcon={<FileCopyIcon />}
                                                onClick={copyLink}
                                            >
                                                Kopieren
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Collapse>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
