import React from 'react'

import Grid from '@material-ui/core/Grid'

import QList from '../../../../components/QList/index'
import QIconRow from '../components/QIconRow/index'
import QBodyText from '../../../../components/QBodyText'
import QHeader from '../../../../components/QHeader'
import Slide from '../../../../components/Slide'
import ImageComponent from '../../../../components/ImageComponent'

import ABBLogo from '../../../../components/SvgIcons/ABBLogo'
import image1 from '../../../../assets/branchenkonfiguratorIndustrie/backgrounds/Deltec.png'
import image2 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_06_URL_link_0681.svg'
import image3 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_14_easy_to_install_0779.svg'
import image4 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_15_oil_platform_0797.svg'

export default function Deltec() {
    return (
        <Slide
            logo={<ABBLogo style={{ fontSize: '4rem' }} />}
            background={{
                enabled: true,
                alignment: 'right',
                width: '50%',
                height: 3.575 / 4,
            }}
            actions={[
                {
                    type: 'icon',
                    text: 'Web',
                    img: 'laptop',
                    target: '_blank',
                    link: 'https://new.abb.com/low-voltage/de/produkte/innovative-kabelmanagement-systeme/kabelbinder-und-zubehoer/deltec',
                },
                {
                    type: 'icon',
                    text: 'e-Configure',
                    img: 'konfigurator',
                    target: '_blank',
                    link: 'https://econfigure.de.abb.com/#/search/Deltec',
                },
                {
                    type: 'icon',
                    text: 'PDF',
                    img: 'pdf',
                    target: '_blank',
                    link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/deltec-befestigungssysteme.pdf',
                },
                {
                    type: 'icon',
                    text: 'Training',
                    img: 'seminar',
                    target: '_blank',
                    link: 'https://new.abb.com/service/de/training/abb-university/germany/seminare/termine/großhandelspartnerprogramm/maschinensicherheit-großhandel',
                },
            ]}
        >
            <Grid container wrap="nowrap" direction="column" style={{ height: '95vh' }}>
                <Grid item>
                    <QHeader title="Deltec® Befestigungssysteme" />
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <QBodyText>
                                Das Deltec® Kabelbefestigungssystem mit Metallverschluss ist sicher und einfach zu
                                installieren. Es ist witterungsbeständig mit einer sehr langen Lebensdauer.
                            </QBodyText>
                            <QList
                                items={[
                                    'Keine scharfen Kanten',
                                    'Für Nagetiere und andere Schädlinge unbekömmlich',
                                    'Das Band aus wetterbeständigem Acetat bietet hervorragende Beständigkeit gegen Sonnenlicht, Feuchtigkeit und salzhaltige Umgebungen',
                                    'Korrosionsbeständige Kupferlegierung nach Marineklassifizierung',
                                    'Mindestzugfestigkeit: 1110 N (250 lbs.)',
                                    'Niedrige Installationskosten durch Bandware, kein Bandverschnitt!',
                                ]}
                                dense
                                variant="dot"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Grid
                                container
                                spacing={2}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Grid item>
                                    <ImageComponent
                                        previewImgHeight="52vh"
                                        previewImgWidth="39vw"
                                        alt="Energieeffiziente Antriebstechnik"
                                        src={image1}
                                    />
                                </Grid>
                                <Grid item>
                                    <QIconRow
                                        colWidth={4}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                        items={[
                                            {
                                                title: 'Fest verbunden',
                                                img: image2,
                                            },
                                            {
                                                title: 'Einfache Installation',
                                                img: image3,
                                            },
                                            {
                                                title: 'Für robuste Umgebungen',
                                                img: image4,
                                            },
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Slide>
    )
}
