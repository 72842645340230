import React from 'react'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import QIconRow from '../components/QIconRow/index'
import QBodyText from '../../../../components/QBodyText'
import QHeader from '../../../../components/QHeader'
import Slide from '../../../../components/Slide'
import QList from '../../../../components/QList/index'

import ABBLogo from '../../../../components/SvgIcons/ABBLogo'
import image1 from '../../../../assets/branchenkonfiguratorIndustrie/backgrounds/SelbstnachspannendeKlemmen.jpg'
import image2 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_13_speed_up_your_projects_0776.svg'
import image3 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_14_easy_to_install_0779.svg'
import image4 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB__Continous_operation.svg'

export default function SelbstnachspannendeKlemmen() {
    return (
        <Slide
            logo={<ABBLogo style={{ fontSize: '4rem' }} />}
            background={{
                src: image1,
                enabled: true,
                alignment: 'right',
                width: '50%',
                height: 3.575 / 4,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
            actions={[
                // {
                //   type: "custom",
                //   custom: <PDFSelector id="selbstnachspannendeklemmen" />,
                // },
                {
                    type: 'icon',
                    text: 'Web',
                    img: 'laptop',
                    target: '_blank',
                    link: 'https://new.abb.com/low-voltage/de/produkte/motorschutz-und-steuerung/push-in-feder-anschlusstechnik',
                },
                {
                    type: 'icon',
                    text: 'e-Configure',
                    img: 'konfigurator',
                    target: '_blank',
                    link: 'https://econfigure.de.abb.com/#/selector/9AAC184308',
                },
                {
                    type: 'icon',
                    text: 'YouTube',
                    img: 'youtube',
                    target: '_blank',
                    link: 'https://search.abb.com/library/Download.aspx?DocumentID=9AKK107492A2212&LanguageCode=de&DocumentPartId=&Action=Launch',
                },
                {
                    type: 'icon',
                    text: 'PDF',
                    img: 'pdf',
                    target: '_blank',
                    link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/push-in-feder-anschlusstechnik.pdf',
                },
                {
                    type: 'icon',
                    text: 'Training',
                    img: 'seminar',
                    target: '_blank',
                    link: 'https://new.abb.com/service/de/training/abb-university/germany/seminare/termine/großhandelspartnerprogramm/schaltschrank-lösungen-großhandel',
                },
            ]}
        >
            <Grid container wrap="nowrap" direction="column" style={{ height: '95vh' }}>
                <Grid item>
                    <QHeader title="Push-in-Feder Anschlusstechnik" />
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Box p={2} className="autoScroll">
                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <QBodyText>
                                            Bei der neuen Lösung für den Motorstart mit Push-in-Feder reicht einfaches
                                            Stecken für eine äußerst schnelle Verdrahtung aus. Es ist kein Werkzeug
                                            erforderlich. Daher sparen Sie mit der Push-in-Federtechnik im Vergleich zu
                                            herkömmlichen Federlösungen bis zu 50% Verdrahtungszeit.
                                        </QBodyText>
                                    </Grid>
                                    <Grid item>
                                        <QList
                                            items={[
                                                'Robuste Bauweise',
                                                'Vibrationsfest',
                                                'Ein erneutes Anziehen entfällt',
                                                'Schneller installiert',
                                                'Einfacher verdrahtet',
                                                'Zuverlässig verbunden',
                                            ]}
                                            dense
                                            variant="dot"
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid item>
                                <QIconRow
                                    colWidth={4}
                                    items={[
                                        {
                                            title: 'Projekte beschleunigen',
                                            img: image2,
                                        },
                                        {
                                            title: 'Einfache Installation',
                                            img: image3,
                                        },
                                        {
                                            title: 'Dauerbetrieb',
                                            img: image4,
                                        },
                                    ]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Slide>
    )
}
