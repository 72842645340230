import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import { useModal } from 'mui-modal-provider'

import Slide from '../Slide'
import QHeader from '../QHeader'
import QDialog from '../QDialog'
import QList from '../QList'
import QRCode from '../QRCode'

import MenuIcon from '../SvgIcons/Menu'
import DoneIcon from '../SvgIcons/DoneIcon'
import ABBLogo from '../SvgIcons/ABBLogo'
import backgroundKontakt from '../../assets/branchenkonfiguratorIndustrie/kontakt/abb-gebaeude.jpg'
import backgroundWeitereInfo from '../../assets/branchenkonfiguratorIndustrie/kontakt/weitere-informationen.jpg'
import QRCodeNewsletter from '../../assets/branchenkonfiguratorIndustrie/kontakt/qr-codes/newsletter.jpg'
import QRCodeSeminare from '../../assets/branchenkonfiguratorIndustrie/kontakt/qr-codes/seminare.jpg'
import QRCodeStromkompass from '../../assets/branchenkonfiguratorIndustrie/kontakt/qr-codes/stromkompass.jpg'
import QRCodeMissionToZero from '../../assets/branchenkonfiguratorIndustrie/kontakt/qr-codes/mission-to-zero.jpg'

const useStyles = makeStyles((theme) => ({
    menuIcon: {
        right: '20px',
        top: '20px',
        zIndex: '20',
        position: 'fixed',
        padding: '0',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    resultPageBtn: {
        right: '80px',
        top: '20px',
        zIndex: '20',
        position: 'fixed',
        padding: '0',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    clickable: {
        cursor: 'pointer',
    },
    link: {
        cursor: 'pointer',
        '&:after': {
            content: '"\\25B8"',
            marginLeft: theme.spacing(0.5),
        },
    },
}))

export default function Menu() {
    const classes = useStyles()
    const location = useLocation()
    const history = useHistory()
    const { showModal } = useModal()

    const resultPage = () => {
        history.push('/result')
    }

    const showDialog = (dialog) => {
        const d = showModal(QDialog, {
            children: dialog === 'kontakt' ? <Contact /> : <MoreInformation />,
            onConfirm: () => {
                d.hide()
            },
            onCancel: () => {
                d.hide()
            },
        })
    }

    const getOpenOverlay = (label, dialog, slide = null) => (
        <Box className={classes.clickable} onClick={() => showDialog(dialog)}>
            {label}
        </Box>
    )

    const item = {
        title: 'Prozess- und Verfahrensindustrie',
        popover: true,
        popoverContent: (
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <QList
                        items={[getOpenOverlay('Kontakt', 'kontakt'), getOpenOverlay('Weitere Informationen', 'aria')]}
                        dense
                        variant="none"
                        divider
                        fontSize="clamp(1.0rem, 1.1vw, 1.2rem)"
                    />
                </Grid>
            </Grid>
        ),
    }

    return (
        <div>
            {!location.pathname.includes('result') && !location.pathname.includes('share') && (
                <IconButton color="primary" onClick={resultPage} className={classes.resultPageBtn}>
                    <DoneIcon fontSize="large" />
                </IconButton>
            )}
            <PopupState variant="popover" popupId="demo-popup-popover">
                {(popupState) => (
                    <>
                        <IconButton
                            disableRipple="true"
                            color="primary"
                            {...(Object.prototype.hasOwnProperty.call(item, 'popover') && {
                                ...bindTrigger(popupState),
                            })}
                            className={classes.menuIcon}
                        >
                            <MenuIcon fontSize="large" />
                        </IconButton>
                        {Object.prototype.hasOwnProperty.call(item, 'popover') && item.popover && (
                            <Popover
                                {...bindPopover(popupState)}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <Box p={2}>
                                    <Typography onClick={popupState.close}>{item.popoverContent}</Typography>
                                </Box>
                            </Popover>
                        )}
                    </>
                )}
            </PopupState>
        </div>
    )
}

const Contact = () => {
    return (
        <Slide
            logo={<ABBLogo style={{ fontSize: '4rem' }} />}
            background={{
                src: backgroundKontakt,
                enabled: true,
                alignment: 'right',
                width: '50%',
                height: 3.575 / 4,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
            actions={[
                {
                    type: 'icon',
                    text: 'Ansprechpartner',
                    img: 'pdf',
                    target: '_blank',
                    link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/Ansprechpartner.pdf',
                },
            ]}
        >
            <Grid container wrap="nowrap" direction="column" style={{ height: '95vh' }}>
                <Grid item>
                    <QHeader title="Kontakt" />
                </Grid>
                <Grid item>
                    <p>
                        <strong>ABB Stotz-Kontakt GmbH</strong>
                        <br />
                        Kundencenter
                        <br />
                        Eppelheimer Straße 82
                        <br />
                        69123 Heidelberg, Deutschland
                    </p>
                    <p>
                        Telefon: +49 (0) 6221 701-777
                        <br />
                        Fax: +49 (0) 6221 701-771
                    </p>
                    <p>
                        <a href="mailto:info.bje@de.abb.com">info.bje@de.abb.com</a>
                    </p>
                    <p>
                        <a href="abb.de/niederspannung" target="_blank">
                            abb.de/niederspannung
                        </a>
                    </p>
                </Grid>
            </Grid>
        </Slide>
    )
}

const MoreInformation = () => {
    return (
        <Slide
            logo={<ABBLogo style={{ fontSize: '4rem' }} />}
            background={{
                src: backgroundWeitereInfo,
                enabled: true,
                alignment: 'right',
                width: '50%',
                height: 3.575 / 4,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
            <Grid container wrap="nowrap" direction="column" style={{ height: '95vh' }}>
                <Grid item style={{ marginBottom: '25px' }}>
                    <QHeader title="Weitere Informationen" />
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item sm={5} md={5} lg={4}>
                            <Grid container spacing={6}>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}
                                >
                                    <QRCode
                                        href="https://new.abb.com/de/newsletter"
                                        title="Newsletter"
                                        img={QRCodeNewsletter}
                                    />
                                    <QRCode
                                        href="https://new.abb.com/service/de/training/abb-university/germany/großhandelpartnerprogramm"
                                        title="Seminarangebot"
                                        img={QRCodeSeminare}
                                    />
                                    <QRCode
                                        href="https://new.abb.com/low-voltage/de/service/stromkompass"
                                        title={<>StromKompass<sup style={{ position: "absolute", fontSize:"clamp(1.2em, 2.5vh, 1.8em)", transform: "translateY(-5px)"}}>®</sup></>}
                                        img={QRCodeStromkompass}
                                    />
                                    <QRCode
                                        href="https://new.abb.com/mission-to-zero/de/"
                                        title="Mission to Zero"
                                        img={QRCodeMissionToZero}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Slide>
    )
}
