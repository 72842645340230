import React, { useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import QHeader from '../../../../../components/QHeader'
import QList from '../../../../../components/QList'
import Slide from '../../../../../components/Slide'
import { silentMoveTo } from '../../../../../static/fullpageHelpers'
import QBoxList from '../../../../../components/QBoxList'
import root from 'window-or-global'
import { useModal } from 'mui-modal-provider'
import QDialog from '../../../../../components/QDialog'
import OverlaySelector from '../../components/OverlaySelector'

import image1 from '../../../../../assets/branchenkonfiguratorIndustrie/dashboard/ABB Oil & Gas -Medium.jpg'
import image2 from '../../../../../assets/branchenkonfiguratorIndustrie/dashboard/ABB EB DAVID ROBOTICS 18-Medium.jpg'
import image3 from '../../../../../assets/branchenkonfiguratorIndustrie/dashboard/Wastewater Treatment Plant-Large.png'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles({
    link: {
        cursor: 'pointer',
    },
})

export default function Dashboard() {
    const classes = useStyles()
    const location = useLocation()
    const { showModal } = useModal()

    useEffect(() => {
        if (root) {
            const urlParams = new URLSearchParams(location.search)
            const dialog = urlParams.get('open')
            if (location.pathname.split('/')[1]) {
                silentMoveTo(location.pathname.split('/')[1], null)
            }
            if (dialog && dialog !== '') {
                const d = showModal(QDialog, {
                    children: <OverlaySelector id={dialog} />,
                    onConfirm: () => {
                        d.hide()
                    },
                    onCancel: () => {
                        d.hide()
                    },
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getMoveTO = (label, section, slide = null) => (
        <Box className={classes.link} onClick={() => silentMoveTo(section, slide)}>
            {label}
        </Box>
    )

    const items = [
        {
            title: 'Prozess- und Verfahrensindustrie',
            img: image1,
            popover: true,
            popoverContent: (
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <QList
                            items={[
                                getMoveTO('Chemie, Öl und Gas', 'chemie-oel-gas'),
                                getMoveTO('Holz und Papier', 'holz-papier'),
                                getMoveTO('Nahrungs- und Genussmittel', 'nahrungs-genussmittel'),
                                getMoveTO('Pharmazie', 'pharmazie'),
                                getMoveTO('Stahl und Metall', 'stahl-metall'),
                                getMoveTO('Zement, Steine und Erde', 'zement-steine-erde'),
                            ]}
                            dense
                            variant="none"
                            divider
                            fontSize="clamp(1.0rem, 1.1vw, 1.2rem)"
                        />
                    </Grid>
                </Grid>
            ),
        },
        {
            title: 'Produzierende Industrie und Anlagenbau',
            img: image2,
            popover: true,
            popoverContent: (
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <QList
                            items={[
                                getMoveTO('Facility Management', 'facility-management'),
                                getMoveTO('Maschinenbau', 'maschinenbau'),
                                getMoveTO('Schaltanlagenbau', 'schaltanlagenbau'),
                            ]}
                            dense
                            variant="none"
                            divider
                            fontSize="clamp(1.0rem, 1.1vw, 1.2rem)"
                        />
                    </Grid>
                </Grid>
            ),
        },
        {
            title: 'Infrastruktur und Versorger',
            img: image3,
            popover: true,
            popoverContent: (
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <QList
                            items={[
                                getMoveTO('Bahn und Schienenfahrzeuge', 'bahn-schienenfahrzeuge'),
                                getMoveTO('Data Center', 'data-center'),
                                getMoveTO('Energieerzeugung und Bereitstellung', 'energieerzeugung-bereitstellung'),
                                getMoveTO('Gesundheitswesen', 'gesundheitswesen'),
                                getMoveTO('Hoch- und Tiefbau', 'hoch-tiefbau'),
                                getMoveTO('Infrastruktur und Logistik', 'infrastruktur-logistik'),
                                getMoveTO('Instandhaltung und Service', 'instandhaltung-service'),
                                getMoveTO('Schiffbau und Marine', 'schiffbau-marine'),
                                getMoveTO('Wasser und Abwasser', 'wasser-abwasser'),
                                getMoveTO('Windenergie', 'windenergie'),
                            ]}
                            dense
                            variant="none"
                            divider
                            fontSize="clamp(1.0rem, 1.1vw, 1.2rem)"
                        />
                    </Grid>
                </Grid>
            ),
        },
    ]

    return (
        <Slide
            background={{
                enabled: true,
                alignment: 'right',
                width: 11 / 20,
                height: 3.575 / 4,
            }}
        >
            <Grid container direction="column">
                <Grid item>
                    <QHeader title="BranchenSelect" subTitle="Future of Manufacturing" />
                </Grid>
                <Grid item>
                    <Box p={2}>
                        <Grid container direction="column" spacing={2} justifyContent="center" alignItems="center">
                            <Grid item>
                                <QBoxList items={items} colWidth={4} />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Slide>
    )
}
