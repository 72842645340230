import React from 'react'

import Grid from '@material-ui/core/Grid'

import QList from '../../../../components/QList/index'
import QIconRow from '../components/QIconRow/index'
import QBodyText from '../../../../components/QBodyText'
import QHeader from '../../../../components/QHeader'
import Slide from '../../../../components/Slide'
import ImageComponent from '../../../../components/ImageComponent'

import ABBLogo from '../../../../components/SvgIcons/ABBLogo'
import image1 from '../../../../assets/branchenkonfiguratorIndustrie/backgrounds/softstarter.png'
import image2 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_14_easy_to_install_0779.svg'
import image3 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_13_speed_up_your_projects_0776.svg'
import image4 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB__Continous_operation.svg'

export default function Softstarter() {
    return (
        <Slide
            logo={<ABBLogo style={{ fontSize: '4rem' }} />}
            background={{
                enabled: true,
                alignment: 'right',
                width: '50%',
                height: 3.575 / 4,
                backgroundSize: 'cover',
                backgroundPosition: 'right',
            }}
            actions={[
                {
                    type: 'icon',
                    text: 'Web',
                    img: 'laptop',
                    target: '_blank',
                    link: 'https://new.abb.com/drives/de/softstarter',
                },
                {
                    type: 'icon',
                    text: 'e-Configure',
                    img: 'konfigurator',
                    target: '_blank',
                    link: 'https://econfigure.de.abb.com/#/categories/9AAC171541',
                },
                {
                    type: 'icon',
                    text: 'PDF',
                    img: 'pdf',
                    target: '_blank',
                    link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/intelligentes-montomanagement.pdf',
                },
            ]}
        >
            <Grid container wrap="nowrap" direction="column" style={{ height: '95vh' }}>
                <Grid item>
                    <QHeader title="Softstarter" />
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <QBodyText>
                                ABB Softstarter erhöhen die Lebensdauer eines Motors, indem sie ihn vor elektrischen
                                Überlastungen schützen. Sie optimieren die Anlaufströme, die bei herkömmlichen
                                Startverfahren den Motor einer hohen Belastung aussetzen.
                            </QBodyText>
                            <QList
                                items={[
                                    'Stellt die Zuverlässigkeit des Motors sicher',
                                    'Erhöht die Produktivität',
                                    'Steigert die Energieeffizienz',
                                    'Schnell und einfach zu installieren',
                                    'Aufbau mit geringem Platzbedarf',
                                ]}
                            />
                        </Grid>
                        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
                            <ImageComponent previewImgHeight="47vh" alt="Softstarter" src={image1} />
                            <QIconRow
                                colWidth={4}
                                items={[
                                    {
                                        title: 'Einfache Installation',
                                        img: image2,
                                    },
                                    {
                                        title: 'Hohe Anlagenverfügbarkeit',
                                        img: image3,
                                    },
                                    {
                                        title: 'Dauerbetrieb',
                                        img: image4,
                                    },
                                ]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Slide>
    )
}
