import { createStore, compose, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import reducer from '../modules/reducers'

// #region createStore : enhancer
const enhancer = compose(applyMiddleware(thunkMiddleware))
// #endregion

// #region persisted reducer
const persistConfig = {
    key: 'root',
    version: '0.0.1',
    storage,
    migrate: (state) => {
        if (state._persist.version !== persistConfig.version) {
            // eslint-disable-next-line no-console
            console.log('New version!!!')
            state = undefined
        }
        return Promise.resolve(state)
    },
}

const persistedReducer = persistReducer(persistConfig, reducer)
// #endregion

// #region store initialization
export default function configureStore(initialState) {
    const store = createStore(persistedReducer, initialState, enhancer)
    const persistor = persistStore(store)
    return { store, persistor }
}
// #endregion
