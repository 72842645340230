import React from 'react'

import Grid from '@material-ui/core/Grid'

import QList from '../../../../components/QList/index'
import QIconRow from '../components/QIconRow/index'
import QBodyText from '../../../../components/QBodyText'
import QHeader from '../../../../components/QHeader'
import Slide from '../../../../components/Slide'
import ImageComponent from '../../../../components/ImageComponent'

import ABBLogo from '../../../../components/SvgIcons/ABBLogo'
import image1 from '../../../../assets/branchenkonfiguratorIndustrie/backgrounds/maschinensicherheit.png'
import image2 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_14_easy_to_install_0779.svg'
import image3 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_13_speed_up_your_projects_0776.svg'
import image4 from '../../../../assets/branchenkonfiguratorIndustrie/icons/ABB_picto_48x48_14_profile_and_settings_0781.svg'

export default function Maschinensicherheit() {
    return (
        <Slide
            logo={<ABBLogo style={{ fontSize: '4rem' }} />}
            background={{
                enabled: true,
                alignment: 'right',
                width: '50%',
                height: 3.575 / 4,
                backgroundSize: 'contain',
                backgroundPosition: 'right',
            }}
            actions={[
                {
                    type: 'icon',
                    text: 'Web',
                    img: 'laptop',
                    target: '_blank',
                    link: 'https://new.abb.com/low-voltage/de/produkte/maschinensicherheit',
                },
                {
                    type: 'icon',
                    text: 'YouTube',
                    img: 'youtube',
                    target: '_blank',
                    link: 'https://www.youtube.com/watch?app=desktop&v=MuQo_x3nX8Q',
                },
                {
                    type: 'icon',
                    text: 'e-Configure',
                    img: 'konfigurator',
                    target: '_blank',
                    link: 'https://econfigure.de.abb.com/#/categories/9AAC170124',
                },
                {
                    type: 'icon',
                    text: 'PDF',
                    img: 'pdf',
                    target: '_blank',
                    link: 'https://abb-branchenkonfigurator-pdfs.s3.eu-central-1.amazonaws.com/jokab-safety.pdf',
                },
                {
                    type: 'icon',
                    text: 'Training',
                    img: 'seminar',
                    target: '_blank',
                    link: 'https://new.abb.com/service/de/training/abb-university/germany/seminare/termine/großhandelspartnerprogramm/maschinensicherheit-großhandel',
                },
            ]}
        >
            <Grid container wrap="nowrap" direction="column" style={{ height: '95vh' }}>
                <Grid item>
                    <QHeader title="Maschinensicherheit" />
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <QBodyText>
                                Wir liefern alles von der Sicherheitslösung bis zu kompletten Sicherheitssystemen für
                                einzelne Maschinen oder ganze Fertigungsstraßen.
                            </QBodyText>
                            <QList
                                items={[
                                    'Vollständiges Portfolio',
                                    'Robust und hohe Schutzarten',
                                    'Hohe konstruktive und funktionale Flexibilität',
                                    'Sicherheit für Ihren Betrieb',
                                ]}
                                dense
                                variant="dot"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ImageComponent
                                previewImgHeight="47vh"
                                alt="Energieeffiziente Antriebstechnik"
                                src={image1}
                            />
                            <QIconRow
                                colWidth={4}
                                items={[
                                    {
                                        title: 'Produktionsfreundlich',
                                        img: image2,
                                    },
                                    {
                                        title: 'Hohe Anlagenverfügbarkeit',
                                        img: image3,
                                    },
                                    {
                                        title: 'Personenschutz',
                                        img: image4,
                                    },
                                ]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Slide>
    )
}
