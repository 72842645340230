import React from 'react'
import { useHistory } from 'react-router-dom'

import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import QBackgroundBox from '../../../components/QBackgroundBox'
import BranchenkonfiguratorIndustriePage from '../../../components/Beratung/Categories/BranchenkonfiguratorIndustrie'

import ABBLogo from '../../../components/SvgIcons/ABBLogo'
import QHeader from '../../../components/QHeader'

import { dateOptions } from '../../../static/data'

const useStyles = makeStyles(() => ({
    sectionContainer: {
        overflow: 'hidden',
    },
    slideContent: {
        padding: '0.5rem 1.5rem 0 1.5rem',
    },
    childWrap: {
        paddingRight: '1rem',
        paddingLeft: '1rem',
        position: 'relative',
    },
    logo: {
        position: 'absolute',
        bottom: '12px',
        right: '75px',
        zIndex: 200,
    },
    title: {
        fontFamily: 'ABBvoice-Bold',
    },
    largeTitle: {
        fontSize: '48px',
    },
}))

export default function Result() {
    const classes = useStyles()
    const history = useHistory()

    const date = new Date()

    return (
        <Box className={`${classes.sectionContainer}`}>
            <Box className={classes.content}>
                <Box className={clsx(classes.slideWrap)}>
                    <QBackgroundBox
                        settings={{
                            alignment: 'left',
                            width: '100%',
                            height: '100%',
                        }}
                    />
                    <Box className={classes.slideContent}>
                        <Box className={classes.childWrap}>
                            <Grid container direction="column" spacing={2} wrap="nowrap">
                                <Grid item>
                                    <QHeader
                                        title="Zusammenfassung"
                                        subTitle={`Unser Gespräch vom ${date.toLocaleDateString(
                                            undefined,
                                            dateOptions
                                        )}`}
                                        arrow
                                        arrowOverride={() => history.push('/')}
                                        disableOnClick
                                    />
                                </Grid>
                                <Grid item>
                                    <BranchenkonfiguratorIndustriePage context="result" />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box className={classes.logo}>
                        <ABBLogo style={{ fontSize: '4rem' }} />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
