import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import QHeader from '../../../components/QHeader'
import QActionBox from './components/QActionBox'

import APIService from '../../../api/index'
import {
    getBranchenkonfiguratorIndustrieData,
    setAsset,
} from '../../../redux/modules/chapterBranchenkonfiguratorIndustrie'

import ABBLogo from '../../../components/SvgIcons/ABBLogo'

import { dateOptions } from '../../../static/data'
import { branchenActionData } from '../../../static/branchenActionData'

const useStyles = makeStyles(() => ({
    sectionContainer: {
        overflow: 'hidden',
    },
    slideContent: {
        padding: '0.5rem 1.5rem 0 1.5rem',
    },
    childWrap: {
        paddingRight: '1rem',
        paddingLeft: '1rem',
        position: 'relative',
        margin: '1rem',
    },
    logo: {
        position: 'absolute',
        bottom: '12px',
        right: '75px',
        zIndex: 200,
    },
    title: {
        fontFamily: 'ABBvoice-Bold',
    },
    largeTitle: {
        fontSize: '48px',
    },
}))

export default function Share() {
    const classes = useStyles()
    const { id } = useParams()
    const dispatch = useDispatch()

    const { detailOverlays } = useSelector(getBranchenkonfiguratorIndustrieData)
    const [date, setDate] = useState(new Date())

    useEffect(() => {
        if (id) {
            APIService.get(`/api/result/${id}`, (status, data) => {
                if (status === 200) {
                    const curDate = new Date(data.date)
                    if (curDate instanceof Date) {
                        setDate(curDate)
                    }
                    dispatch(setAsset({ id: 'detailOverlays', value: data.values }))
                }
            })
        }
    }, [id, dispatch])

    const data = branchenActionData.filter(
        (item) =>
            detailOverlays.value.includes(item.id) ||
            item.overlays?.find((o) => detailOverlays.value.includes(`${item.id}_${o.id}`))
    )

    return (
        <Box className={`${classes.sectionContainer}`}>
            <Box className={classes.content}>
                <Box className={clsx(classes.slideWrap)}>
                    <Box className={classes.slideContent}>
                        <Box className={classes.childWrap}>
                            <Grid container direction="column" spacing={2} wrap="nowrap">
                                <Grid item>
                                    <QHeader
                                        title="Zusammenfassung"
                                        subTitle={`Unser Gespräch vom ${date.toLocaleDateString(
                                            undefined,
                                            dateOptions
                                        )}`}
                                        disableOnClick
                                    />
                                </Grid>
                                <Grid item style={{ marginBottom: 50 }}>
                                    {data.map((item, i) => {
                                        return <QActionBox key={i} direction={i % 2 ? 'left' : 'right'} item={item} />
                                    })}
                                </Grid>
                            </Grid>
                            <Box className={classes.logo}>
                                <ABBLogo style={{ fontSize: '4rem' }} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
